import { useEffect, useRef, useState } from "react";

function Notify({ title, message, type, closeNotification, manualCloseNotification }) {
  const eleRef = useRef(null);

  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
  });

  const fadeUpAlert = width > 728;

  const close = () => {
    eleRef.current?.classList.add("fadeOut");
    setTimeout(() => {
      eleRef.current?.classList.remove("fadeOut");
      closeNotification();
    }, 1000);
  };
  useEffect(() => {
    if (!manualCloseNotification) {
      const closeTimer = setTimeout(() => {
        closeNotification();
      }, 5000);
      return () => {
        clearTimeout(closeTimer);
      };
    }
  }, []);

  return (
    <div
      ref={eleRef}
      id="script-injected-notification"
      className={
        fadeUpAlert
          ? `animated alert alert-${type} alert-dismissable alert-absolute fadeInUp`
          : `animated alert alert-${type} alert-dismissable alert-absolute fadeInDown`
      }
    >
      <div>
        {title && <h5 className="alert-header">{title}</h5>}
        <p className={title ? "alert-body" : "alert-body-bold"}>{message}</p>
      </div>
      <div>
        <a className={title ? "close-icon" : "close-icon-margin"} onClick={close}>
          &times;
        </a>
      </div>
    </div>
  );
}
export default Notify;
