import axios from "axios";
import { API, localize } from "../utils";

export function uploadDocument(data) {
    const fileFormData = new FormData();
    fileFormData.append("document", data.document);
    fileFormData.append("document_type", data.document_type);
    if (data.hash) {
        fileFormData.append("hash", data.hash);
    }
    return API.postRequest(true, localize(`user-document/`, undefined), fileFormData);
}

export function deleteDocument(hash, appointmentHash) {
    var fileFormData = new FormData();
    if (appointmentHash) {
        fileFormData.append("hash", appointmentHash);
    }

    return API.deleteRequest(true, localize(`user-document/${hash}/`, undefined), { hash: appointmentHash });
}

// DOWNLOADS AN UPLOADED FILE
export const downloadFile = async (file, setErr) => {
    try {
        const response = await axios.get(file.url, { responseType: "blob" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(response.data);
        a.download = file.file_name;
        a.click();
    } catch (err) {
        setErr({
            title: "Error",
            message: "Sorry, something went wrong. Please contact our support team for further help!",
            type: "danger",
        });
    }
};
