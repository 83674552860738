import { useSelector } from "react-redux";

function Stepper({ titles, currentStep }) {
    const { language_code } = useSelector(({ InitialDataS }) => InitialDataS);

    return (
        <div className="stepper-horizontal">
            {titles.map((title, index) => (
                <div key={index} className={`stepper-step ${currentStep === index ? "active" : ""}`}>
                    <div className="stepper-step-circle">
                        <span>{index + 1}</span>
                    </div>
                    <div className="stepper-step-title">{title}</div>
                    <div
                        className={`stepper-step-bar-left ${
                            (language_code === "ar" && currentStep === index + 1) ||
                            (language_code === "en" && currentStep === index)
                                ? "active"
                                : ""
                        }`}
                    ></div>
                    <div
                        className={`stepper-step-bar-right  ${
                            (language_code === "ar" && currentStep === index) ||
                            (language_code === "en" && currentStep === index + 1)
                                ? "active"
                                : ""
                        }`}
                    ></div>
                </div>
            ))}
        </div>
    );
}

export default Stepper;
