import { VALIDATE_USER_SUCCESS, VALIDATE_USER_FAIL } from "../types/auth";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  userInfo: null,
  error: null,
};

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case VALIDATE_USER_SUCCESS:
      return { error: null, userInfo: payload };
    case VALIDATE_USER_FAIL:
      return { error: payload, userInfo: null };
    case HYDRATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default auth;