import { localize } from '../../utils';

export const userMenuItems = [
  {
    heading: 'My Appointments',
    subText: 'Manage your appointments',
    href: '/me/appointments/',
  },
  {
    heading: 'Prescriptions',
    subText: 'Uploaded by doctors after appointments',
    href: '/me/profile/prescriptions',
  },
  {
    heading: 'Uploaded Documents',
    subText: 'Insurance, ID/Passport, Records',
    href: '/me/profile/uploaded_documents/',
  },
  {
    heading: 'Edit Profile',
    subText: 'View or edit your information',
    href: '/me/profile/edit',
  },
];

export const dashBoardAuth = {
  dashboard: {
    heading: 'Clinic Dashboard',
    subText: 'Reports & Analytics on your doctors',
    url: `dashboards/summary`,
  },
  send_sms: {
    heading: 'Send SMS',
    subText: 'Send SMS to patients for reviews',
    url: `dashboards/send-sms`,
  },
  bookings_report: {
    heading: 'Bookings Report',
    subText: 'List of patient bookings details',
    url: `dashboards/bookings-report`,
  },
  bookings_management: {
    heading: 'Bookings Management',
    subText: 'Confirm patient booking requests',
    url: `dashboards/bookings-management`,
  },
  reviews_management: {
    heading: 'Reviews Management',
    subText: 'List of patient reviews on your doctors',
    url: `dashboards/reviews-management`,
  },
  practice_management: {
    heading: 'Practice Management',
    subText: 'Appointments, Calendar, Patient List',
    url: `dashboards/appointments`,
  },

  invoice_management: {
    heading: 'Invoice Management',
    subText: 'Patient Bills',
    url: `dashboards/invoices`,
  },
};

export const localeChain = {
  name: 'Locale',
  list: [
    {
      name: 'Qatar',
      slug: 'qatar',
      active: false,
      // "callback": () => (window.location.href = this.$rootScope.localeHrefChange),
    },
    {
      name: 'Emirates',
      slug: 'uae',
      active: false,
      // "callback": () => (window.location.href = this.$rootScope.localeHrefChange),
    },
    // UNCOMMENT THE NEXT LINES FOR KSA LAUNCH
    // {
    //   name: 'Saudi Arabia',
    //   slug: 'sa',
    //   active: false,
    // },
    {
      name: 'Nigeria',
      slug: 'ng',
      active: false,
      // "callback": () => (window.location.href = this.$rootScope.localeHrefChange),
    },
  ],
};
export const optionListMain = (specialists, localeChain, push, client_side_country, language_code) => [
  {
    name: 'Specialities',
    chain: true,
    list: specialists,
    chosen: false,
  },
  {
    name: 'Change Country',
    chain: true,
    list: localeChain.list,
    chosen: false,
  },
  {
    name: 'For Doctors',
    callback: () =>
      (window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL}${localize(
        `join-doctors`,
        client_side_country,
        language_code
      )}`),
    chain: false,
    chosen: false,
  },
];
