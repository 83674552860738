import axios from "axios";

const defaultHeaders = { "x-requested-with": "XMLHttpRequest" }

const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND_BASE_URL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    },
});

const getRequest = async (url, params = {}, headers = null) => {
    return instance
        .get(encodeURI(url), { params, headers: headers || defaultHeaders })
        .catch((err) => {
            throw err;
        });
    
};

const postRequest = ( url, body, headers = null) => {
    
    return instance
        .post(encodeURI(url), body, { headers: headers || defaultHeaders  })
        .then(({ data }) => data)
        .catch((err) => {
            throw err;
        });
};

const deleteRequest = (url, headers = null) => {

    return instance
        .delete(encodeURI(url), { headers: headers || defaultHeaders  })
        .then(({ data }) => data)
        .catch((err) => {
            throw err;
        });
};

const updateRequest = ( url, body, headers = null) => {
   
    return instance
        .put(encodeURI(url), body, { headers: headers || defaultHeaders })
        .then(({ data }) => data)
        .catch((err) => {
            throw err;
        });
};

export default { getRequest, postRequest, deleteRequest, updateRequest };
