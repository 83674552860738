import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import useTranslation from "../../intl/useTranslation";

function Overlay({
  topCommand,
  topCommandCallback,
  backOption,
  overlayTitle,
  optionList,
  specialCommand,
  searchBar,
  searchBarPlaceholder,
  inheritWidth,
  isOverlay,
  dropOffset,
  call,
  leave,
  hasTwoStep,
  stepTwoList,
  hide,
  backToStepOne,
  goBack,
  specialCommandCallback,
  cancelOptionCallbackWrapper,
  stepOneName,
  stepTwoName,
  chosenIndex,
  showBackButton,
  selectedSubFilters,
  secondSpecialCommand,
  secondSpecialCommandCallback,
  showDesktopHeader,
  selectedFields,
  showBottomCommand,
  bottomCommandActive,
  translateList,
  showInsuranceEnglishNames,
  // TODO: this is a temporary solution. To avoid conflict between width in doctors listing and doctor profile (sort overlay). Should be handled through css classes
  setInlineWidth,
  className,
}) {
  const { locale } = useRouter();
  const [searchList, setSearchList] = useState([]);
  const [list, setList] = useState(
    (searchList.length && searchList) || (stepTwoList?.length && stepTwoList) || optionList
  );
  const [isStepTwo, setIsStepTwo] = useState(false);
  const { t } = useTranslation();
  const ref = useRef();
  useEffect(() => {
    let options = (searchList.length && searchList) || (stepTwoList?.length && stepTwoList) || optionList;
    // the next line fixes a bug where abandoning a 2 step overlay and opening a single step one
    // would cause the single step overlay to show the 2nd step of the 2 step overlay
    // example: step 2 of the insurance/area filter should not show in the treatment filter.
    if (!hasTwoStep) options = (searchList.length && searchList) || optionList;
    let tempField = {};
    options = options?.filter((field) => {
      if (selectedFields?.hasOwnProperty(field.elem_name)) {
        field.selected = true;
        tempField = field;
      } else {
        field.selected = false;
        return field;
      }
    });
    if (Object.keys(tempField).length != 0) {
      options.unshift(tempField);
    }

    setList(options);

    let optionsContainer = document.getElementById("overlay-options-container");
    if (optionsContainer) {
      optionsContainer.scrollTop = 0;
    }
  }, [searchList, stepTwoList, optionList]);

  useEffect(() => {
    if (stepTwoList?.length && !isStepTwo) {
      setSearchList([]);
      setIsStepTwo(true);
      document.getElementById("searchInput").value = "";
    }

    if (!stepTwoList?.length && isStepTwo) {
      setIsStepTwo(false);
      setSearchList([]);
      document.getElementById("searchInput").value = "";
    }
  }, [stepTwoList]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && !event.target.className.includes("dropdown-button")) {
        leave(event);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
  const handleSearch = (e) => {
    let searchArr;
    if (stepTwoList && stepTwoList.length) {
      searchArr = stepTwoList.filter((ele) =>
        (ele.name || ele.friendly_name || ele.elem_name || ele.filter_name || ele.name_en || ele.elem_name_en)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    } else {
      searchArr = optionList.filter((element) => {
        const keys = Object.keys(element);
        for (let i = 0; i < keys.length; i++) {
          if (typeof element[keys[i]] === "string") {
            if (element[keys[i]].toLowerCase().includes(e.target.value.toLowerCase())) {
              return true;
            }
          }
        }
        // (ele.name || ele.friendly_name || ele.elem_name || ele.filter_name)
        //   .toLowerCase()
        //   .includes(e.target.value.toLowerCase())
      });
    }
    setSearchList(searchArr);
  };

  if (overlayTitle == "Nationality/Country of Education") {
    overlayTitle = "Nat./Country";
  }

  return (
    <>
      <div
        ref={ref}
        className={`generic-overlay ${inheritWidth ? "inherit-parent-width" : ""} ${
          isOverlay ? "full-overlay" : ""
        } ${className}`}
        style={{ top: dropOffset, width: setInlineWidth }}
      >
        {isOverlay && (
          <div className="overlay-controller">
            {backOption && (
              <div className="back-command" style={{ marginTop: "2px" }} onClick={() => goBack()}>
                {t("Back")}
              </div>
            )}
            {topCommand && (
              <div className="special-top-command" onClick={() => topCommandCallback()}>
                {topCommand}
              </div>
            )}
            {!backOption && <div className="back-command"></div>}
            <div className="overlay-title"> {t(overlayTitle) || overlayTitle}</div>
            <span className="close-command" onClick={() => hide()}>
              &times;
            </span>
          </div>
        )}
        {hasTwoStep && (
          <div className="overlay-steps-container">
            <span
              className={`overlay-step ${stepTwoList.length ? "" : "active"} `}
              onClick={() => {
                backToStepOne();
                setSearchList([]);
              }}
            >
              <span>
                {t("Step 1:")} {t(stepOneName)}
              </span>
            </span>
            <span
              className={`overlay-step step-two ${stepTwoList.length ? "active" : ""} `}
              onClick={() => {
                // goTostepTwo();
                setSearchList([]);
              }}
            >
              <span>
                {t("Step 2:")} {t(stepTwoName)}
              </span>
            </span>
          </div>
        )}

        {showBackButton && showDesktopHeader && !isOverlay && (
          <div className="overlay-back-step-container" style={{ display: "block" }}>
            <div
              className="overlay-step"
              style={{
                margin: "15px 10px 0px 10px",
                position: "absolute",
                color: "#2A3390",
                fontSize: "14px",
                fontWeight: "400",
              }}
              onClick={() => goBack(overlayTitle)}
            >
              {t("Back")}
            </div>
            <div
              className="overlay-step"
              style={{
                margin: 0,
                paddingTop: "15px",
                textAlign: "center",
                color: "#282828",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {overlayTitle}
            </div>

            {/* <span style={{flexGrow:0.5}}></span> */}
          </div>
        )}

        {searchBar && (
          <div className="overlay-search-container">
            {/* <img src="/images/icon-search-light-grey.svg"/> */}
            <span className="search-icon"></span>
            <input
              id="searchInput"
              autoComplete="off"
              onChange={handleSearch}
              type="text"
              name="search"
              className={`overlay-options-search ${
                hasTwoStep || (showBackButton && showDesktopHeader && !isOverlay) ? "contains-steps" : ""
              }`}
              placeholder={searchBarPlaceholder ? searchBarPlaceholder : t("Search")}
              data-hj-allow
            />
          </div>
        )}
        <div
          className={`overlay-listing-wrapper overlay-options-container bold-optio  ${
            searchBar ? "contains-search" : ""
          } ${specialCommand ? "contains-special-command" : ""} ${
            hasTwoStep || (showBackButton && showDesktopHeader && !isOverlay) ? "contains-steps" : ""
          }`}
          id="overlay-options-container"
        >
          {list?.map((option, index) => (
            <div
              key={index}
              onClick={(e) => {
                e.target.className.indexOf("meddy-close cancel-icon") == -1 &&
                  e.target.className.indexOf("filter-close") == -1 &&
                  call(option);
              }}
              className={`overlay-option ${option.cancel && !showBackButton ? "bold-option " : ""} ${
                option.cancel ? "cancelable-option" : ""
              } ${searchBar ? "searchable-option" : ""} ${option.no_discounts == 0 ? "disable-option" : ""}`}
              disabled={option.no_discounts == 0}
              style={{
                padding: showBackButton && option.cancel && !isOverlay ? "13px 29px" : "",
                background: option.selected ? "#2A3390" : "",
                color: option.selected ? "#fff" : "",
              }}
            >
              {option.cancel && (
                <span
                  style={{ zIndex: "10", position: "absolute" }}
                  onClick={() => cancelOptionCallbackWrapper(option, index)}
                  className={`cancel-option ${showBackButton ? "cancel-option-invert" : ""}`}
                >
                  {!showBackButton && <i className="meddy-close cancel-icon " aria-hidden="true"></i>}

                  {/* {showBackButton && isOverlay && <i className="meddy-close cancel-icon-invert" aria-hidden="true"></i>} */}

                  {showBackButton && (
                    <img className="filter-close" src="/next-images/Icon_Cross_Red.svg" alt="Video Consultation" />
                  )}
                </span>
              )}
              {translateList
                ? t(option.name || option.friendly_name || option.elem_name || option.filter_name)
                : option.name || option.friendly_name || option.elem_name || option.filter_name}
              {showInsuranceEnglishNames && (
                <div className="insurance-sub">{option.name_en || option.elem_name_en}</div>
              )}
              {showBackButton && option.cancel && <span className={"applied-sticker"}>applied</span>}
              {(option.no_discounts || option.no_discounts == 0) && (
                <span className="overlay-discounts-num">
                  {option.no_discounts} {t("Discounts")}
                </span>
              )}
              {option.chain && <span className="chained-option meddy-light-chevron"></span>}
            </div>
          ))}
          {showBackButton && showBottomCommand && !isOverlay && (
            <div className={`mt-2`} style={{ marginBottom: "12px", position: "relative" }}>
              <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "2%" }}>
                <button
                  onClick={() => specialCommandCallback()}
                  className={"button-default-xs doctor-listing-filter-bottom-command"}
                  style={{
                    color: "#fff",
                    background: "#2A3390",
                    fontSize: "12px",
                    fontWeight: "bold",
                    display: "flex",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "12px",
                  }}
                  disabled={!bottomCommandActive}
                >
                  {t(specialCommand)}
                </button>
                <button
                  onClick={() => secondSpecialCommandCallback()}
                  className={"button-xs invert"}
                  style={{
                    marginRight: "12px",
                    color: "#282828",
                    background: "#fff",
                    border: "1px solid #282828",
                    fontWeight: "700",
                    fontSize: "12px",
                    display: bottomCommandActive ? "block" : "none",
                  }}
                >
                  {t(secondSpecialCommand)}
                </button>
              </div>
            </div>
          )}
        </div>
        {specialCommand && isOverlay && (
          <div
            className="special-command-container"
            style={{ backgroundColor: "#fff", borderTop: 0 }}
            onClick={() => specialCommandCallback()}
          >
            <div className="special-command-inner-container">
              <span className="special-command-text">{t(specialCommand)}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Overlay;
