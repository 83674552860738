export const en = {
  "Concerned about Coronavirus (COVID-19)? Get your symptoms checked here!":
    "Concerned about Coronavirus (COVID-19)? Get your symptoms checked here!",
  "Get your symptoms checked for COVID-19 and request a free phone consultation!":
    "Get your symptoms checked for COVID-19 and request a free phone consultation!",
  "Thank you for using HeliumDoc. Please expect a call back from one of our doctors.":
    "Thank you for using HeliumDoc. Please expect a call back from one of our doctors.",
  "*This service is available daily only from 10 AM - 1 PM except Fridays.":
    "*This service is available daily only from 10 AM - 1 PM except Fridays.",
  "Calling the COVID-19 Hotline...": "Calling the COVID-19 Hotline...",
  "Request Sent!": "Request Sent!",
  "Request Sent!": "تم ارسال الطلب!",
  "Featured Doctors": "Featured Doctors",
  "Popular Clinics": "Popular Clinics",
  Featured: "Featured",
  "If you didn't get a pop up asking you to call": "If you didn't get a pop up asking you to call",
  "click here to manually call": "click here to manually call",
  "A user with that username already exists.": "A user with that username already exists.",
  "The two password fields didn't match.": "The two password fields didn't match.",
  "Password confirmation": "Password confirmation",
  "New password confirmation": "New password confirmation",
  "<strong>Success!</strong> Thank you for contacting us. We will be in touch shortly":
    "<strong>Success!</strong> Thank you for contacting us. We will be in touch shortly",
  "Do you want to get popular among your patients?": "Do you want to get popular among your patients?",
  "Do you want your patients to find your clinic without getting lost?":
    "Do you want your patients to find your clinic without getting lost?",
  "Do you want 24/7 service for your patients?": "Do you want 24/7 service for your patients?",
  "Product Tour": "Product Tour",
  "Sounds interesting?": "Sounds interesting?",
  "Let's talk more": "Let's talk more",
  "Product Features": "Product Features",
  "Get Discovered": "Get Discovered",
  "Let your patients find you through our website": "Let your patients find you through our website",
  "24/7 Booking request": "24/7 Booking request",
  "Patients can request booking within few clicks even if your phones are busy or it's midnight and your clinic is closed.":
    "Patients can request booking within few clicks even if your phones are busy or it's midnight and your clinic is closed.",
  "Patient Reviews": "Patient Reviews",
  "Build your online presence and reputation by having your patients share their experience on your profile with everyone to see.":
    "Build your online presence and reputation by having your patients share their experience on your profile with everyone to see.",
  "Clinic Highlights": "Clinic Highlights",
  "Let your patients know the benefits of your clinic and why they should choose your service.":
    "Let your patients know the benefits of your clinic and why they should choose your service.",
  "Custom Profile": "Custom Profile",
  "Showcase Your Personality": "Showcase Your Personality",
  "Let your patients know you beyond your profession by sharing your philosophy towards medicine and treatment.":
    "Let your patients know you beyond your profession by sharing your philosophy towards medicine and treatment.",
  Location: "Location",
  "Help your patients find your location effortlessly without getting lost.":
    "Help your patients find your location effortlessly without getting lost.",
  "Clinic Hours": "Clinic Hours",
  "Make it easier for your patients to know at what hours you will be available.":
    "Make it easier for your patients to know at what hours you will be available.",
  "Professional Photos": "Professional Photos",
  "Have photos of you and your clinic for patients to feel comfortable going to you.":
    "Have photos of you and your clinic for patients to feel comfortable going to you.",
  "Strategic Partners": "Strategic Partners",
  "Do you want to get": "Do you want to get",
  "popular among": "popular among",
  "your patients?": "your patients?",
  "Do you want your patients": "Do you want your patients",
  "to find your clinic without": "to find your clinic without",
  "getting lost?": "getting lost?",
  "Do you want 24/7 service": "Do you want 24/7 service",
  5: "5",
  "Visit the doctor at their clinic": "Visit the doctor at their clinic",
  "Visit the doctor via video consultation": "Visit the doctor via video consultation",
  "for your patients?": "for your patients?",
  "Attract New Patients": "Attract New Patients",
  "Get reviews & referrals from happy patients to build your practice":
    "Get reviews & referrals from happy patients to build your practice",
  "Enhance Patient Experience": "Enhance Patient Experience",
  "Allow patients to send a booking request anytime, even when your clinic is closed.":
    "Allow patients to send a booking request anytime, even when your clinic is closed.",
  "Strengthen Your Reputation": "Strengthen Your Reputation",
  "Let your patients know about all your awesome educational credentials":
    "Let your patients know about all your awesome educational credentials",
  "Why should I be on HeliumDoc?": "Why should I be on HeliumDoc?",
  "Patients can request booking within few clicks even if your phones are busy or it's midnight and your clinic is closed":
    "Patients can request booking within few clicks even if your phones are busy or it's midnight and your clinic is closed",
  "Personal Search Listing": "Personal Search Listing",
  "Patients can request booking within few clicks. Even if your phones are busy or your clinic is closed, patients will always be able to request an appointment.":
    "Patients can request booking within few clicks. Even if your phones are busy or your clinic is closed, patients will always be able to request an appointment.",
  "Personalized Profile": "Personalized Profile",
  "Your profile will be customized with your credentials and specialties to let your patients know more about you!":
    "Your profile will be customized with your credentials and specialties to let your patients know more about you!",
  "As Seen On": "As Seen On",
  "Interested? We are here to help you!": "Interested? We are here to help you!",
  "Your Name": "Your Name",
  "Clinic or Hospital Name": "Clinic or Hospital Name",
  "Email (Optional)": "Email (Optional)",
  "Contact Number": "Contact Number",
  "Call Me Back": "Call Me Back",
  'Thank you for booking an appointment through <span class=\\"in-text-logo\\">HeliumDoc</span>':
    'Thank you for booking an appointment through <span class=\\"in-text-logo\\">HeliumDoc</span>',
  "You can expect a call shortly from": "You can expect a call shortly from",
  "to confirm your appointment within 24 hours.": "to confirm your appointment within 24 hours.",
  Confirmed: "Confirmed",
  "No Answer": "No Answer",
  "Other Reason": "Other Reason",
  Navigate: "Navigate",
  "View on Google Maps": "View on Google Maps",
  Filters: "Filters",
  Speciality: "Speciality",
  Clinic: "Clinic",
  "Country of Education": "Country of Education",
  Area: "Area",
  Gender: "Gender",
  "All Doctors In Qatar": "All Doctors In Qatar",
  "show all": "show all",
  Nationality: "Nationality",
  "No Doctors Found": "No Doctors Found",
  "We can't find a doctor matching your preferences": "We can't find a doctor matching your preferences",
  "Do you know a doctor?": "Do you know a doctor?",
  "Let us know!": "Let us know!",
  "We are working our best on adding all the good doctors in Qatar":
    "We are working our best on adding all the good doctors in Qatar",
  "Online Booking": "Online Booking",
  Prev: "Prev",
  Next: "Next",
  Home: "Home",
  "Free Consultation": "Free Consultation",
  "Phone Appointment": "Phone Appointment",
  Walkin: "Walkin",
  RECOMMENDATIONS: "RECOMMENDATIONS",
  "Open in Google Maps": "Open in Google Maps",
  Profile: "Profile",
  Photos: "Photos",
  "Emergency Info": "Emergency Info",
  "For emergency appointments on the same day send SMS to": "For emergency appointments on the same day send SMS to",
  "directly on his mobile": "directly on his mobile",
  "Call Emergency Number": "Call Emergency Number",
  "Professional Statement": "Professional Statement",
  Qualifications: "Qualifications",
  "Dental Schools": "Dental Schools",
  "Medical Schools": "Medical Schools",
  Diplomas: "Diplomas",
  "Board Certifications": "Board Certifications",
  "Country of Education & Practice": "Country of Education & Practice",
  "Share your experience:": "Share your experience:",
  "Be the first to review:": "Be the first to review:",
  "How was your experience going to": "How was your experience going to",
  "?": "?",
  "to someone else?": "to someone else?",
  Yes: "Yes",
  No: "No",
  "Would you recommend": "Would you recommend",
  Submit: "Submit",
  "Submit Review": "Submit Review",
  "Already Reviewed!": "Already Reviewed!",
  "Write a Review": "Write a Review",
  "Posted on": "Posted on",
  "Call Clinic Number": "Call Clinic Number",
  "Office Hours": "Office Hours",
  Phone: "Phone",
  "Appointment Request": "Appointment Request",
  "Your Email": "Your Email",
  "Your Contact Number": "Your Contact Number",
  "Submit Booking": "Submit Booking",
  "Insurance Providers with Direct Billing": "Insurance Providers with Direct Billing",
  Close: "Close",
  "<strong>Success!</strong> Thank you for requesting an appointment with Dr.":
    "<strong>Success!</strong> Thank you for requesting an appointment with Dr.",
  "on HeliumDoc. The clinic should give you a call shortly to confirm your request":
    "on HeliumDoc. The clinic should give you a call shortly to confirm your request",
  recommendations: "recommendations",
  "For emergency appointments on the same day send sms to Dr. Nizar Kharma directly on his mobile":
    "For emergency appointments on the same day send sms to Dr. Nizar Kharma directly on his mobile",
  "License Number": "License Number",
  License: "License",
  "Dental School": "Dental School",
  "Medical School": "Medical School",
  "Board Certification": "Board Certification",
  Affiliations: "Affiliations",
  "Blog Links": "Blog Links",
  Other: "Other",
  Contact: "Contact",
  "Search By": "Search By",
  "Doctor Name": "Doctor Name",
  "Clinic Name": "Clinic Name",
  "OB/GYN": "OB/GYN",
  GPs: "GPs",
  "Al Waab": "Al Waab",
  "Al Hilal": "Al Hilal",
  "Al Duhail": "Al Duhail",
  "Abu Hamour": "Abu Hamour",
  "Al Muntazah": "Al Muntazah",
  "Join HeliumDoc for Free!": "Join HeliumDoc for Free!",
  "Advertise your Clinic": "Advertise your Clinic",
  "Made with": "Made with",
  "in Qatar": "in Qatar",
  "in UAE": "in UAE",
  "in Nigeria": "in Nigeria",
  "Find best doctors in Qatar based on patient reviews and credentials. Browse doctor profiles with their background information, get reviews from other patients going to that doctor and get clinic locations. Make an informed health decision based on knowledge, not chance.":
    "Find best doctors in Qatar based on patient reviews and credentials. Browse doctor profiles with their background information, get reviews from other patients going to that doctor and get clinic locations. Make an informed health decision based on knowledge, not chance.",
  Bookings: "Bookings",
  Dashboard: "Dashboard",
  "Log Out": "Log Out",
  "Sign Up": "Sign Up",
  Login: "Login",
  "Join Now": "Join Now",
  "Get all information about a doctor instantly without calling the clinic":
    "Get all information about a doctor instantly without calling the clinic",
  "Insurance/Pricing": "Insurance/Pricing",
  in: "in",
  for: "for",
  " Eye Doctors ": " Eye Doctors ",
  "Your review is saved, and you will be able to post it when you create an account or login.":
    "Your review is saved, and you will be able to post it when you create an account or login.",
  "Login with Facebook": "Login with Facebook",
  "Don't worry, we NEVER post without your permission. We hate that, too!":
    "Don't worry, we NEVER post without your permission. We hate that, too!",
  OR: "OR",
  "Your username and password don't match our records. Please try again.":
    "Your username and password don't match our records. Please try again.",
  "Don't have an account?": "Don't have an account?",
  "Sign up now": "Sign up now",
  "Clinic Photos": "Clinic Photos",
  "Your username, in case you've forgotten:": "Your username, in case you've forgotten:",
  "The %(site_name)s team": "The %(site_name)s team",
  "Send SMS Messages": "Send SMS Messages",
  "^": "^",
  "Info:": "Info:",
  "The first 5 doctors are randomly sorted.": "The first 5 doctors are randomly sorted.",
  Country: "Country",
  "I'm paying for myself": "I'm paying for myself",
  "Patient Name": "Patient Name",
  "Enter your Full Name": "Enter your Full Name",
  Mobile: "Mobile",
  "Enter your Mobile Number": "Enter your Mobile Number",
  "Enter your mobile number": "Enter your mobile number",
  "No more reviews": "No more reviews",
  "Show more": "Show more",
  "Show less": "Show less",
  Satisfied: "Satisfied",
  Neutral: "Neutral",
  Unsatisfied: "Unsatisfied",
  "No reviews yet. Be the first the first to leave a Review!":
    "No reviews yet. Be the first the first to leave a Review!",
  "How was your experience?": "How was your experience?",
  "You need to choose one of the following.": "You need to choose one of the following.",
  "How was your experience with": "How was your experience with",
  "Share your Experience": "Share your Experience",
  "Thank you for sharing a review for": "Thank you for sharing a review for",
  "You have already submitted a review for": "You have already submitted a review for",
  "No Matching Results": "No Matching Results",
  Back: "Back",
  "Back to My Appointments": "Back to My Appointments",
  "Clear Filters": "Clear Filters",
  "See Doctors": "See Doctors",
  "See Offers": "See Offers",
  Subspeciality: "Subspeciality",
  "Please fill in the form to confirm your appointment:": "Please fill in the form to confirm your appointment:",
  "You need to log in before you can submit your review": "You need to log in before you can submit your review",
  "Nat./Country": "Nat./Country",
  "Show Nearby Doctors": "Show Nearby Doctors",
  "Getting Location": "Getting Location",
  "Sending your Request": "Sending your Request",
  "Choose a Clinic": "Choose a Clinic",
  "This doctor belongs to multiple clinics, please choose a clinic:":
    "This doctor belongs to multiple clinics, please choose a clinic:",
  "Please choose a Clinic.": "Please choose a Clinic.",
  "Choose Offers": "Choose Offers",
  "manager replied": "manager replied",
  Newest: "Newest",
  Oldest: "Oldest",
  "Publish Review": "Publish Review",
  "Sort by:": "Sort by:",
  Sponsored: "Sponsored",
  "Languages:": "Languages:",
  "Please enter a Valid Phone Number.": "Please enter a Valid Phone Number.",
  "Disclaimer: Prices/Offers are subject to change without prior notice. Terms and conditions apply.":
    "Disclaimer: Prices/Offers are subject to change without prior notice. Terms and conditions apply.",
  "Select your Insurance": "Select your Insurance",
  "Select Reason to Visit (Optional)": "Select Reason to Visit (Optional)",
  "Something Went Wrong": "Something Went Wrong",
  "Sorry, something went wrong in the backend. Please contact our support team for further help!":
    "Sorry, something went wrong in the backend. Please contact our support team for further help!",
  "Please allow browser to access location": "Please allow browser to access location",
  "Sorry!": "Sorry!",
  "You have to select a doctor before doing that": "You have to select a doctor before doing that",
  "Success!": "Success!",
  "Your information has been saved successfully.": "Your information has been saved successfully.",
  "Cancelled appointment successfully": "Cancelled appointment successfully",
  "Updated Appointment successfully": "Updated Appointment successfully",
  "Cannot select a month in the future": "Cannot select a month in the future",
  "Please choose the end date after start date": "Please choose the end date after start date",
  "Notice!": "Notice!",
  "Error Occurred": "Error Occurred",
  "Patient created!": "Patient created!",
  "Patient Details edited!": "Patient Details edited!",
  "Reply Submitted!": "Reply Submitted!",
  "Reply to review submitted successfully!": "Reply to review submitted successfully!",
  "An error occurred, please contact the HeliumDoc team for assistance":
    "An error occurred, please contact the HeliumDoc team for assistance",
  "We have received your information and will contact you soon.":
    "We have received your information and will contact you soon.",
  "An error occurred in submitting your information.": "An error occurred in submitting your information.",
  "Please try again later.": "Please try again later.",
  Warning: "Warning",
  "Please enter your name": "Please enter your name",
  "Please enter your clinic name": "Please enter your clinic name",
  "Please enter your email": "Please enter your email",
  "Please enter your phone number in a valid format": "Please enter your phone number in a valid format",
  "Please Allow Browser to Find Your Location": "Please Allow Browser to Find Your Location",
  "Querying Server Failed!": "Querying Server Failed!",
  "Please contact the HeliumDoc team for help!": "Please contact the HeliumDoc team for help!",
  "Review Submitted!": "Review Submitted!",
  "Thank you for submitting a review!": "Thank you for submitting a review!",
  "You already reviewed this doctor": "You already reviewed this doctor",
  "You already reviewed this doctor, thank you for your review!":
    "You already reviewed this doctor, thank you for your review!",
  "Failure!": "Failure!",
  "Your review could not be submitted, please contact": "Your review could not be submitted, please contact",
  "the HeliumDoc team for further assistance!": "the HeliumDoc team for further assistance!",
  "Your Review has been Saved!": "Your Review has been Saved!",
  "Your review has been saved, but you need to log in before you can submit it":
    "Your review has been saved, but you need to log in before you can submit it",
  "I'll pay myself": "I'll pay myself",
  "Please select an Insurance Provider.": "Please select an Insurance Provider.",
  "Please select your Insurance Plan.": "Please select your Insurance Plan.",
  "Please select a clinic": "Please select a clinic",
  "Please select Yes or No.": "Please select Yes or No.",
  "How are you going to pay": "How are you going to pay",
  "Do you have insurance?": "Do you have insurance?",
  "This doctor and clinic does not accept ": "This doctor and clinic does not accept ",
  ". Would you still like to book with him/her?": ". Would you still like to book with him/her?",
  "No. Find me a doctor who accepts my insurance.": "No. Find me a doctor who accepts my insurance.",
  "Other...": "Other...",
  "General Consultation": "General Consultation",
  "Change Country": "Change Country",
  Dubai: "Dubai",
  Bahrain: "Bahrain",
  "Are You a Doctor?": "Are You a Doctor?",
  "Follow us": "Follow us",
  Menu: "Menu",
  "No Offers Found": "No Offers Found",
  "Expires on:": "Expires on:",
  "Expires ": "Expires ",
  "Search for Doctors, Specialities, Clinics...": "Search for Doctors, Specialities, Clinics...",
  Logout: "Logout",
  "Enter your details": "Enter your details",
  "Are you booking for yourself?": "Are you booking for yourself?",
  "Complete your details": "Complete your details",
  "Select a Reason a visit (Optional)": "Select a Reason a visit (Optional)",
  "Choose a time for your booking": "Choose a time for your booking",
  "Please select a time for your booking.": "Please select a time for your booking.",
  Continue: "Continue",
  Emirates: "Emirates",
  "Step 1": "Step 1",
  "Step 2": "Step 2",
  "Choose City": "Choose City",
  "Choose Area": "Choose Area",
  "All Areas": "All Areas",
  "All Cities": "All Cities",
  "Choose Plan": "Choose Plan",
  "View Profile": "View Profile",
  "View Clinic": "View Clinic",
  "Select a View": "Select a View",
  Clinics: "Clinics",
  CLINICS: "CLINICS",
  "No Available timings for this day": "No Available timings for this day",
  Cancel: "Cancel",
  "Sorry we could not find any results": "Sorry we could not find any results",
  "No Results Found": "No Results Found",
  "Sub Specializations": "Sub Specializations",
  Available: "Available",
  "All Specializations": "All Specializations",
  "All Specialities": "All Specialities",
  "Next Available": "Next Available",
  "Doctor is available": "Doctor is available",
  "Special Offers": "Special Offers",
  "Average Ratings": "Average Ratings",
  "New Patient. This is the first time I go to": "New Patient. This is the first time I go to",
  "Multiple patient records were found for the phone number":
    "Multiple patient records were found for the phone number",
  at: "at",
  "Recommended reviews": "Recommended reviews",
  "Please Confirm Patient": "Please Confirm Patient",
  "Please select a patient.": "Please select a patient.",
  "If you require any assistance, please call": "If you require any assistance, please call",
  "Please select the correct one:": "Please select the correct one:",
  "First-come, first-served. Submit a booking to get the doctor's schedule and clinic location.":
    "First-come, first-served. Submit a booking to get the doctor's schedule and clinic location.",
  "Acceptance of Terms": "Acceptance of Terms",
  "Remember Me": "Remember Me",
  "Enter Email": "Enter Email",
  "Enter Password": "Enter Password",
  "Log in": "Log in",
  "Full Name": "Full Name",
  Name: "Name",
  Email: "Email",
  "Email address": "Email address",
  Password: "Password",
  "Re-enter Password": "Re-enter Password",
  "Enter the same password as above, for verification.": "Enter the same password as above, for verification.",
  "Create your account": "Create your account",
  "Old password": "Old password",
  "New password": "New password",
  "New password (again)": "New password (again)",
  "Enter email": "Enter email",
  "Recover Password": "Recover Password",
  "Enter new password": "Enter new password",
  "Enter new password (again)": "Enter new password (again)",
  "Welcome to HeliumDoc": "Welcome to HeliumDoc",
  "Book Appointments with the Best Doctors": "Book Appointments with the Best Doctors in",
  Specialization: "Specialization",
  City: "City",
  Find: "Find",
  "Find Discounts": "Find Discounts",
  Insurance: "Insurance",
  Best: "Best",
  "Best doctor for": "Best doctor for",
  "Best Clinics": "Best Clinics",
  "24 hours": "24 hours",
  Midnight: "Midnight",
  ",": ",",
  now: "now",
  today: "today",
  tomorrow: "tomorrow",
  on: "on",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Free: "Free",
  Network: "Network",
  "General Practitioners (GP)": "General Practitioners (GP)",
  "general+practitioner": "general+practitioner",
  "Internal Medicine": "Internal Medicine",
  "internal+medicine": "internal+medicine",
  Pediatricians: "Pediatricians",
  pediatrician: "pediatrician",
  "Obstetricians & Gynecologists (OB/GYN)": "Obstetricians & Gynecologists (OB/GYN)",
  "obstetrician+and+gynecologist": "obstetrician+and+gynecologist",
  Dentists: "Dentists",
  dentist: "dentist",
  Orthopedists: "Orthopedists",
  orthopedist: "orthopedist",
  Urologists: "Urologists",
  urologist: "urologist",
  "Eye Doctors": "Eye Doctors",
  ophthalmologist: "ophthalmologist",
  "Ear, Nose & Throat (ENT)": "Ear, Nose & Throat (ENT)",
  "e+n+t": "e+n+t",
  Endocrinologist: "Endocrinologist",
  endocrinologist: "endocrinologist",
  Dermatologists: "Dermatologists",
  dermatologist: "dermatologist",
  "Plastic Surgeons": "Plastic Surgeons",
  "plastic+surgeon": "plastic+surgeon",
  Psychiatrists: "Psychiatrists",
  psychiatrist: "psychiatrist",
  OBGYN: "OBGYN",
  Arguments: "Arguments",
  English: "English",
  Arabic: "Arabic",
  Qatar: "Qatar",
  qatar: "Qatar",
  UAE: "UAE",
  Nigeria: "Nigeria",
  "Saudi Arabia": "Saudi Arabia",
  Treatment: "Treatment",
  "Procedures & Treatments": "Procedures & Treatments",
  Telemedicine: "Telemedicine",
  "Sub Specialization": "Sub Specialization",
  "City/Area": "City/Area",
  "Nationality/Country of Education": "Nationality/Country of Education",
  Language: "Language",
  male: "male",
  female: "female",
  Male: "Male",
  Female: "Female",
  "Dr.": "Dr.",
  "Prof. Dr.": "Prof. Dr.",
  "Mr.": "Mr.",
  "Mrs.": "Mrs.",
  "Ms.": "Ms.",
  Specialist: "Specialist",
  Consultant: "Consultant",
  "Senior Consultant": "Senior Consultant",
  Counselor: "Counselor",
  "Associate Specialist": "Associate Specialist",
  "Today at": "Today at",
  "Tomorrow at": "Tomorrow at",
  Consultation: "Consultation",
  "Free consultation": "Free consultation",
  "Best Doctors": "Best Doctors",
  Default: "Default",
  "Most Recommended": "Most Recommended",
  "Most Popular": "Most Popular",
  "Closest Doctors": "Closest Doctors",
  "Thank you for using HeliumDoc. \\n Here's the location for {}: {}\\n":
    "Thank you for using HeliumDoc. \\n Here's the location for {}: {}\\n",
  "Would you like to know the costs of consultation/treatment for Dr. {}?":
    "Would you like to know the costs of consultation/treatment for Dr. {}?",
  "You're receiving this email because you requested a password reset for your  user account at %(site_name)s.":
    "You're receiving this email because you requested a password reset for your  user account at %(site_name)s.",
  "Please go to the following page and choose a new password:":
    "Please go to the following page and choose a new password:",
  "Your login email, in case you've forgotten, is same this email address:":
    "Your login email, in case you've forgotten, is same this email address:",
  "Thanks for using our site!": "Thanks for using our site!",
  "The HeliumDoc team": "The HeliumDoc team",
  "Password reset on %(site_name)s": "Password reset on %(site_name)s",
  "Welcome Back!": "Welcome Back!",
  "Not a HeliumDoc user yet?": "Not a HeliumDoc user yet?",
  "Create your account.": "Create your account.",
  "Forgot Password?": "Forgot Password?",
  "Continue with Google": "Continue with Google",
  "Continue with Facebook": "Continue with Facebook",
  or: "or",
  "Password Change": "Password Change",
  "Reset link sent": "Reset link sent",
  "Please check your mail. You would have received a password reset mail from us.":
    "Please check your mail. You would have received a password reset mail from us.",
  "Password recovery": "Password recovery",
  "Remember your password?": "Remember your password?",
  "Back to Login": "Back to Login",
  "Already a User?": "Already a User?",
  'By clicking "Create your account", you are agreeing to the HeliumDoc':
    'By clicking "Create your account", you are agreeing to the HeliumDoc',
  "Terms of Service": "Terms of Service",
  and: "and",
  "Privacy Policy": "Privacy Policy",
  Action: "Action",
  HeliumDoc: "HeliumDoc",
  Required: "Required",
  "You have to select a speciality before searching": "You have to select a speciality before searching",
  "Select Speciality": "Select Speciality",
  "Area (Optional)": "Area (Optional)",
  "Select Area": "Select Area",
  Search: "Search",
  "Popular Specialities": "Popular Specialities",
  "Obstetricians & Gynecologists": "Obstetricians & Gynecologists",
  "General Doctors": "General Doctors",
  "Skin Doctors": "Skin Doctors",
  "Stay Healthy With Us": "Stay Healthy With Us",
  "Read the latest healthcare articles and doctor opinions on our brand new blog.":
    "Read the latest healthcare articles and doctor opinions on our brand new blog.",
  "Explore more about various treatments, health concerns, and home remedies from the best doctors!":
    "Explore more about various treatments, health concerns, and home remedies from the best doctors!",
  "Our blog": "Our blog",
  "Read More": "Read More",
  "Choose speciality, insurance, area": "Choose speciality, insurance, area",
  Featured: "Featured",
  "Book Now": "Book Now",
  "Book appointment with": "Book appointment with",
  Call: "Call",
  "to book an appointment": "to book an appointment",
  "Popular Treatments": "Popular Treatments",
  "Back to Search": "Back to Search",
  "Clinic Price List": "Clinic Price List",
  Address: "Address",
  "Working Times": "Working Times",
  "Open now": "Open now",
  "Closed now": "Closed now",
  Closed: "Closed",
  "About the Clinic": "About the Clinic",
  Services: "Services",
  "Languages Spoken at": "Languages Spoken at",
  Doctors: "Doctors",
  DOCTORS: "DOCTORS",
  Equipment: "Equipment",
  "View More": "View More",
  Insurances: "Insurances",
  "Medical Equipment": "Medical Equipment",
  "Years of Experience": "Years of Experience",
  Details: "Details",
  Reviews: "Reviews",
  ReviewsP: "Reviews",
  Specialities: "Specialities",
  "Speciality is required": "Speciality is required",
  "Languages Spoken": "Languages Spoken",
  Treatments: "Treatments",
  Credentials: "Credentials",
  Education: "Education",
  "Dental Qualifications": "Dental Qualifications",
  "Medical Qualifications": "Medical Qualifications",
  Diploma: "Diploma",
  Residency: "Residency",
  Fellowship: "Fellowship",
  Board: "Board",
  Affiliation: "Affiliation",
  "Read More Reviews": "Read More Reviews",
  "Insurance providers with Direct Billing": "Insurance providers with Direct Billing",
  "Similar Doctors": "Similar Doctors",
  "Call Now": "Call Now",
  reviews: "reviews",
  "No reviews yet": "No reviews yet",
  HeliumDoc: "HeliumDoc",
  "2020 HeliumDoc Inc.": "2020 HeliumDoc Inc.",
  Areas: "Areas",
  "About us": "About us",
  Blog: "Blog",
  "Terms of Use": "Terms of Use",
  "Search doctors, procedures, clinics": "Search doctors, procedures, clinics",
  "General Care": "General Care",
  "Specialized Care": "Specialized Care",
  "Other Care": "Other Care",
  "Are you a Doctor?": "Are you a Doctor?",
  "Internal Dashboard": "Internal Dashboard",
  "Clinic Dashboard": "Clinic Dashboard",
  "Send SMS": "Send SMS",
  "Bookings Report": "Bookings Report",
  "Bookings Management": "Bookings Management",
  "Reviews Management": "Reviews Management",
  "Practice Management": "Practice Management",
  "Invoice Management": "Invoice Management",
  "Sign up": "Sign up",
  "Do you need any help? Message us": "Do you need any help? Message us",
  "Thank you for using HeliumDoc": "Thank you for using HeliumDoc",
  "Thank you for booking an appointment through HeliumDoc": "Thank you for booking an appointment through HeliumDoc",
  "only accepts Walk-ins. You can visit the clinic at these times:":
    "only accepts Walk-ins. You can visit the clinic at these times:",
  "Please visit": "Please visit",
  "page for more information about the location of the clinic.":
    "page for more information about the location of the clinic.",
  "Your appointment has been confirmed for": "Your appointment has been confirmed for",
  with: "with",
  "to confirm the date and time of your appointment with": "to confirm the date and time of your appointment with",
  "Back to Doctor Listings": "Back to Doctor Listings",
  "To help the clinic provide the best care, please complete your additional info":
    "To help the clinic provide the best care, please complete your additional info",
  "Please choose a gender.": "Please choose a gender.",
  "Enter your Email": "Enter your Email",
  Reason: "Reason",
  "Select a Reason": "Select a Reason",
  Send: "Send",
  "Refer friends & family and get free vouchers!": "Refer friends & family and get free vouchers!",
  "For every 2 people who make a booking through the link get a <strong>QR 100  voucher</strong> for Carrefour or Sephora or Talabat.":
    "For every 2 people who make a booking through the link get a <strong>QR 100  voucher</strong> for Carrefour or Sephora or Talabat.",
  "Share with:": "Share with:",
  "I just booked a doctor on HeliumDoc, check them out you can find and book with  the best doctors in Qatar.":
    "I just booked a doctor on HeliumDoc, check them out you can find and book with  the best doctors in Qatar.",
  "Copy and share the link below:": "Copy and share the link below:",
  "We will contact you for your reward when your link has been used!":
    "We will contact you for your reward when your link has been used!",
  "Sharing Successful!": "Sharing Successful!",
  "Thank you for sharing,": "Thank you for sharing,",
  "Doctor is currently on leave until": "Doctor is currently on leave until",
  "Doctor is currently on leave until further notice": "Doctor is currently on leave until further notice",
  "You are booking an appointment with": "You are booking an appointment with",
  "Search Specialities": "Search Specialities",
  "Download our App": "Download our App",
  "Follow Us": "Follow Us",
  "Find the Best Doctors in": "Find the Best Doctors in",
  "Find a doctor instantly": "Find a doctor instantly",
  "Filter quickly by insurance, specialty, and location.": "Filter quickly by insurance, specialty, and location.",
  "Make informed decisions": "Make informed decisions",
  "Research doctors with patient reviews.": "Research doctors with patient reviews.",
  "Book Online": "Book Online",
  "Instantly book online and receive text reminders.": "Instantly book online and receive text reminders.",
  "Book an appointment": "Book an appointment",
  "404 - Page not Found | HeliumDoc": "404 - Page not Found | HeliumDoc",
  "404. Page Not Found": "404. Page Not Found",
  "We couldn't find the page that you're looking for!": "We couldn't find the page that you're looking for!",
  "Back to Home": "Back to Home",
  "500. Server Error": "500. Server Error",
  "Sorry, some error seems to have occurred. We will be back soon!":
    "Sorry, some error seems to have occurred. We will be back soon!",
  "Select City": "Select City",
  Cities: "Cities",
  "Search Areas": "Search Areas",
  "Search Cities": "Search Cities",
  "Please Select": "Please Select",
  close: "close",
  "Clear All": "Clear All",
  Clear: "Clear",
  "Nothing found here!": "Nothing found here!",
  "View more": "View more",
  "View less": "View less",
  applied: "applied",
  "Specialities:": "Specialities:",
  "Area:": "Area:",
  "Area of": "Area of",
  "We can't find an offer matching your preferences": "We can't find an offer matching your preferences",
  "These doctors have your discount!": "These doctors have your discount!",
  "Choose one to continue": "Choose one to continue",
  "Sort By": "Sort By",
  selected: "selected",
  "Sort By:": "Sort By:",
  Popular: "Popular",
  "Call to Book an Appointment": "Call to Book an Appointment",
  "Branch:": "Branch:",
  "Branches of": "Branches of",
  "Working Hours at": "Working Hours at",
  "Ramadan Working Hours at": "Ramadan Working Hours at",
  "This clinic does not accept any insurance with Direct Billing":
    "This clinic does not accept any insurance with Direct Billing",
  "This doctor does not accept any insurance with Direct Billing":
    "This doctor does not accept any insurance with Direct Billing",
  "Booking Details": "Booking Details",
  "View Prices": "View Prices",
  Discounts: "Discounts",
  "Clinic Location": "Clinic Location",
  "Share this doctor with a friend": "Share this doctor with a friend",
  Prices: "Prices",
  "Call Support": "Call Support",
  "Call Clinic": "Call Clinic",
  "For admin Eyes only :)": "For admin Eyes only :)",
  "Location of": "Location of",
  Careers: "Careers",
  "Contact Us": "Contact Us",
  "For clinics": "For clinics",
  Widgets: "Widgets",
  "Need help finding a doctor?": "Need help finding a doctor?",
  "Call now and our team will help you find the best doctor for your case!":
    "Call now and our team will help you find the best doctor for your case!",
  "No, I can find one myself": "No, I can find one myself",
  "Patients can request booking within few clicks even if your phones are busy  or it's midnight and your clinic is closed.":
    "Patients can request booking within few clicks even if your phones are busy  or it's midnight and your clinic is closed.",
  "Build your online presence and reputation by having your patients share  their experience on your profile with everyone to see.":
    "Build your online presence and reputation by having your patients share  their experience on your profile with everyone to see.",
  "Let your patients know the benefits of your clinic and why they should  choose your service.":
    "Let your patients know the benefits of your clinic and why they should  choose your service.",
  "Let your patients know you beyond your profession by sharing your philosophy  towards medicine and treatment.":
    "Let your patients know you beyond your profession by sharing your philosophy  towards medicine and treatment.",
  "Have photos of you and your clinic for patients to feel comfortable going to  you.":
    "Have photos of you and your clinic for patients to feel comfortable going to  you.",
  "Allow patients to send a booking request anytime, even when your clinic is  closed.":
    "Allow patients to send a booking request anytime, even when your clinic is  closed.",
  "Why should I be on HeliumDoc?": "Why should I be on HeliumDoc?",
  "Patients can request booking within few clicks even if your phones are busy  or it's midnight and your clinic is closed":
    "Patients can request booking within few clicks even if your phones are busy  or it's midnight and your clinic is closed",
  "Patients can request booking within few clicks. Even if your phones are busy  or your clinic is closed, patients will always be able to request an  appointment.":
    "Patients can request booking within few clicks. Even if your phones are busy  or your clinic is closed, patients will always be able to request an  appointment.",
  "Your profile will be customized with your credentials and specialties to let  your patients know more about you!":
    "Your profile will be customized with your credentials and specialties to let  your patients know more about you!",
  "Updated on July 11, 2017": "Updated on July 11, 2017",
  Preface: "Preface",
  "Selected:": "Selected:",
  "My Appointments": "My Appointments",
  Upcoming: "Upcoming",
  Completed: "Completed",
  Cancelled: "Cancelled",
  "Please specify other reason": "Please specify other reason",
  "Why book through HeliumDoc?": "Why book through HeliumDoc?",
  "Instant Appointments": "Instant Appointments",
  "Free Cancellation": "Free Cancellation",
  "Exciting Offers": "Exciting Offers",
  "Get all information about a doctor instantly without calling the clinic.":
    "Get all information about a doctor instantly without calling the clinic.",
  "Get exciting discounts and offers with doctors when booking with HeliumDoc!":
    "Get exciting discounts and offers with doctors when booking with HeliumDoc!",
  "We get it - sometime things come up and you have to decline an appointment, you can re-book anytime the same appointment from your profile.":
    "We get it - sometime things come up and you have to decline an appointment, you can re-book anytime the same appointment from your profile.",
  "Reschedule Appointment": "Reschedule Appointment",
  "Cancel Appointment": "Cancel Appointment",
  "Cancel Appointment?": "Cancel Appointment?",
  "Pay for Consultation": "Pay for Consultation",
  "Join Call": "Join Call",
  "You don't have any cancelled appointments at the moment.":
    "You don't have any cancelled appointments at the moment.",
  "You don’t have any upcoming appointments at the moment.": "You don’t have any upcoming appointments at the moment.",
  "You don't have any completed appointments at the moment.":
    "You don't have any completed appointments at the moment.",
  "After cancelling any appointment, you will see it here.": "After cancelling any appointment, you will see it here.",
  "After scheduling any appointment, you will see it here.": "After scheduling any appointment, you will see it here.",
  "After completing any appointment, you will see it here.": "After completing any appointment, you will see it here.",
  "Start booking!": "Start booking!",
  "Book again": "Book again",
  "Available slots for": "Available slots for",
  "Choose a new Date & Time": "Choose a new Date & Time",
  "Provide Patient Details": "Provide Patient Details",
  "Details will be sent to the clinic you are booking": "Details will be sent to the clinic you are booking",
  "Previous Date & Time": "Previous Date & Time",
  "Reason for cancellation:": "Reason for cancellation:",
  "Work Related Reasons": "Work Related Reasons",
  "Personal Emergency": "Personal Emergency",
  "Lack of Transportation": "Lack of Transportation",
  "Don't feel sick": "Don't feel sick",
  "Not in pain anymore": "Not in pain anymore",
  "Type your reason here": "Type your reason here",
  "Please choose a reason for cancellation.": "Please choose a reason for cancellation.",
  "Yes, Cancel": "Yes, Cancel",
  "Don't Cancel": "Don't Cancel",
  "Select Date": "Select Date",
  "Select Time": "Select Time",
  "This doctor is not available for this date.": "This doctor is not available for this date.",
  "Please make sure you choose a date and selected a time for this appointment.":
    "Please make sure you choose a date and selected a time for this appointment.",
  "Appointment Cancelled": "Appointment Cancelled",
  "Below appointment has been cancelled.": "Below appointment has been cancelled.",
  "Get directions": "Get directions",
  "Appointment Rescheduled": "Appointment Rescheduled",
  "Thank you for booking an appointment through HeliumDoc, your appointment has been rescheduled.":
    "Thank you for booking an appointment through HeliumDoc, your appointment has been rescheduled.",
  "Clinic Name & Location": "Clinic Name & Location",
  "Patient Booking Details": "Patient Booking Details",
  "Mobile Number": "Mobile Number",
  "With Email": "With Email",
  "With Phone": "With Phone",
  "You can view and manage all your appointments here.": "You can view and manage all your appointments here.",
  "You have to sign in to be able to view your appointments.":
    "You have to sign in to be able to view your appointments.",
  "To help the clinic provide the best care, please complete your additional info.":
    "To help the clinic provide the best care, please complete your additional info.",
  "Thank you for booking an appointment through HeliumDoc, Your appointment has been rescheduled.":
    "Thank you for booking an appointment through HeliumDoc, Your appointment has been rescheduled.",
  "Complete Profile": "Complete Profile",
  "Upload Again": "Upload Again",
  "The other side": "The other side",
  "We require copy of your ID or Passport to continue": "We require copy of your ID or Passport to continue",
  "Back & Front Required": "Back & Front Required",
  "Upload ID": "Upload ID",
  "Upload Passport": "Upload Passport",
  "Upload the Other Side": "Upload the Other Side",
  "Next Step": "Next Step",
  "Upload your insurance or skip this step if you have no insurance":
    "Upload your insurance or skip this step if you have no insurance",
  "No Insurance?": "No Insurance?",
  Skip: "Skip",
  Finish: "Finish",
  "Upload ID or Passport": "Upload ID or Passport",
  "Upload Insurance": "Upload Insurance",
  "Video Consultation": "Video Consultation",
  "insurance available": "insurance available",
  "Visit the doctor via video consultation.": "Visit the doctor via video consultation.",
  "Starting at": "Starting at",
  "Clinic Visit": "Clinic Visit",
  "Video Visit": "Video Visit",
  "Home Visit": "Home Visit",
  "Audio Visit": "Audio Visit",
  "Visit the doctor at their clinic.": "Visit the doctor at their clinic.",
  "Choose Consultation Type": "Choose Consultation Type",
  "You are booking a clinic visit with": "You are booking a clinic visit with",
  "You are booking a video visit with": "You are booking a video visit with",
  "You are booking a home visit with": "You are booking a home visit with",
  "You are booking a audio visit with": "You are booking a audio visit with",
  "Payment Failed": "Payment Failed",
  "Please retry or email": "Please retry or email",
  "If the appointment cannot be confirmed for any reason, you will get a":
    "If the appointment cannot be confirmed for any reason, you will get a",
  "100% refund": "100% refund",
  "Your credit card will not be charged yet.": "Your credit card will not be charged yet.",
  "will call you shortly after submitting your payment information to confirm the date and time of your appointment.":
    "will call you shortly after submitting your payment information to confirm the date and time of your appointment.",
  "Enter your Credit Card Details": "Enter your Credit Card Details",
  "Payment Details": "Payment Details",
  "Your transactions are 100% secure": "Your transactions are 100% secure",
  Pay: "Pay",
  "Submit Payment Information": "Submit Payment Information",
  "Payment Successful": "Payment Successful",
  "An SMS will be sent to you with the details of the appointment.":
    "An SMS will be sent to you with the details of the appointment.",
  "Booking Completed Successfully": "Booking Completed Successfully",
  "Payment Expired": "Payment Expired",
  "Payment links expire": "Payment links expire",
  "minutes after the appointment is created.": "minutes after the appointment is created.",
  "Appointment Details": "Appointment Details",
  "Redirecting you to Complete Profile": "Redirecting you to Complete Profile",
  "Upload Front Side": "Upload Front Side",
  "Passport Image": "Passport Image",
  "No ID?": "No ID?",
  "No Passport?": "No Passport?",
  "Visit the doctor in the audio consultation.": "Visit the doctor in the audio consultation.",
  "Have a Promocode?": "Have a Promocode?",
  "You have to sign in before you can pay.": "You have to sign in before you can pay.",
  "You have to sign in before you can complete your profile.":
    "You have to sign in before you can complete your profile.",
  "Completing your profile is": "Completing your profile is",
  mandatory: "mandatory",
  "before an appointment.": "before an appointment.",
  "Complete My Profile": "Complete My Profile",
  "Please enter your booking details below.": "Please enter your booking details below.",
  "Complete your profile": "Complete your profile",
  "to join the call": "to join the call",
  "Verify your Number": "Verify your Number",
  "Sorry, something went wrong. Please contact our support team for further help!":
    "Sorry, something went wrong. Please contact our support team for further help!",
  "You already have a booking with this doctor on this date. Please choose another date to continue":
    "You already have a booking with this doctor on this date. Please choose another date to continue",
  "resend pin by SMS": "resend pin by SMS",
  "Resend pin by WhatsApp": "Resend pin by WhatsApp",
  "whatsapp-in": "in",
  "Pin successfully sent by WhatsApp": "Pin successfully sent by WhatsApp",
  "Pin sent by WhatsApp": "Pin sent by WhatsApp",
  "Number not found on WhatsApp": "Number not found on WhatsApp",
  "Use OTP instead?": "Use OTP instead?",
  "You have requested the maximum number of allowed OTPs. Please try again in a few hours.":
    "You have requested the maximum number of allowed OTPs. Please try again in a few hours.",
  "OTP has been sent to your number!": "OTP has been sent to your number!",
  "Use password instead?": "Use password instead?",
  "User doesn't exist!": "User doesn't exist!",
  Error: "Error",
  "Not your number? Click here choose another.": "Not your number? Click here choose another.",
  "All your documents were uploaded successfully.": "All your documents were uploaded successfully.",
  "Audio Call Consultation Price": "Audio Call Consultation Price",
  "Clinic Visit Consultation Price": "Clinic Visit Consultation Price",
  "Home Visit Consultation Price": "Home Visit Consultation Price",
  "Video Call Consultation Price": "Video Call Consultation Price",
  "Your credit card will be temporarily charged for a hold amount of":
    "Your credit card will be temporarily charged for a hold amount of",
  "If any copayment will be subject to your insurance coverage. The final amount will be charged only after the clinic calls to inform you about the fee.":
    "If any copayment will be subject to your insurance coverage. The final amount will be charged only after the clinic calls to inform you about the fee.",
  "Show next availability": "Show next availability",
  "Send PIN": "Send PIN",
  "Enter your PIN": "Enter your PIN",
  "Add your number": "Add your number",
  Pin: "Pin",
  "The doctor will visit you at your home.": "The doctor will visit you at your home.",
  "I agree to the": "I agree to the",
  terms: "terms",
  "&": "&",
  "privacy agreements": "privacy agreements",
  "Please agree to the terms & privacy agreements": "Please agree to the terms & privacy agreements",
  "Consultation price": "Consultation price",
  "Starting Price": "Starting Price",
  "Please expect a call back from one of our doctors after Eid Al-Fitr holidays.":
    "Please expect a call back from one of our doctors after Eid Al-Fitr holidays.",
  "*Due to Eid Al-Fitr holidays, this service will resume on May 31.":
    "*Due to Eid Al-Fitr holidays, this service will resume on May 31.",
  "Consult with doctors online from the comfort of your home.":
    "Consult with doctors online from the comfort of your home.",
  "Video Visits": "Video Visits",
  NEW: "NEW",
  "Search Doctors": "Search Doctors",
  "Doctors now offer video visit through HeliumDoc. Look for the video badge below.":
    "Doctors now offer video visit through HeliumDoc. Look for the video badge below.",
  "Search Video Visits": "Search Video Visits",
  "Offers Video Visits": "Offers Video Visits",
  "Offers video visits only": "Offers video visits only",
  "Price Summary": "Price Summary",
  Apply: "Apply",
  "Search Country": "Search Country",
  "Country not found": "Country not found",
  Applied: "Applied",
  "Original Price": "Original Price",
  Total: "Total",
  "Promo Code": "Promo Code",
  "up to": "up to",
  Discount: "Discount",
  "Not offered by doctor": "Not offered by doctor",
  Okay: "Okay",
  "Please call": "Please call",
  "to reschedule your appointment.": "to reschedule your appointment.",
  "We want to hear from you!": "We want to hear from you!",
  "Write your review here": "Write your review here",
  "Please rate your experience": "Please rate your experience",
  "How was the video quality?": "How was the video quality?",
  "How was the audio quality?": "How was the audio quality?",
  "How was the overall experience?": "How was the overall experience?",
  Rate: "Rate",
  "Rating submitted successfully!": "Rating submitted successfully!",
  "Back to HeliumDoc": "Back to HeliumDoc",
  "Thank you for your feedback": "Thank you for your feedback",
  "Your review has been submitted successfully": "Your review has been submitted successfully",
  "No amount will be charged until the clinic calls you to confirm your appointment.":
    "No amount will be charged until the clinic calls you to confirm your appointment.",
  "The consultation fee is an indicative rate added by the clinic. Charges are subject to your insurance coverage if applicable.":
    "The consultation fee is an indicative rate added by the clinic. Charges are subject to your insurance coverage if applicable.",
  remove: "remove",
  "MAIN INFO": "MAIN INFO",
  "EXTRA INFO": "EXTRA INFO",
  "Save Changes": "Save Changes",
  "Date of Birth": "Date of Birth",
  "Uploaded Documents": "Uploaded Documents",
  "ID/Passport": "ID/Passport",
  INSURANCE: "INSURANCE",
  "No ID uploaded yet": "No ID uploaded yet",
  "No insurance uploaded yet": "No insurance uploaded yet",
  "No record uploaded yet": "No record uploaded yet",
  "Upload Another": "Upload Another",
  "Stay Safe!": "Stay Safe!",
  Prescriptions: "Prescriptions",
  prescriptions: "prescriptions",
  "Uploaded by doctors after appointments": "Uploaded by doctors after appointments",
  "Insurance, ID/Passport, Records": "Insurance, ID/Passport, Records",
  "Profile Edited Successfully": "Profile Edited Successfully",
  Edit: "Edit",
  "Name is required": "Name is required",
  "Mobile number successfully changed": "Mobile number successfully changed",
  "Skip for now": "Skip for now",
  "Old Password": "Old Password",
  "New Password": "New Password",
  "Confirm New Password": "Confirm New Password",
  "Confirm new password is required": "Confirm new password is required",
  "Old Password is required": "Old Password is required",
  "Enter your Old Password": "Enter your Old Password",
  "Enter your New Password": "Enter your New Password",
  "Change Password": "Change Password",
  "Date of birth is required": "Date of birth is required",
  "View Prescriptions": "View Prescriptions",
  "Download All": "Download All",
  Change: "Change",
  "Manage your appointments": "Manage your appointments",
  "No Prescriptions Yet": "No Prescriptions Yet",
  "You will find doctor prescriptions here after your appointments":
    "You will find doctor prescriptions here after your appointments",
  "Edit Profile": "Edit Profile",
  "Change Mobile Number": "Change Mobile Number",
  Sex: "Sex",
  "New password is required": "New password is required",
  "View or edit your information": "View or edit your information",
  "Current and new mobile number are same!": "Current and new mobile number are same!",
  "Gender is required": "Gender is required",
  RECORDS: "RECORDS",
  Download: "Download",
  "Email address is not valid": "Email address is not valid",
  "Add Email": "Add Email",
  "Email Added Successfully": "Email Added Successfully",
  "Email Already Added": "Email Already Added",
  "Add Password": "Add Password",
  "No email added yet": "No email added yet",
  "Password Added Successfully": "Password Added Successfully",
  "You have to sign in to be able to view your profile.": "You have to sign in to be able to view your profile.",
  "Enter Patient Full Name": "Enter Patient Full Name",
  "Dashboard for HeliumDoc Staff": "Dashboard for HeliumDoc Staff",
  "Reports & Analytics on your doctors": "Reports & Analytics on your doctors",
  "Send SMS to patients for reviews": "Send SMS to patients for reviews",
  "List of patient bookings details": "List of patient bookings details",
  "Confirm patient booking requests": "Confirm patient booking requests",
  "List of patient reviews on your doctors": "List of patient reviews on your doctors",
  "Appointments, Calendar, Patient List": "Appointments, Calendar, Patient List",
  "Patient Bills": "Patient Bills",
  "Medical Report": "Medical Report",
  "No Medical Reports uploaded yet. Store all your medical reports here.":
    "No Medical Reports uploaded yet. Store all your medical reports here.",
  "Medical Reports": "Medical Reports",
  "Upload Medical Reports": "Upload Medical Reports",
  "Medical Jobs in UAE": "Medical Jobs in UAE",
  "Medical Jobs in Qatar": "Medical Jobs in Qatar",
  "Add your Mobile Number": "Add your Mobile Number",
  "Find Clinics by": "Find Clinics by",
  "Find Doctors by": "Find Doctors by",
  Doha: "Doha",
  "How can I find the best": "How can I find the best",
  "You can filter your specialists based on location, treatment, language, insurance, popularity and gender. This helps you find the doctor best suited to your needs in":
    "You can filter your specialists based on location, treatment, language, insurance, popularity and gender. This helps you find the doctor best suited to your needs in",
  "You can also read the ratings and reviews against each doctor.":
    "You can also read the ratings and reviews against each doctor.",
  "How can I find a doctor who speaks my language?": "How can I find a doctor who speaks my language?",
  "When you search for": "When you search for",
  ", you will be able to see Filters on the right. The last filter is Language. You can choose the language of your liking and the doctors that speak it will be displayed.":
    ", you will be able to see Filters on the right. The last filter is Language. You can choose the language of your liking and the doctors that speak it will be displayed.",
  "How can I make an appointment with instant confirmation?":
    "How can I make an appointment with instant confirmation?",
  "On some": "On some",
  "cards, you will find a blue label “Available at a certain time”. Doctors with these buttons are available for instant confirmation and require no confirmation call.":
    "cards, you will find a blue label “Available at a certain time”. Doctors with these buttons are available for instant confirmation and require no confirmation call.",
  "How can I book an appointment online with a": "How can I book an appointment online with a",
  "To book an appointment with HeliumDoc follow these steps:":
    "To book an appointment with HeliumDoc follow these steps:",
  "1. On the HeliumDoc homepage search for the treatment or doctor by typing in the search bar. You can also search by choosing a speciality and location In":
    "1. On the HeliumDoc homepage search for the treatment or doctor by typing in the search bar. You can also search by choosing a speciality and location In",
  "2. After filtering to find the doctor best suited to your needs, you can click the ‘Book Now’ or ‘Available at a certain time’ button on the doctor card.":
    "2. After filtering to find the doctor best suited to your needs, you can click the ‘Book Now’ or ‘Available at a certain time’ button on the doctor card.",
  "3. If that doctor is offering a video visit, you will be able to choose between ‘Video visit’ and ‘Clinic visit.’":
    "3. If that doctor is offering a video visit, you will be able to choose between ‘Video visit’ and ‘Clinic visit.’",
  "How can I find a doctor who takes my insurance?": "How can I find a doctor who takes my insurance?",
  "HeliumDoc lets you search specifically for": "HeliumDoc lets you search specifically for",
  "who accept your insurance in": "who accept your insurance in",
  "After searching for a speciality or doctor, just choose your insurance from the Insurance dropdown at the top of the page.":
    "After searching for a speciality or doctor, just choose your insurance from the Insurance dropdown at the top of the page.",
  "How can I book a video visit with a": "How can I book a video visit with a",
  "Select the Video Visits option on the right side of the page. After choosing the doctor of your choice, click on Book Now and when asked the consultation type, choose video visit. After filling the form, make the online payment.":
    "Select the Video Visits option on the right side of the page. After choosing the doctor of your choice, click on Book Now and when asked the consultation type, choose video visit. After filling the form, make the online payment.",
  "How can I find a top-rated": "How can I find a top-rated",
  "You can go to the doctor’s profile to view the ratings and reviews for that doctor. These have been left by patients who have consulted the specialist. You can also filter the doctors by choosing ‘Most Recommended’ or ‘Most Popular’ to see the top doctors in":
    "You can go to the doctor’s profile to view the ratings and reviews for that doctor. These have been left by patients who have consulted the specialist. You can also filter the doctors by choosing ‘Most Recommended’ or ‘Most Popular’ to see the top doctors in",
  "Are video visits with": "Are video visits with",
  "covered by my insurance?": "covered by my insurance?",
  "Most insurers in": "Most insurers in",
  "provide coverage for video visits at the same cost as clinic visits. You can search on HeliumDoc specifically for":
    "provide coverage for video visits at the same cost as clinic visits. You can search on HeliumDoc specifically for",
  "who accept your insurance for video visits by selecting your insurance company from the drop-down menu at the top of the page.":
    "who accept your insurance for video visits by selecting your insurance company from the drop-down menu at the top of the page.",
  "Can I cancel an appointment?": "Can I cancel an appointment?",
  "For appointments paid for online, you can cancel or reschedule up to two hours before the scheduled time of the appointment. An appointment cannot be rescheduled or cancelled after the given time frame. After cancelling the appointment, you can schedule another appointment or ask for a refund. The refund is subject to a 5% payment gateway fee. If for some reason you do not attend the appointment, the appointment will be marked as a no-show and you will not be entitled to a refund.":
    "For appointments paid for online, you can cancel or reschedule up to two hours before the scheduled time of the appointment. An appointment cannot be rescheduled or cancelled after the given time frame. After cancelling the appointment, you can schedule another appointment or ask for a refund. The refund is subject to a 5% payment gateway fee. If for some reason you do not attend the appointment, the appointment will be marked as a no-show and you will not be entitled to a refund.",
  "How can I make an appointment with": "How can I make an appointment with",
  "HeliumDoc allows you to book appointments online without the hassle of calling the clinic.":
    "HeliumDoc allows you to book appointments online without the hassle of calling the clinic.",
  "Click here": "Click here ",
  "to book an appointment online with": "to book an appointment online with",
  "You will get a call back from the clinic to confirm your appointment.":
    "You will get a call back from the clinic to confirm your appointment.",
  Does: "Does",
  "accept my insurance?": "accept my insurance?",
  "to see a list of insurance plans he accepts.": "to see a list of insurance plans he accepts.",
  "How much does": "How much does",
  "charge & how do I pay?": "charge & how do I pay?",
  "Where is": "Where is",
  "clinic located in": "clinic located in",
  "works at:": "works at:",
  "for the google map location.": "for the google map location.",
  "What is the education and qualifications of": "What is the education and qualifications of",
  "has the following degrees and diplomas:": "has the following degrees and diplomas:",
  "Are there any additional charges to book an appointment with a":
    "Are there any additional charges to book an appointment with a",
  "through HeliumDoc?": "through HeliumDoc?",
  "HeliumDoc bridges the gap between the patient and the doctor. There are no additional charges for booking through HeliumDoc.":
    "HeliumDoc bridges the gap between the patient and the doctor. There are no additional charges for booking through HeliumDoc.",
  charges: "charges",
  "for consultation for cash patients. You will pay at the reception when you visit the doctor. There are no additional charges when you book through HeliumDoc.":
    "for consultation for cash patients. You will pay at the reception when you visit the doctor. There are no additional charges when you book through HeliumDoc.",
  "What timings for": "What timings for",
  "are available?": "are available?",
  "to request an appointment with the doctor and the clinic will call to share the doctor’s schedule.":
    "to request an appointment with the doctor and the clinic will call to share the doctor’s schedule.",
  "What languages does": "What languages does",
  "speak?": "speak?",
  He: "He",
  She: "She",
  "What has the experience of patients been with": "What has the experience of patients been with",
  "speaks the following": "speaks the following ",
  "What are common reasons for patients to see": "What are common reasons for patients to see",
  "frequently sees patients for": "frequently sees patients for",
  "reviews have been added against": "reviews have been added against",
  "profile and his average rating is": "profile and his average rating is",
  "How do I know what are the available specialties at": "How do I know what are the available specialties at",
  "When you search for a clinic, the specialities available are displayed under the Doctor tab.":
    "When you search for a clinic, the specialities available are displayed under the Doctor tab.",
  "Does the": "Does the",
  FAQs: "FAQs",
  "to see a list of insurance plans supported at": "to see a list of insurance plans supported at",
  "Are there any additional charges to book an appointment with a clinic through HeliumDoc?":
    "Are there any additional charges to book an appointment with a clinic through HeliumDoc?",
  "There are no additional charges when you book through HeliumDoc.":
    "There are no additional charges when you book through HeliumDoc.",
  "How do I know what are the available treatments at": "How do I know what are the available treatments at",
  "When you search for a clinic, go to the Treatments tab to list the treatments being offered at that clinic.":
    "When you search for a clinic, go to the Treatments tab to list the treatments being offered at that clinic.",
  "located in": "located in",
  "What are the consultation charges of doctors in": "What are the consultation charges of doctors in",
  "The consultation for doctors in": "The consultation for doctors in",
  doctors: "doctors",
  "ranges from": "ranges from",
  "for cash patients. You will pay at the reception when you visit the doctor.":
    "for cash patients. You will pay at the reception when you visit the doctor.",
  is: "is",
  "How can I find the best clinic in": "How can I find the best clinic in",
  "Popular clinics in": "Popular clinics in",
  "have a rating against their names. These ratings are a compilation of patient reviews for the doctors who serve in that specific clinic.":
    "have a rating against their names. These ratings are a compilation of patient reviews for the doctors who serve in that specific clinic.",
  "How can I book an appointment?": "How can I book an appointment?",
  "You can search for the clinic in the search bar and book with the desired doctor. You can also call us at":
    "You can search for the clinic in the search bar and book with the desired doctor. You can also call us at",
  "from 9AM to 5PM to book your appointment.": "from 9AM to 5PM to book your appointment.",
  "How can I find a clinic nearest to me?": "How can I find a clinic nearest to me?",
  "Click on the “Filters” option under the Clinics tab. Then click on City and select your desired area within the chosen city. You will be redirected to a list of clinics located nearest to you.":
    "Click on the “Filters” option under the Clinics tab. Then click on City and select your desired area within the chosen city. You will be redirected to a list of clinics located nearest to you.",
  "How can I find a clinic who accepts my insurance?": "How can I find a clinic who accepts my insurance?",
  "Click on the “Filters” option under the Clinics tab. Then click on Insurance and select your desired insurance. You will be redirected to a list of clinics that accept the chosen insurance.":
    "Click on the “Filters” option under the Clinics tab. Then click on Insurance and select your desired insurance. You will be redirected to a list of clinics that accept the chosen insurance.",
  "Please specify reason": "Please specify reason",
  "Edit Review": "Edit Review",
  "Delete Review": "Delete Review",
  Delete: "Delete",
  "Delete Review?": "Delete Review?",
  "Are you sure you want to delete this review?": "Are you sure you want to delete this review?",
  "Contact Numbers": "Contact Numbers",
  "Doctor Works at Multiple Clinics": "Doctor Works at Multiple Clinics",
  "Insurance Providers": "Insurance Providers",
  "Filter companies or plans": "Filter companies or plans",
  "Show Less": "Show Less",
  "Show More": "Show More",
  "No plans to show": "No plans to show",
  "Your search term matches with no company or plan": "Your search term matches with no company or plan",
  "Have you visited this clinic before?": "Have you visited this clinic before?",
  "What's Next": "What's Next",
  "You have submitted your booking": "You have submitted your booking",
  Booking: "Booking",
  Confirmation: "Confirmation",
  "Your booking is confirmed by": "Your booking is confirmed by",
  "will contact you shortly to confirm your booking": "will contact you shortly to confirm your booking",
  Visit: "Visit",
  See: "See",
  "after appointment confirmation.": "after appointment confirmation.",
  "Your response has been saved successfully": "Your response has been saved successfully",
  "Pay at Clinic": "Pay at Clinic",
  "Pay Online Now": "Pay Online Now",
  "Cannot pay online just yet": "Cannot pay online just yet",
  "We are working hard to give you the option to pay online.":
    "We are working hard to give you the option to pay online.",
  "As of now you are kindly requested to pay at the clinic.":
    "As of now you are kindly requested to pay at the clinic.",
  Ok: "Ok",
  "Loading Doctors": "Loading Doctors...",
  "Feedback Submitted": "Feedback Submitted",
  "Your feedback has already been submitted!": "Your feedback has already been submitted!",
  "Feedback has been successfully submitted!": "Feedback has been successfully submitted!",
  plans: "plans",
  "Insurance Plans": "Insurance Plans",
  "Other Insurance Companies": "Other Insurance Companies",
  "No insurance companies to show": "No insurance companies to show",
  clinics: "clinics",
  "We are working hard to give you the option to pay online in installments.":
    "We are working hard to give you the option to pay online in installments.",
  "Pay Online in Installments": "Pay Online in Installments",
  "Cannot Pay in Installments Just Yet": "Cannot Pay in Installments Just Yet",
  "Preferred Booking Time": "Preferred Booking Time",
  "Choose when you would like to see your doctor and we'll do our best to make it happen":
    "Choose when you would like to see your doctor and we'll do our best to make it happen",
  Day: "Day",
  "Within 24 Hours": "Within 24 Hours",
  "Within 48 Hours": "Within 48 Hours",
  Later: "Later",
  Time: "Time",
  Morning: "Morning",
  Afternoon: "Afternoon",
  Evening: "Evening",
  "Your appointment has been cancelled. Thank you for your feedback!":
    "Your appointment has been cancelled. Thank you for your feedback!",
  "Cannot Cancel": "Cannot Cancel",
  "You can no longer cancel this appointment because its time has already passed":
    "You can no longer cancel this appointment because its time has already passed",
  "Help us serve you better": "Help us serve you better",
  "Please let us know why you’re cancelling so we can server you better next time":
    "Please let us know why you’re cancelling so we can server you better next time",
  "* Please choose a reason for cancellation.": "* Please choose a reason for cancellation.",
  "Are you sure you want to cancel your appointment?": "Are you sure you want to cancel your appointment?",
  "You will not get a refund.": "You will not get a refund.",
  "Your free discount will no longer be valid.": "Your free discount will no longer be valid.",
  "You will not get a refund if your payment method has already been charged.":
    "You will not get a refund if your payment method has already been charged.",
  "Clinics List": "Clinics List",
  "Doctors List": "Doctors List",
  "covered by": "covered by",
  "Book Appointment with": "Book Appointment with",
  speaking: "speaking",
  "prices in": "prices in",
  "Please fill out complete card details.": "Please fill out complete card details.",
  "Your card number is incomplete.": "Your card number is incomplete.",
  "Your card number is invalid.": "Your card number is invalid.",
  "Your card's expiration date is incomplete.": "Your card's expiration date is incomplete.",
  "Your card's expiration year is in the past.": "Your card's expiration year is in the past.",
  "Your card's security code is incomplete.": "Your card's security code is incomplete.",
  "No-Show Fee": "No-Show Fee",
  "Booking with HeliumDoc is": "Booking with HeliumDoc is",
  Secure: "Secure",
  "Free.": "Free.",
  "If you miss your appointment, a no-show fee of AED 50 will be deducted.":
    "If you miss your appointment, a no-show fee of AED 50 will be deducted.",
  "If you choose to": "If you choose to",
  cancel: "cancel",
  reschedule: "reschedule",
  "your appointment 12 hours prior to the booking time, you":
    "your appointment 12 hours prior to the booking time, you",
  "will not be charged.": "will not be charged.",
  "فلن يتم تحصيل الرسوم": "فلن يتم تحصيل الرسوم",
  منكم: "منكم",
  "We understand that emergencies happen.": "We understand that emergencies happen.",
  "Best Discounts in": "Best Discounts in",
  "Best Savers": "Best Savers",
  "See all": "See all",
  "Get Discount": "Get Discount",
  save: "save",
  "Expiring Soon": "Expiring Soon",
  "Discount Categories": "Discount Categories",
  "Discount Category": "Discount Category",
  "All Categories": "All Categories",
  "Search Discount Categories": "Search Discount Categories",
  Recommended: "Recommended",
  "Recommended Discounts": "Recommended Discounts",
  Discount: "Discount",
  Discounts: "Discounts",
  "Discounts By Area": "Discounts By Area",
  "Expires in 3 days": "Expires in 3 days",
  "Expires in 2 days": "Expires in 2 days",
  "Last Day": "Last Day",
  "Expires on": "Expires on",
  "Expires in 24 hours": "Expires in 24 hours",
  "Best Savers in": "Best Savers in",
  "Choose Area": "Choose Area",
  "No Discounts Now": "No Discounts Now",
  "We are working hard to bring you new discounts. Please check later.":
    "We are working hard to bring you new discounts. Please check later.",
  "Go Back": "Go Back",
  "Expiring Soon Discounts": "Expiring Soon Discounts",
  "Discount offered by": "Discount offered by",
  "Choose a Doctor to Continue": "Choose a Doctor to Continue",
  "Read Reviews": "Read Reviews",
  "you save": "you save",
  save: "save",
  "Discount Details": "Discount Details",
  TERMS: "TERMS",
  DESCRIPTION: "DESCRIPTION",
  "Necessary precautions and safety measures must be strictly adhered to safeguard public health. Cosmetic procedures should be sought for therapeutic or reconstructive reasons only.":
    "Necessary precautions and safety measures must be strictly adhered to safeguard public health. Cosmetic procedures should be sought for therapeutic or reconstructive reasons only.",
  "This advertisement permission was granted by MOH based on the professional /activity licenses for both advertising facility and its healthcare providers who should deliver the service / products in accordance with the regulations and the standards set by the licensing authority. MOH is ABSOLUTELY NOT part of this deal or any of its terms.":
    "This advertisement permission was granted by MOH based on the professional /activity licenses for both advertising facility and its healthcare providers who should deliver the service / products in accordance with the regulations and the standards set by the licensing authority. MOH is ABSOLUTELY NOT part of this deal or any of its terms.",
  "Buying the deal is exclusively the responsibility of the consumer. This ad is approved by":
    "Buying the deal is exclusively the responsibility of the consumer. This ad is approved by",
  "License No.": "License No.",
  License: "License",
  Reviews: "Reviews",
  "How it Works": "How it Works",
  "Select a doctor": "Select a doctor",
  "Book the discount and pay online": "Book the discount and pay online",
  "The clinic will confirm your appointment": "The clinic will confirm your appointment",
  "Visit the clinic": "Visit the clinic",
  Next: "Next",
  "Choose Doctor": "Choose Doctor",
  "View on Google Maps": "View on Google Maps",
  reviews: "reviews",
  "Best Saver Discounts": "Best Saver Discounts",
  "Or search other areas": "Or search other areas",
  "Link copied. Now you can share it with a friend!": "Link copied. Now you can share it with a friend!",
  "To avail this offer is solely the responsibility of the client and this advertisement has been approved by":
    "To avail this offer is solely the responsibility of the client and this advertisement has been approved by",
  "The permit to advertise has been given by the": "The permit to advertise has been given by the",
  "considering the advertisement and the healthcare facility should deliver whilst abiding by the rules set by the authorities. It should be noted that":
    "considering the advertisement and the healthcare facility should deliver whilst abiding by the rules set by the authorities. It should be noted that",
  "is not involved in the campaign.": "is not involved in the campaign.",
  "All preventive measures must be taken to protect public health. Individuals should undergo cosmetic treatments for the purpose of therapy, to boost self-confidence or for reconstructive reasons as advised by professionals.":
    "All preventive measures must be taken to protect public health. Individuals should undergo cosmetic treatments for the purpose of therapy, to boost self-confidence or for reconstructive reasons as advised by professionals.",
  "No reviews yet.": "No reviews yet.",
  "Show Less Categories": "Show Less Categories",
  "Show More Categories": "Show More Categories",
  "Expiring Soon Discounts": "Expiring Soon Discounts",
  "Doctors now offer video visit through HeliumDoc. Look for the video badge below":
    "Doctors now offer video visit through HeliumDoc. Look for the video badge below",
  "See Clinics": "See Clinics",
  SPECIALITIES: "SPECIALITIES",
  AREA: "AREA",
  INSURANCES: "INSURANCES",
  "Password Changed Successfully": "Password Changed Successfully",
  "New password does not match!": "New password does not match!",
  "Price starting from": "Price starting from",
  "No password added yet": "No password added yet",
  "Discounts in": "Discounts in",
  Best: "Best",
  Discounts: "Discounts",
  in: "in",
  "What medical insurance plans are available in": "What medical insurance plans are available in",
  "The medical insurance plans available in": "The medical insurance plans available in",
  are: "are",
  "What are the medical specialties and the list of doctors covered by":
    "What are the medical specialties and the list of doctors covered by",
  "To know the medical specialties and the list of doctors covered by":
    "To know the medical specialties and the list of doctors covered by",
  "go to the top of the page and browse the list of doctors covered by insurance":
    "go to the top of the page and browse the list of doctors covered by insurance",
  "What hospitals are covered by": "What hospitals are covered by",
  "You can go to the top of the page and you will find a list of hospitals covered by":
    "You can go to the top of the page and you will find a list of hospitals covered by",
  "below the list of doctors covered by": "below the list of doctors covered by",
  Does: "Does",
  "insurance cover orthodontics": "insurance cover orthodontics",
  "Orthodontic coverage in": "Orthodontic coverage in",
  "depends on your insurance plan with the insurance company, because insurance coverage varies according to the medical insurance plans of":
    "depends on your insurance plan with the insurance company, because insurance coverage varies according to the medical insurance plans of",
  "Is pregnancy covered by": "Is pregnancy covered by",
  insurance: "insurance",
  "Pregnancy coverage in": "Pregnancy coverage in",
  "Is laser eye surgery covered by": "Is laser eye surgery covered by",
  "laser eye surgery coverage in": "laser eye surgery coverage in",
  "Is breast cancer treatment covered by": "Is breast cancer treatment covered by",
  "Breast cancer treatment coverage in": "Breast cancer treatment coverage in",
  "Load More Clinics": "Load More Clinics",
  "Load More Doctors": "Load More Doctors",
  "Visit Type": "Visit Type",
  "Video Only": "Video Only",
  "Clinic Only": "Clinic Only",
  "Clinic and Video": "Clinic and Video",
  "More Filters": "More Filters",
  "Apply Filters": "Apply Filters",
  "Offers clinic & video visits": "Offers clinic & video visits",
  "Any Plan": "Any Plan",
  Buy1Get1Free: "Buy1Get1Free",
  "Buy 1 Get 1 Free": "Buy 1 Get 1 Free",
  "Buy 1 Get 1 Free!": "Buy 1 Get 1 Free!",
  "buy 1 get 1 for free!": "buy 1 get 1 for free!",
  "buy 1 and get 1 for free!": "buy 1 and get 1 for free!",
  "you get 2 for the price of 1": "you get 2 for the price of 1",
  "you get 2 for the price of 1!": "you get 2 for the price of 1!",
  "2 for the price of 1": "2 for the price of 1",
  "Book the Buy 1 Get 1 Free discount and pay online": "Book the Buy 1 Get 1 Free discount and pay online",
  "You get a link to share with someone to redeem the same discount for free":
    "You get a link to share with someone to redeem the same discount for free",
  "Visit the clinic and get your discount": "Visit the clinic and get your discount",
  "pending confirmation": "pending confirmation",
  "Copy Coupon Link": "Copy Coupon Link",
  "you get this discount for FREE!": "you get this discount for FREE!",
  "you get it for FREE": "you get it for FREE",
  "The clinic will confirm your side of the appointment": "The clinic will confirm your side of the appointment",
  "Link has been copied!": "Link has been copied!",
  "Cancel My Appointment": "Cancel My Appointment",
  "Book the free discount": "Book the free discount",
  "For Doctors": "For Doctors",
  Expired: "Expired",

  "Your card does not have sufficient balance. Please use a card with an adequate fund to complete the transaction.":
    "Your card does not have sufficient balance. Please use a card with an adequate fund to complete the transaction.",
  "This bank account cannot be used. This error occurs if the account is not supported or is not yet verified. Please try with a different bank account.":
    "This bank account cannot be used. This error occurs if the account is not supported or is not yet verified. Please try with a different bank account.",
  "Looks like this card has been declined too many times. Please try again in 24 hours. If the issue does not resolve, make sure that your card is supported and the information entered is correct.":
    "Looks like this card has been declined too many times. Please try again in 24 hours. If the issue does not resolve, make sure that your card is supported and the information entered is correct.",
  "Your card has been declined.": "Your card has been declined.",
  "Our services are not available in the country this card was issued.":
    "Our services are not available in the country this card was issued.",
  "This card has expired.": "This card has expired.",
  "Please check the card's security code.": "",
  "The card number is invalid. Please re-enter the card.": "The card number is invalid. Please re-enter the card.",
  "Incorrect postal code.": "Incorrect postal code.",
  "We are unable to authenticate your payment method.": "We are unable to authenticate your payment method.",
  "Your ZIP code is incomplete.": "Your ZIP code is incomplete.",
  "Clinic Confirmed Your Appointment": "Clinic Confirmed Your Appointment",
  "Clinic Confirms Your Appointment": "Clinic Confirms Your Appointment",
  "will contact you shortly to confirm your walk-in appointment":
    "will contact you shortly to confirm your walk-in appointment",
  "I Want to Get the Discount": "I Want to Get the Discount",
  "Link has been copied!": "Link has been copied!",
  "buy 1 get 1 for free!": "buy 1 get 1 for free!",
  "buy 1 and get 1 for free!": "buy 1 and get 1 for free!",
  "Redeem Discount for free": "Redeem Discount for free",
  "Visit the Clinic": "Visit the Clinic",
  "only accepts walk-ins. After clinic confirms, you can visit the clinic at these times:":
    "only accepts walk-ins. After clinic confirms, you can visit the clinic at these times:",
  "Thank you for booking through HeliumDoc": "Thank you for booking through HeliumDoc",
  "Thank you for booking an appointment through HeliumDoc": "Thank you for booking an appointment through HeliumDoc",
  "will contact you shortly to confirm the date and time of your appointment":
    "will contact you shortly to confirm the date and time of your appointment",
  "You will get a discount coupon link to share with a friend or family member to redeem the free discount.":
    "You will get a discount coupon link to share with a friend or family member to redeem the free discount.",
  "See how it works": "See how it works",
  "Share the discount coupon link with a friend or family member to redeem the free discount.":
    "Share the discount coupon link with a friend or family member to redeem the free discount.",
  "Redeem your discount with": "Redeem your discount with",
  "You have booked and paid the discounted price": "You have booked and paid the discounted price",
  "Book the Discount and Pay Online": "Book the Discount and Pay Online",
  "Get Discount Coupon Link": "Get Discount Coupon Link",
  "How Discount Coupons Work": "How Discount Coupons Work",
  "Enter your payment details to buy the": "Enter your payment details to buy the",
  "Book and Redeem the Free Discount": "Book and Redeem the Free Discount",
  "You have booked and redeemed your discount": "You have booked and redeemed your discount",
  "Discount Coupon Link": "Discount Coupon Link",
  "after appointment confirmation.": "after appointment confirmation.",
  "copy link": "copy link",
  "Please select a clinic": "Please select a clinic",
  "Select Your Clinic": "Select Your Clinic",
  "This discount is not supported for this doctor": "This discount is not supported for this doctor",
  "Your appointment has been rescheduled to": "Your appointment has been rescheduled to",
  Expired: "Expired",
  "Discounted Price": "Discounted Price",
  "Known For": "Known For",
  "Start by entering your mobile number": "Start by entering your mobile number",
  "A pin code was sent to your mobile number": "A pin code was sent to your mobile number",
  "Resend pin": "Resend pin",
  "Verify My Mobile Number": "Verify My Mobile Number",
  "Change number": "Change number",
  "Save Big With Discounts": "Save Big With Discounts",
  "Read our blog": "Read our blog",
  "Read the latest healthcare articles and doctor opinions": "Read the latest healthcare articles and doctor opinions",
  "Our Blog": "Our Blog",
  "This is a Buy 1 Get 1 Free Discount. After you buy the discount, you get a link to share with someone to redeem the discount for free":
    "This is a Buy 1 Get 1 Free Discount. After you buy the discount, you get a link to share with someone to redeem the discount for free",
  "You are booking the": "You are booking the",
  discount: "discount",
  "No upcoming appointments available": "No upcoming appointments available",
  "will call you shortly after paying to confirm the date and time of your appointment.":
    "will call you shortly after paying to confirm the date and time of your appointment.",
  "All bookings and payments made are non-refundable. The amount is only refunded if ":
    "All bookings and payments made are non-refundable. The amount is only refunded if ",
  "cancels your booking.": "cancels your booking.",
  "Credit Card Details": "Credit Card Details",
  "Clinic Confirmed Your Appointment": "Clinic Confirmed Your Appointment",
  "Clinic Confirms Your Appointment": "Clinic Confirms Your Appointment",
  "will contact you shortly to confirm your walk-in appointment":
    "will contact you shortly to confirm your walk-in appointment",
  "I Want to Get the Discount": "I Want to Get the Discount",
  "Link has been copied!": "Link has been copied!",
  "buy 1 get 1 for free!": "buy 1 get 1 for free!",
  "buy 1 and get 1 for free!": "buy 1 and get 1 for free!",
  "Redeem Discount for free": "Redeem Discount for free",
  "Visit the Clinic": "Visit the Clinic",
  "only accepts walk-ins. After clinic confirms, you can visit the clinic at these times:":
    "only accepts walk-ins. After clinic confirms, you can visit the clinic at these times:",
  "Thank you for booking through HeliumDoc": "Thank you for booking through HeliumDoc",
  "Thank you for booking an appointment through HeliumDoc": "Thank you for booking an appointment through HeliumDoc",
  "will contact you shortly to confirm the date and time of your appointment":
    "will contact you shortly to confirm the date and time of your appointment",
  "You will get a discount coupon link to share with a friend or family member to redeem the free discount.":
    "You will get a discount coupon link to share with a friend or family member to redeem the free discount.",
  "See how it works": "See how it works",
  "Share the discount coupon link with a friend or family member to redeem the free discount.":
    "Share the discount coupon link with a friend or family member to redeem the free discount.",
  "Redeem your discount with": "Redeem your discount with",
  "You have booked and paid the discounted price": "You have booked and paid the discounted price",
  "Book the Discount and Pay Online": "Book the Discount and Pay Online",
  "Get Discount Coupon Link": "Get Discount Coupon Link",
  "How Discount Coupons Work": "How Discount Coupons Work",
  "Enter your payment details to buy the": "Enter your payment details to buy the",
  "Book and Redeem the Free Discount": "Book and Redeem the Free Discount",
  "You have booked and redeemed your discount": "You have booked and redeemed your discount",
  "Discount Coupon Link": "Discount Coupon Link",
  "after appointment confirmation.": "after appointment confirmation.",
  "copy link": "copy link",
  "Please select a clinic": "Please select a clinic",
  "Select Your Clinic": "Select Your Clinic",
  "This discount is not supported for this doctor": "This discount is not supported for this doctor",
  "Your appointment has been rescheduled to": "Your appointment has been rescheduled to",
  Expired: "Expired",
  "Discounted Price": "Discounted Price",
  "Known For": "Known For",
  "Start by entering your mobile number": "Start by entering your mobile number",
  "A pin code was sent to your mobile number": "A pin code was sent to your mobile number",
  "Resend pin": "Resend pin",
  "Verify My Mobile Number": "Verify My Mobile Number",
  "Change number": "Change number",
  "Download Our App": "Download Our App",
  "Download our app today and get the best of HeliumDoc. Your wellness is just a tap away!":
    "Download our app today and get the best of HeliumDoc. Your wellness is just a tap away!",
  "Find Doctors by Insurance": "Find Doctors by Insurance",
  "View All": "View All",
  "View All Doctors": "View All Doctors",
  "View All Clinics": "View All Clinics",
  insurance_doctors_1: "Doctors covered by",
  insurance_doctors_2: "Insurance in",
  insurance_clinics_1: "Clinics covered by",
  State: "State",
  "No slots available on this day. Try other days": "No slots available on this day. Try other days",
  "Choose your booking time": "Choose your booking time",
  "Not available": "Not available",
  "slots available": "slots available",
  "slot available": "slot available",
  "This doctor is currently unavailable": "This doctor is currently unavailable",
  "Search other": "Search other",
  "Searching for next availability": "Searching for next availability",
  "Almost there": "Almost there",
  "A few seconds left": "A few seconds left",
};
