import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../../intl/useTranslation";
import { localeChain, optionListMain } from "../../../public/assets/navbarData";
import { getRoute, isMobile, Mixpanel } from "../../../utils";

// just as example
const local = "qatar";

function sidebar({ setMoreIndex }) {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [chosenChain, setChosenChain] = useState(null);
  const [specialists, setSpecialists] = useState([]);

  const { t } = useTranslation();
  const { push } = useRouter();

  const { navbar_specialties, client_side_country, language_code, locale } = useSelector(
    ({ InitialDataS }) => InitialDataS
  );
  const user = useSelector(({ InitialData }) => InitialData.initData);

  useEffect(() => {
    let arr = [];
    for (const ele in navbar_specialties) {
      arr = arr.concat(navbar_specialties[ele]);
    }
    setSpecialists(arr);
  }, []);

  useEffect(() => {
    if (openSidebar) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openSidebar]);

  return (
    <>
      <button
        className="navbar-toggle"
        type="button"
        id="sideTrigger"
        aria-label="Sidebar Button"
        onClick={() => {
          setOpenSidebar((openSidebar) => !openSidebar);
          setMoreIndex((prev) => !prev);
        }}
      >
        <span className="icon-bar mine-shaft"></span>
        <span className="icon-bar mine-shaft"></span>
        <span className="icon-bar mine-shaft"></span>
      </button>
      {openSidebar && (
        <div className="animated generic-sidebar main-sidebar">
          {isMobile() && (
            <div className="sidebar-controller">
              <div
                className="back-command"
                onClick={() => {
                  setOpenSidebar(false);
                  setMoreIndex(false);
                }}
              >
                <span className="meddy-light-chevron chevron-left"></span>
              </div>
              <div className="back-command"></div>
              <div className="sidebar-title">{t("Menu")}</div>
            </div>
          )}
          {!user?.user_is_authenticated && (
            <div className="sidebar-auth-buttons">
              <Link href={getRoute(client_side_country, `/signup`)}>
                <a
                  onClick={() => {
                    Mixpanel.track("Sidebar - Signup Clicked", { "Current URL": window.location.href });
                    setOpenSidebar(false);
                    setMoreIndex(false);
                  }}
                >
                  <button className="button-sm invert-dark text-center sign-up">{t("Sign Up")}</button>
                </a>
              </Link>
              <Link href={getRoute(client_side_country, `/login`)}>
                <a
                  onClick={() => {
                    Mixpanel.track("Sidebar - Login Clicked", { "Current URL": window.location.href });
                    setOpenSidebar(false);
                    setMoreIndex(false);
                  }}
                >
                  <button className="button-sm default-dark text-center log-in">{t("Login")}</button>
                </a>
              </Link>
            </div>
          )}
          <div className="sidebar-options-container">
            {optionListMain(specialists, localeChain, push, client_side_country, language_code).map((option, index) => (
              <div
                key={index}
                tabIndex={index}
                onClick={() => {
                  if (option.chain) {
                    setChosenChain(option);
                  } else {
                    setOpenSidebar(false);
                    option.callback();
                  }
                }}
                className="sidebar-option"
              >
                {t(option.name)}
                {option.chain && <span className="chained-option meddy-light-chevron"></span>}
              </div>
            ))}
          </div>
          <div className="sidebar-social">
            {t("Follow us")}
            <div className="buttons">
              <a
                className="footer-social-media-link"
                href={`https://www.facebook.com/heliumdoc${locale}/`}
                rel="noopener"
                target="_blank"
              >
                <img src="/next-images/Icon_Facebook.svg" className="meddy-icon meddy-icon-invert" />
              </a>

              <a
                className="footer-social-media-link"
                href="https://www.linkedin.com/company/heliumdoc/"
                rel="noopener"
                target="_blank"
              >
                <img src="/next-images/Icon_Linkedin.svg" className="meddy-icon meddy-icon-invert" />
              </a>

              <a
                className="footer-social-media-link"
                href="https://www.instagram.com/heliumdoc/"
                rel="noopener"
                target="_blank"
              >
                <img src="/next-images/Icon_instagram.svg" className="meddy-icon meddy-icon-invert" />
              </a>

              <a
                className="footer-social-media-link"
                href={`https://twitter.com/heliumdoc/`}
                rel="noopener"
                target="_blank"
              >
                <img src="/next-images/Icon_Twitter.svg" className="meddy-icon meddy-icon-invert" />
              </a>
            </div>
          </div>
        </div>
      )}
      {chosenChain?.list && (
        <div className="animated generic-sidebar chain-sidebar">
          <div className="sidebar-controller">
            <div className="back-command" onClick={() => setChosenChain(null)}>
              <span className="meddy-light-chevron chevron-left"></span>
            </div>
            <div className="back-command"></div>
            <div className="sidebar-title">{t(chosenChain.name)}</div>
          </div>
          <div className="sidebar-options-container">
            {chosenChain?.list.map((option, index) => (
              <div
                key={index}
                onClick={() => {
                  if (chosenChain.name === "Specialities") {
                    push(getRoute(client_side_country, `/doctors/${option.slug}`));
                  } else if (chosenChain.name === "Change Country") {
                    window.location.href = `/${
                      ["qatar", "uae"].includes(option.slug || "qatar") && language_code === "ar" ? "ar/" : ""
                    }${option.slug === "qatar" ? "" : option.slug}`;
                  }
                  setChosenChain(null);
                  setOpenSidebar(false);
                }}
                className={`sidebar-option ${option.class}`}
              >
                {chosenChain.name === "Specialities" ? option.name : t(option.name)}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default sidebar;
