import theAxios from "axios";

const AUTH_EXCLUDED_URLS = ["/ars/stratify-risks/", "/ars/result/"];

const Axios = theAxios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_BASE_URL,
  headers: { "x-requested-with": "XMLHttpRequest" },
});

// Add trailing slash
Axios.interceptors.request.use((config) => {
  if (config.url?.[config.url.length - 1] !== "/") {
    config.url += "/";
  }
  if (typeof window !== "undefined" && !AUTH_EXCLUDED_URLS.some((url) => config.url.indexOf(url) != -1)) {
    const token = window.localStorage.getItem("accessToken");
    config.headers.authorization = token ? `Bearer ${token}` : "";
  }

  return config;
});

export const configureAxiosLocale = (locale: string, country: string) => {
  Axios.defaults.headers.common["Accept-Language"] = locale;
  (Axios.interceptors.request as any).handlers = [];
  Axios.interceptors.request.use((config) => {
    if (config.url?.[config.url.length - 1] !== "/") {
      config.url += "/";
    }
    config.url = (locale !== "en" ? "/" + locale : "") + (country !== "qatar" ? "/" + country : "") + config.url;
    return config;
  });
};

export const configureAxiosAuth = (token?: string) => {
  Axios.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";
};

export default Axios;
