import { Fragment } from "react";

function Loader({ transparent, showInPlace = false, containerStyle = {}, loadingText, id = "", loaderTextStyle }) {
  return showInPlace
    ? inPlace({ transparent, containerStyle, loadingText, id, loaderTextStyle })
    : complete({ transparent, containerStyle, loadingText, id });
}

function inPlace({ transparent, containerStyle, loadingText, loaderTextStyle }) {
  let container = {
    height: "140px",
    background: `${transparent ? "transparent" : "#fff"}`,
    zIndex: "999999999999999999999999",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };
  container = { ...container, ...containerStyle };

  let loaderText = {
    fontSize: "18px",
    color: "#555555",
    fontWeight: 300,
  };

  loaderText = { ...loaderText, ...loaderTextStyle };
  return (
    <div style={container}>
      <div
        style={{
          fontSize: "10px",
          left: "calc(50% - 30px)",
          top: "calc(50% - 30px)",
          zIndex: 11000000,
          textIndent: "-9999em",
          border: "3px solid rgba(0, 0, 0, 0.2)",
          borderLeftColor: "#f81e46",
          WebkitTransform: "translateZ(0)",
          transform: "translateZ(0)",
          WebkitAnimation: "load8 1.1s infinite linear",
          animation: "load8 1.1s infinite linear",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
        }}
      />
      {loadingText && <div style={loaderText}>{loadingText}</div>}
    </div>
  );
}

function complete({ transparent, loadingText, id }) {
  return (
    <Fragment>
      <div
        id={id}
        style={{
          height: "100vh",
          width: "100vw",
          background: `${transparent ? "transparent" : "#fff"}`,
          position: "fixed",
          zIndex: "999999999999999999999999",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={
            !loadingText
              ? {
                  margin: "60px auto",
                  fontSize: "10px",
                  position: "fixed",
                  left: "calc(50% - 30px)",
                  top: "calc(50% - 30px)",
                  zIndex: 11000000,
                  textIndent: "-9999em",
                  border: "3px solid rgba(0, 0, 0, 0.2)",
                  borderLeftColor: "#f81e46",
                  WebkitTransform: "translateZ(0)",
                  transform: "translateZ(0)",
                  WebkitAnimation: "load8 1.1s infinite linear",
                  animation: "load8 1.1s infinite linear",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                }
              : {
                  margin: "0 auto 15px",
                  fontSize: "10px",
                  zIndex: 11000000,
                  textIndent: "-9999em",
                  border: "3px solid rgba(0, 0, 0, 0.2)",
                  borderLeftColor: "#f81e46",
                  WebkitTransform: "translateZ(0)",
                  transform: "translateZ(0)",
                  WebkitAnimation: "load8 1.1s infinite linear",
                  animation: "load8 1.1s infinite linear",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                }
          }
        />
        {loadingText && (
          <div
            style={{
              fontSize: "18px",
              color: "#555555",
              fontWeight: 300,
            }}
          >
            {loadingText}
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Loader;
