import { localize, SEO } from "../../utils";
import axios from "axios";
export const GET_DISCOUNT_DATA = "GET_DISCOUNT_DATA";
export const GET_DISCOUNTS_MAIN_PAGE_DATA = "GET_DISCOUNTS_MAIN_PAGE_DATA";
export const GET_DISCOUNTS_AREA = "GET_DISCOUNTS_AREA";
export const GET_DISCOUNTS_LISTING = "GET_DISCOUNTS_LISTING";
export const GET_COUPON_DATA = "GET_COUPON_DATA"
export const CLEAR_COUPON_DATA = "CLEAR_COUPON_DATA"

import { API, SSR } from "../../utils";


export const getDiscountData = ({ discountSlug, client_side_country, locale , coupon_code = null}) => async (dispatch) => {
    try {
        const data = await SSR.getRequest(localize(`bookings/discount/${discountSlug}/${coupon_code?`?coupon_code=${coupon_code}`:""}`, client_side_country, locale))
        await dispatch({
            type: GET_DISCOUNT_DATA,
            payload: { ...data.data, client_side_country: client_side_country },
        });
    } catch (err) {
        return err;
    }
};

export const getDiscountsData = ({ client_side_country, locale }) => async (dispatch) => {
    try {
        const data = await SSR.getRequest(localize(`bookings/discount_categories/`, client_side_country, locale))
        await dispatch({
            type: GET_DISCOUNTS_MAIN_PAGE_DATA,
            payload: { ...data.data, client_side_country: client_side_country },
        });
    } catch (err) {
        return err;
    }
};

export const getDiscountsArea = ({ client_side_country, locale }) => async (dispatch) => {
    try {
        const data = await SSR.getRequest(localize(`bookings/discount_areas/`, client_side_country, locale));
        await dispatch({
            type: GET_DISCOUNTS_AREA,
            payload: { ...data.data, client_side_country: client_side_country },
        });
    } catch (err) {
        return err;
    }
};

export const getDiscountsListing = ({ client_side_country, locale, category, area, type, specialization}) => async (dispatch) => {
    try {
        // let qs = type
        //     ? `type=${type}`
        //     : category && area
        //     ? `category=${category}&area=${area}`
        //     : category
        //     ? `category=${category}`
        //     : area
        //     ? `area=${area}`
        //     : `category=&area=""`;
        
        let params = {
            specialization,
            category,
            area,
            type
        }

        const data = await SSR.getRequest(localize(`bookings/discount_listing/`, client_side_country, locale), params)
        await dispatch({
            type: GET_DISCOUNTS_LISTING,
            payload: { ...data.data, client_side_country: client_side_country },
        });
    } catch (err) {
        return err;
    }
};


export const validateCouponCode = ({discount_code, coupon_code,client_side_country,language_code}) => async (dispatch) => {

    try {
        const data = await API.postRequest(true,localize("bookings/discounts/validate-coupon/",client_side_country,language_code),{
            discount_code,
            coupon_code
        })
    
        await dispatch({
            type: GET_COUPON_DATA,
            payload: data
        })
    } catch (error) {
        if(error?.data?.message){
            await dispatch({
                type: GET_COUPON_DATA,
                payload: error.data
            })
        }
        else{
            return error
        }
        
    }
   
}

export const clearCouponData = () => async (dispatch) => {
    try {
        await dispatch({
            type: CLEAR_COUPON_DATA,
            payload: {}
        })
    } catch (error) {
        return error
    }
    
}


export const verifyDiscount = async ({
  discount_code,
  coupon_code,
  doctor_slug,
  client_side_country,
  language_code,
}) => {
  try {
    return await API.postRequest(
      true,
      localize("bookings/discount/check-doctor/", client_side_country, language_code),
      {
        discount_code,
        coupon_code,
        doctor_slug,
      }
    );
  } catch (error) {}
};

export const getDiscountInfo = async ({ discount_code, coupon_code, client_side_country, language_code }) => {
  try {
    return await API.postRequest(true, localize("bookings/discount_code/", client_side_country, language_code), {
      discount_code,
      coupon_code,
    });
  } catch (error) {}
};