import dayjs from "dayjs";
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

export class Day {
  constructor(day, isAvailable = false) {
    this.raw = day;
    this.date = dayjs(day);
    this.hours = [];
    this.available = isAvailable;
    this.active = false;
    this.defaultHours = [];
  }

  get weekDay() {
    const names = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return names[this.date.weekday()];
  }

  get monthDay() {
    return this.date.format("MMM DD").toUpperCase();
  }

  setHours(data) {
    this.hours = data;

    return this;
  }

  toString() {
    return this.date.format("YYYY-MM-DD");
  }
}
