import { HYDRATE } from 'next-redux-wrapper';
import { FETCH_INSURANCE_DATA, GET_INSURANCE_FIND_BY } from '../actions/insurance';

const initialState = {};

const insurance = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INSURANCE_DATA:
      return { ...state, ...payload };
    case GET_INSURANCE_FIND_BY:
      return { ...state, doctorFindBy: { ...payload } };
    case HYDRATE:
      return { ...state, ...payload.insurance };
    default:
      return state;
  }
};

export default insurance;
