import { GET_COMFIRMATION_DATA } from "../actions/cofirmationBookings";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const confirmation = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_COMFIRMATION_DATA:
      return { ...state, ...payload };
    case HYDRATE:
      return { ...state, ...payload.confirmation };
    default:
      return state;
  }
};

export default confirmation;
