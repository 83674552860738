import { API, localize } from "../../utils";
import { formatedDays } from "../../services/calendarService";

export const FETCH_USER_BOOKING_UPCOMING = "FETCH_USER_BOOKING_UPCOMING";
export const FETCH_USER_BOOKING_CANCELLED = "FETCH_USER_BOOKING_CANCELLED";
export const FETCH_USER_BOOKING_VISITED = "FETCH_USER_BOOKING_VISITED";
export const GET_BOOKING_CANCELLED = "GET_BOOKING_CANCELLED";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const GET_CANCEL_BOOKING_DATA = "GET_CANCEL_BOOKING_DATA";
export const GET_AVAILABLE_TIMINGS = "GET_AVAILABLE_TIMINGS";

export const fetchUserBookingUpcoming = (locale) => async (dispatch) => {
    const data = await API.getRequest(true, localize(`patient/user_bookings/upcoming/`, undefined, locale));

    dispatch({ type: FETCH_USER_BOOKING_UPCOMING, payload: { ...data } });
};

export const fetchUserBookingVisited = (locale) => async (dispatch) => {
    const data = await API.getRequest(true, localize(`patient/user_bookings/visited/`, undefined, locale));

    dispatch({ type: FETCH_USER_BOOKING_VISITED, payload: { ...data } });
};
export const fetchUserBookingCancelled = (locale) => async (dispatch) => {
    const data = await API.getRequest(true, localize(`patient/user_bookings/cancelled/`, undefined, locale));

    dispatch({ type: FETCH_USER_BOOKING_CANCELLED, payload: { ...data } });
};

export const getBookingCancelled = (slug, locale) => async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    const data = await API.getRequest(true, localize(`patient/booking_details/${slug}`, undefined, locale));

    dispatch({ type: GET_BOOKING_CANCELLED, payload: { ...data } });
    dispatch({ type: "SET_LOADING", payload: false });
};

export const cancelAppointment = (appointmentId, cancellationReason) => async (dispatch) => {
    const bodyParams = {
        appointment_id: appointmentId,
        cancellation_reason: cancellationReason,
    };

    const { id } = await API.postRequest(true, `patient/cancel_booking/`, bodyParams);
    return id
};


export const getCancelBookingData = (locale, slug) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADING", payload: true });
        const data = await API.getRequest(true, localize(`bookings/cancel_booking/${slug}/`, undefined, locale));
        if (data) {
            dispatch({ type: "SET_LOADING", payload: false });
            dispatch({ type: GET_CANCEL_BOOKING_DATA, payload: data });
        }
    } catch (err) {
        dispatch({ type: "SET_LOADING", payload: false });
    }
}

export const getAvailabilityTimes = (
    doctorId,
    clinicId,
    startDate = "2019-12-20",
    endDate = "2019-12-30",
    start
) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADING", payload: true });
        const requestBody = {
            doctor_id: doctorId,
            clinic_id: clinicId,
            date_from: startDate,
            date_to: endDate,
        };

        const res = await API.postRequest(false, `patient/available_timing/`, requestBody);
        if (res.success) {
            const data = formatedDays(res.timings, start);
            dispatch({ type: GET_AVAILABLE_TIMINGS, payload: [...data] });
        }
        dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
        dispatch({ type: "SET_LOADING", payload: false });
    }
};
