import { useEffect } from "react";
import { Mixpanel } from "../../utils";

function Paginate({ currentPage, stopPage, startPage, scrollTop, pageName, changePage, clickPaginate, paginateFor }) {
  const range = 2;

  const getPagesList = () => {
    // hard coded to be 5 pages. Fix later.
    let pagesList = new Array(range * 2 + 1);

    for (let index = 0; index < pagesList.length; index++) {
      let n = index - range + currentPage;
      if (n >= startPage && n <= stopPage) {
        pagesList[index] = n;
      }
    }

    pagesList = pagesList.filter(function (elem) {
      return elem;
    });
    return pagesList;
  };

  const trackMixpanel = (page) => {
    Mixpanel.track("Page Pagination | Change Page | Button", {
      "Page Number": page,
      "Page Type": pageName,
    });
  };
  // move pagination to the previous page.
  const handlePreviousClick = () => {
    // disabled if we're on the first page
    if (currentPage === startPage) return;
    trackMixpanel(currentPage - 1);
    changePage(currentPage - 1);
  };

  // move pagination to the next page.
  const handleNextClick = () => {
    // disable if we're on the last page
    if (currentPage === stopPage) return;
    trackMixpanel(currentPage + 1);
    changePage(currentPage + 1);
  };

  useEffect(() => {
    scrollTop
      ? window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      : null;
  }, [currentPage]);
  return (
    <div className="pagination-wrapper">
      <ul className="pagination">
        <li className={`prev ${currentPage == startPage ? "disabled" : ""}`}>
          <a onClick={handlePreviousClick}>
            <div className="pagination-chevron-prev">
              <span className="meddy-light-chevron gray-chevron chevron-left"></span>
            </div>
          </a>
        </li>
        {getPagesList().map((page) => (
          <li key={page} className={page == currentPage ? "active" : ""}>
            {/* todo: move this function to the body of the component for better readability...this single line gives compiled code vibes */}
            <a
              onClick={() => {
                trackMixpanel(page);
                changePage(page);
                if (clickPaginate) {
                  clickPaginate(true);
                }
              }}
            >
              {page}
            </a>
          </li>
        ))}
        <li className={`next ${currentPage == stopPage ? "disabled" : ""}`}>
          <a onClick={handleNextClick}>
            <div className="pagination-chevron">
              <span className="meddy-light-chevron gray-chevron chevron-right"></span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Paginate;
