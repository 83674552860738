/**
 * Date: 2021-3-21
 * Des: this file contains utils function for client side router 
 */

export const VALID_COUNTRIES = Object.freeze({
    QA: null, // 🇶🇦 
    UAE: "uae", // 🇦🇪 
    SA: "sa", // 🇸🇦 
    KW: "kw", // 🇰🇼 
    BD: "bd", // 🇧🇩 
    NG: "ng",
    KE: "ke",
    GH: "gh",
    UG: "ug",
    LR: "lr",
    SN: "sn"
});

/**
 * function read pathname return current country
 * @param {*} pathName 
 * @returns 
 */
export const getCurrentCountry = (pathName) => {
    // expected country 
    const countries = ["/qatar/", "/uae/", "/sa/", "/kw/", "/bd/"];
    // found country 
    let foundCountry = 'qa';
    // search on counries 
    countries.forEach(country => {
        const found = pathName.search(country);
        if (found >= 0) {
            foundCountry = country;
        }
    })
    return foundCountry;
}

/**
 * check if next route is profile
 * @param {*} url 
 * @returns {'isProfile':boolean}
 */
export const checkIsProfile = (url) => {
    // is route to profile 
    const isProfile = url.search('/discount/') >= 0
    return isProfile;
};

/**
 * check if next route is listing 
 * @param {*} patname 
 * @returns {'isListingPage':boolean}
 */
 export const checkIsListing = (pathname) => {
    // check is current route listing  
    const isListing = pathname.search('/listings') >= 0
    return isListing;
};

export const isAfricanCounty = (country_code) => ["ug", "bd", "ke", "ng", "gh", "lr", "sn"].includes(country_code);
