import {
    GET_DISCOUNT_DATA,
    GET_DISCOUNTS_MAIN_PAGE_DATA,
    GET_DISCOUNTS_AREA,
    GET_DISCOUNTS_LISTING,
} from "../actions/discounts";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const discountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_DISCOUNTS_LISTING:
            return { ...payload };
        case HYDRATE:
            return { ...payload.discountListing };
        default:
            return state;
    }
};

export default discountReducer;
