import { Mixpanel } from "../../utils";

export const footerTracker = {
  trackWhatsappSupport: () => {
    Mixpanel.track("Whatsapp Support Clicked", {
      "Current URL": window.location.href,
    });
  },

  trackFacebook: () => {
    Mixpanel.track("Footer - Facebook Clicked", {
      "Current URL": window.location.href,
    });
  },

  trackLinkedIn: () => {
    Mixpanel.track("Footer - LinkedIn Clicked", {
      "Current URL": window.location.href,
    });
  },

  trackTwitter: () => {
    Mixpanel.track("Footer - Twitter Clicked", {
      "Current URL": window.location.href,
    });
  },

  trackInstagram: () => {
    Mixpanel.track("Footer - Instagram Clicked", {
      "Current URL": window.location.href,
    });
  },

  trackAboutUs: (option) => {
    Mixpanel.track("Footer - About Us Clicked", {
      "Current URL": window.location.href,
    });
  },

  trackForClinics: (option) => {
    Mixpanel.track("Footer - For clinics Clicked", {
      "Current URL": window.location.href,
    });
  },

  trackSpec: (option) => {
    Mixpanel.track("Footer - Specialization Clicked", {
      "Current URL": window.location.href,
      "Speciality Name": option,
    });
  },

  trackArea: (option) => {
    Mixpanel.track("Footer - Area Clicked", {
      "Current URL": window.location.href,
      "Area Name": option,
    });
  },
  globalFooterClick: (clickedUrl) => {
    Mixpanel.track("Footer | Any Link Clicked | Button", {
      "Current URL": window.location.href,
      "Clicked URL": clickedUrl,
    });
  },
};
