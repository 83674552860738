import { GET_DOCTOR_REVIEWS } from "../types/doctorProfile";

const initialState = {};

const DoctorReview = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DOCTOR_REVIEWS:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default DoctorReview;
