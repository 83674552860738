import { Mixpanel } from "../../utils";

export const navbarTracker = {
  trackLogin: (href) => {
    Mixpanel.track("Header - Login Clicked", { "Current URL": href });
  },

  trackSignup: (href) => {
    Mixpanel.track("Header - Signup Clicked", { "Current URL": href });
  },

  trackLogout: (href) => {
    Mixpanel.track("Header - Logout Clicked", { "Current URL": href });
  },
};
