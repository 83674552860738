import TagManager from "react-gtm-module";
const { configs } = require("../env_config");

let gtmInitialized = false;

// TODO: CHANGE ARGS BASED ON ENV
// const tagManagerArgs = {
//     gtmId: "GTM-MDXLVBS",
// };

export function InitGtm(locale) {
    const initialize = () => {
        if (typeof window !== 'undefined' && !gtmInitialized) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){dataLayer.push(arguments);}
            
            console.log("Initializing GTM");
            gtmInitialized = true;

            if (process.env.NEXT_PUBLIC_APP_ENV == 'production') {
              let gtmId = 'GTM-5DD88TL';
              TagManager.initialize({ gtmId });

              if (locale == 'uae') {
                gtmId = 'GTM-TZ59LK9';
              } else if (locale == 'kw') {
                gtmId = 'GTM-NK3B2Q9';
              } else if (locale == 'ng') {
                gtmId = 'GTM-KX546VT';
              } else if (locale == 'bd') {
                gtmId = 'GTM-5DCQG8S';
              } else if (locale == 'sa') {
                gtmId = 'GTM-W8ZW2ZM';
              } else {
                gtmId = 'GTM-NX2W8QC';
              }

              TagManager.initialize({ gtmId });

              // if (locale == 'ng') {
              //   TagManager.initialize({ gtmId: 'G-H3P1RLLV80' });
              // }
            } else {
              TagManager.initialize({ gtmId: 'GTM-MDXLVBS' });
            }
            
        }
    }

    initialize()
   
   

 
}

export function TrackGtm(initData, pageData, event) {
    let data = {
        "event": event || "VirtualPageView",
        "virtualPage": window.location.pathname,
        "locale": initData.locale,
        "language": initData.language_code,
        "platform": initData.user_platform,
        "userEmail": initData.user_email,
    };

    data = { ...data, ...pageData };

    let dataLayer = {
        dataLayer: data
    }
    TagManager.dataLayer(dataLayer);
    console.log("Tracking GTM DATA", data);

}
