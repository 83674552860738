import { FETCH_CLINICS, GET_CLINIC, GET_CLINIC_FIND_BY, FETCH_CLINIC_DOCTORS_LISTING } from "../actions/clinics";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    clinicList: null,
    clinicProfile: null,
    clinicFindBy: null,
    doctorsListing: null,
};

const clinics = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CLINICS:
            return { ...state, ...payload };
        case GET_CLINIC:
            return { ...state, clinicProfile: { ...payload } };
        case GET_CLINIC_FIND_BY:
            return { ...state, clinicFindBy: { ...payload } };
        case FETCH_CLINIC_DOCTORS_LISTING:
            return { ...state, doctorsListing: { ...payload } };
        case HYDRATE:
            return { ...payload.clinics };
        default:
            return state;
    }
};

export default clinics;
