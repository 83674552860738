export default {
  // Typography
  typography: {
    fontFamily: '"Mulish", sans-serif',
    fontFamilyAr: "Tajawal, sans-serif",
    htmlBaseFontPx: 10,
    baseLineHeight: 1.5,
  },
  // Colors
  colors: {
    textDark: "#282828",
    textDarkGray: "#555555",
    textGray: "#A2A3A6",
    primaryPurple: "#2a3390",
    primaryGreen: "#48BFC0",
    primaryBlue: "#1A99D6",
    generalLink: "#2E69C3",
    primaryPink: "#F81E46",
    secondary: "#E9EAF4",
    secondaryThree: "#F9F9FC",
    bgColor: "#FBFBFC",
    borderColor: "#E7E9EF",
    lightPurples: {
      default: "#f4f4f9",
      hover: "#e9eaf4",
    },
    white: "#FFFFFF",
    white200: "#F4F4F9",
    greys: {
      one: "#C7C7C7",
      two: "#DDDDDD",
      three: "#EDEDED",
      four: "#F8F8F8",
    },
    system: {
      red: "#D73E3E",
      green: "#63BE7B",
      yellow: "#FFBB3D",
    },
  },
  fontsizes: {
    h1: "4.8rem",
    h2: "2.6rem",
    h3: "1.9rem",
    h4: "2.1rem",
    h5: "1.7rem",
    h6: "1.6rem",
    body18: "1.8rem",
    body16: "1.6rem",
    body14: "1.4rem",
    body12: "1.2rem",
  },
  fontWeight: {
    font300: 300,
    font400: 400,
    font500: 500,
    font600: 600,
    font700: 700,
    font800: 800,
  },
};
