import { GET_COUPON_DATA , CLEAR_COUPON_DATA} from "../actions/discounts";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const couponReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_COUPON_DATA:
            return { ...state, ...payload };
       
        case CLEAR_COUPON_DATA: 
            return {}

        case HYDRATE:
            return {...state, ...payload.coupon };
        default:
            return state;
    }
};

export default couponReducer;
