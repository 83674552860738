import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

const Language = () => {
  const changeLangAr = useSelector(({ clinics, DoctorsListing }) => {
    return clinics.change_lang_ar || DoctorsListing.change_lang_ar;
  });
  const changeLangEn = useSelector(({ clinics, DoctorsListing }) => {
    return clinics.change_lang_en || DoctorsListing.change_lang_en;
  });

  const [changeLangRoute, setChangeLangRoute] = useState("");

  const router = useRouter();
  const { asPath, push, locale } = router;

  useEffect(() => {
    let route = "";
    if (changeLangEn || changeLangAr) {
      route = changeLangAr || changeLangEn;
    } else {
      if (locale == "ar") {
        route = asPath;
      } else {
        route = "/ar" + asPath;
      }
    }

    setChangeLangRoute(route);
  }, []);
  return (
    <div className="pull-right">
      <ul className="nav pull-left">
        <li className="nav-item">
          {locale === "ar" ? (
            <a
              href={changeLangRoute}
              target="_self"
              className="nav-link change-lang-circle change-lang-en"
              id="changeToEnglish"
            >
              En
            </a>
          ) : (
            <a
              href={changeLangRoute}
              target="_self"
              className="nav-link change-lang-circle change-lang-ar"
              id="changeToArabic"
            >
              ع
            </a>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Language;
