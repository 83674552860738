import axios from "axios";
import { t } from "../intl/translation";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

const getErrorMessage = (pathname) => {
  if (pathname.indexOf("/appointments") != -1) {
    return t("You have to sign in to be able to view your appointments.");
  }
  if (pathname.indexOf("/complete_profile") != -1) {
    return t("You have to sign in before you can complete your profile.");
  }
  if (pathname.indexOf("/profile") != -1) {
    return t("You have to sign in to be able to view your profile.");
  }
  return null;
};
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      let message = getErrorMessage(window.location.pathname);
      // auth error
      if (!window.location.pathname.split("/").includes("login")) {
        window.location.href = `/login/?next=${encodeURIComponent(window.location.pathname)}${
          message ? `&message=${message}` : ""
        }`;
      }
    }
    if (error.response && error.response.status === 500) {
      if (error?.config?.url?.indexOf("/schedule/") != -1 || error?.config?.url?.indexOf("api/create_booking/") != -1) {
        return;
      }
      // internal server error
      window.location.pathname = "/500";
    } else if (!error.response) {
      // Network Error.
      return Promise.reject({ message: "Network Error" });
    }
    return Promise.reject(error.response);
  }
);

const getRequest = (authenticate, url, params = {}) => {
  const encoded = encodeURI(url);
  let headers = { "x-requested-with": "XMLHttpRequest" };
  if (authenticate && window.localStorage.getItem("accessToken")) {
    const token = window.localStorage.getItem("accessToken");
    headers.authorization = `Bearer ${token}`;
  }

  return instance
    .get(`${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${encoded}`, { params, headers })
    .then(({ data }) => data)
    .catch((err) => {
      throw err;
    });
};

const postRequest = (authenticate, url, body) => {
  const encoded = encodeURI(url);
  // console.log(encoded, "initial");

  let headers = { "x-requested-with": "XMLHttpRequest" };
  if (authenticate && window.localStorage.getItem("accessToken")) {
    const token = window.localStorage.getItem("accessToken");
    headers.authorization = `Bearer ${token}`;
  }
  return instance
    .post(`${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${encoded}`, body, { headers })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const deleteRequest = (authenticate, url) => {
  let headers = { "x-requested-with": "XMLHttpRequest" };
  if (authenticate && window.localStorage.getItem("accessToken")) {
    const token = window.localStorage.getItem("accessToken");
    headers.authorization = `Bearer ${token}`;
  }
  return instance
    .delete(encodeURI(`${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${url}`), { headers })
    .then(({ data }) => data)
    .catch((err) => {
      throw err;
    });
};

const updateRequest = (authenticate, url, body) => {
  let headers = { "x-requested-with": "XMLHttpRequest" };
  if (authenticate && window.localStorage.getItem("accessToken")) {
    const token = window.localStorage.getItem("accessToken");
    headers.authorization = `Bearer ${token}`;
  }
  return instance
    .put(encodeURI(`${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${url}`), body, { headers })
    .then(({ data }) => data)
    .catch((err) => {
      throw err;
    });
};

export default { getRequest, postRequest, deleteRequest, updateRequest };
