/**
 * just for test
 */
const env = process.env.ENV || "development";
const configs = {
  development: {
    APP_ENV: env,
    NEXT_PUBLIC_BACKEND_BASE_URL: "https://bandar2.stagefelbayt.com/",
    NEXT_PUBLIC_BASE_URL: "https://www.stagefelbayt.com/",
    NEXT_PUBLIC_IMAGE_BASE: "https://bandar2.stagefelbayt.com/",
    NEXT_PUBLIC_DASHBOARD_BASE_URL: "https://bandar2.stagefelbayt.com/",
    NEXT_PUBLIC_MIXPANEL_SECRET_KEY: "2136e173f4f4804a82128fa36337e96b",
    NEXT_PUBLIC_SENTRY_DSN: "https://500bc8b7fd2e4b0a8214e65b60b92125@o88617.ingest.sentry.io/5698471",
    NEXT_PUBLIC_RECAPTCHA_KEY: "6LcGA4ccAAAAAIQ8CVE_1fmnGWpsDahhYKXyNV9r",
    NEXT_PUBLIC_TINGG_SERVICE_CODE: "HELDEV4895",
    NEXT_PUBLIC_ARS_BASE_URL: "https://ars-dev.onemedtest.com/",
  },
  staging: {
    APP_ENV: env,
    NEXT_PUBLIC_BACKEND_BASE_URL: "https://bandar.stagefelbayt.com/",
    NEXT_PUBLIC_BASE_URL: "https://www.stagefelbayt.com/",
    NEXT_PUBLIC_IMAGE_BASE: "https://bandar.stagefelbayt.com/",
    NEXT_PUBLIC_DASHBOARD_BASE_URL: "http://bandar.stagefelbayt.com/",
    NEXT_PUBLIC_MIXPANEL_SECRET_KEY: "2136e173f4f4804a82128fa36337e96b",
    NEXT_PUBLIC_SENTRY_DSN: "https://500bc8b7fd2e4b0a8214e65b60b92125@o88617.ingest.sentry.io/5698471",
    NEXT_PUBLIC_RECAPTCHA_KEY: "6LcGA4ccAAAAAIQ8CVE_1fmnGWpsDahhYKXyNV9r",
    NEXT_PUBLIC_TINGG_SERVICE_CODE: "HELDEV4895",
    NEXT_PUBLIC_ARS_BASE_URL: "https://ars-dev.onemedtest.com/",
  },
  staging2: {
    APP_ENV: env,
    NEXT_PUBLIC_BACKEND_BASE_URL: "https://bandar2.stagefelbayt.com/",
    NEXT_PUBLIC_BASE_URL: "https://stage2.stagefelbayt.com/",
    NEXT_PUBLIC_IMAGE_BASE: "https://bandar2.stagefelbayt.com/",
    NEXT_PUBLIC_DASHBOARD_BASE_URL: "http://bandar2.stagefelbayt.com/",
    NEXT_PUBLIC_MIXPANEL_SECRET_KEY: "2136e173f4f4804a82128fa36337e96b",
    NEXT_PUBLIC_SENTRY_DSN: "https://500bc8b7fd2e4b0a8214e65b60b92125@o88617.ingest.sentry.io/5698471",
    NEXT_PUBLIC_RECAPTCHA_KEY: "6LcGA4ccAAAAAIQ8CVE_1fmnGWpsDahhYKXyNV9r",
    NEXT_PUBLIC_TINGG_SERVICE_CODE: "HELDEV4895",
    NEXT_PUBLIC_ARS_BASE_URL: "https://ars-dev.onemedtest.com/",
  },
  production: {
    APP_ENV: env,
    NEXT_PUBLIC_BACKEND_BASE_URL: "https://cloud.heliumdoc.com/",
    NEXT_PUBLIC_BASE_URL: "https://www.heliumdoc.com/",
    NEXT_PUBLIC_IMAGE_BASE: "https://cloud.heliumdoc.com/",
    NEXT_PUBLIC_DASHBOARD_BASE_URL: "https://dashboards.heliumdoc.com/",
    NEXT_PUBLIC_MIXPANEL_SECRET_KEY: "db63fba4f6906ae61b442f87320df56c",
    NEXT_PUBLIC_SENTRY_DSN: "https://500bc8b7fd2e4b0a8214e65b60b92125@o88617.ingest.sentry.io/5698471",
    NEXT_PUBLIC_RECAPTCHA_KEY: "6LckDoccAAAAADkzdAQVi1eJ0Yy8gday0ZdsSrny",
    NEXT_PUBLIC_TINGG_SERVICE_CODE: "HELDEV4895",
    NEXT_PUBLIC_ARS_BASE_URL: "https://ars.heliummum.com/",
    NEXT_PUBLIC_ARS_PUBLIC_KEY:
      "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuRlGsu5kbgyTutL1WRUf5CximlQDDNHGxmDcQ3wYjMBhbipAE52HVRnYEcLXwLI52M5XBLAOHBhWO8W3HcEZ6xPSLinXzDgQ4Jawh7fD61mSktsk1l69nWMUTkoVotVJSLvvj9W79rT6upof4DRR40Yl43fDgGrI",
  },
}[env];

module.exports = { configs };
