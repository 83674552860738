import { API, localize } from "../../utils";

export const GET_PAYMENTS_DATA = "GET_PAYMENTS_DATA";

export const getPaymentsData =
  ({ uuid, client_side_country, locale }) =>
  async (dispatch) => {
    const data = await API.getRequest(true, localize(`/payments/${uuid}/`, null, locale));

    dispatch({
      type: GET_PAYMENTS_DATA,
      payload: data,
    });
};

export const setPaymentUnknownStatus = async ({uuid,client_side_country,language_code}) => {
  return await API.getRequest(true, localize(`/payments/unknown/${uuid}/`, null, null));
}

export const makePayment = async ({uuid,client_side_country,language_code,body}) => {
  return await API.postRequest(true, localize(`/payments/${uuid}/`, null,null),body)
}

export const getPaymentStatus = async ({uuid, client_side_country,language_code}) => {
  return await API.getRequest(true, localize(`/payments/status/${uuid}/`, null, null));
}

export const applyPromoCode = async ({uuid,promoCode,client_side_country,language_code}) => {
  return await API.getRequest(true, localize(`/payments/${uuid}/code/${promoCode}/`, null,null),{});
}

export const removePaymentPromo = async ({uuid,client_side_country,language_code}) => {
  return await API.getRequest(true, localize(`/payments/${uuid}/remove-code/`, null,null),{});
}