import {
    FETCH_USER_BOOKING_CANCELLED,
    FETCH_USER_BOOKING_VISITED,
    FETCH_USER_BOOKING_UPCOMING,
    GET_BOOKING_CANCELLED,
    CANCEL_APPOINTMENT,
    GET_CANCEL_BOOKING_DATA,
    GET_AVAILABLE_TIMINGS,
} from "../actions/userAppointments";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    upcoming: null,
    cancelled: null,
    visited: null,
    bookingCancelled: null,
    cancelBookingData: null,
    availableTimings: null,
};

const userAppointments = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_USER_BOOKING_UPCOMING:
            return { ...state, upcoming: { ...payload } };
        case FETCH_USER_BOOKING_CANCELLED:
            return { ...state, cancelled: { ...payload } };
        case FETCH_USER_BOOKING_VISITED:
            return { ...state, visited: { ...payload } };
        case GET_BOOKING_CANCELLED:
            return { ...state, bookingCancelled: { ...payload } };
        case GET_CANCEL_BOOKING_DATA:
            return { ...state, cancelBookingData: { ...payload } };
        case CANCEL_APPOINTMENT:
            const cancelledAPT = state?.upcoming?.user_bookings.find(({ id }) => id === payload);
            return {
                ...state,
                cancelled: { ...state.cancelled, user_bookings: [...state.cancelled.user_bookings, cancelledAPT] },
                upcoming: {
                    ...state.upcoming,
                    user_bookings: state.upcoming.user_bookings.filter(({ id }) => id !== payload),
                },
            };
        case GET_AVAILABLE_TIMINGS:
            return { ...state, availableTimings: payload };
        case HYDRATE:
            return { ...payload.userAppointments };
        default:
            return state;
    }
};

export default userAppointments;
