import { API } from "../../utils";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_PENDING = "FETCH_USER_PROFILE_PENDING";
export const FETCH_USER_PROFILE_FULFILLED = "FETCH_USER_PROFILE_FULFILLED";
export const FETCH_USER_PROFILE_REJECTED = "FETCH_USER_PROFILE_REJECTED";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_PENDING = "UPDATE_USER_PROFILE_PENDING";
export const UPDATE_USER_PROFILE_FULFILLED = "UPDATE_USER_PROFILE_FULFILLED";
export const UPDATE_USER_PROFILE_REJECTED = "UPDATE_USER_PROFILE_REJECTED";


export const updateUserProfileData =  (data) => async (dispatch) => {
    try {
        let res = await API.postRequest(true, `profile/`, data).then((data) => {
            return {
                ...data,
            };
        })
    
        dispatch({
            type: UPDATE_USER_PROFILE,
            payload: res
        });
    } catch (error) {
        throw error
    }
    
};

export const fetchUserData = () => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADING", payload: true });
        const data = await API.getRequest(true, `profile/`);
        dispatch({ type: FETCH_USER_PROFILE_FULFILLED, payload: data });
        dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
        console.log(err)
    }
};

export const changePassword = async (body) => {
    try {
        const data = await API.postRequest(true, `profile/change-password/`, body);
        return data;
    } catch (err) {
        throw err;
    }
};

export const setEmail = async (body) => {
    try {
        const data = await API.postRequest(true, `profile/set-email/`, body);
        return data;
    } catch (err) {
        throw err;
    }
};
