import { FETCH_DOCTORS_LISTING } from "../actions/doctorsListing";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const doctorsData = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DOCTORS_LISTING:
      return { ...state, ...payload };
    case HYDRATE:
      return { ...state, ...payload.DoctorsListing };
    default:
      return state;
  }
};

export default doctorsData;
