import { API, localize } from "../../utils";

export const GET_COMFIRMATION_DATA = "GET_COMFIRMATION_DATA";

export const getConfirmationData =
  ({ id, client_side_country, locale }) =>
  async (dispatch) => {
    const data = await API.getRequest(
      false,
      localize(`/bookings/booking-confirmation/doctor/${id}`, client_side_country, locale)
    );

    dispatch({
      type: GET_COMFIRMATION_DATA,
      payload: data,
    });
  };
