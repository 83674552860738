import { API, localize } from "../../utils";
import { uploadDocument, deleteDocument } from "../../services/completeProfile";
export const FETCH_COMPLETE_PROFILE_DATA = "FETCH_COMPLETE_PROFILE_DATA";
export const UPDATE_ID_FRONT = "UPDATE_ID_FRONT";
export const UPDATE_ID_BACK = "UPDATE_ID_BACK";
export const UPDATE_PASSPORT = "UPDATE_PASSPORT";
export const UPDATE_HAS_VERIFICATION = "UPDATE_HAS_VERIFICATION";
export const UPDATE_INSURANCE = "UPDATE_INSURANCE";
export const SET_ERR = "SET_ERR";
export const UPLOAD_RECORD = "UPLOAD_RECORD";
export const REMOVE_RECORD = "REMOVE_RECORD";

export const fetchCompleteProfileData = (locale) => async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    const data = await API.getRequest(true, localize(`get-user-profile/`, undefined, locale));
    dispatch({ type: "SET_LOADING", payload: false });

    let hasVerification;
    if (data.id_front && data.id_back) {
        hasVerification = 1; // 1 IS FOR VERIFICATION VIA ID
    } else if (data.passport) {
        hasVerification = 2; // 2 IS FOR VERIFICATION VIA PASSPORT
    } else if (data.id_front || data.id_back) {
        hasVerification = 1; // IF EITHER OF THESE IS ALREADY UPLOADED SHOW VERIFICATION VIA ID
    }

    dispatch({ type: FETCH_COMPLETE_PROFILE_DATA, payload: { ...data, hasVerification } });
};

export const uploadDoc = (event, docType, appointmentId) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADING", payload: true });

        // PASS IN BOOKING ID INCASE OF TELEHEALTH REDIRECTION
        if (event.target.files && event.target.files[0]) {
            // TO UPLOAD DOCUMENT TO SPECIFIC USER
            const response = await uploadDocument({
                document: event.target.files[0],
                document_type: docType,
                hash: appointmentId ? appointmentId : undefined,
            });

            if (response) {
                dispatch({ type: "SET_LOADING", payload: false });

                if (docType === "id_front") {
                    dispatch({ type: UPDATE_ID_FRONT, payload: response });
                    dispatch({ type: UPDATE_HAS_VERIFICATION, payload: 1 });
                } else if (docType === "id_back") {
                    dispatch({ type: UPDATE_ID_BACK, payload: response });
                    dispatch({ type: UPDATE_HAS_VERIFICATION, payload: 1 });
                } else if (docType === "passport") {
                    dispatch({ type: UPDATE_PASSPORT, payload: response });
                    dispatch({ type: UPDATE_HAS_VERIFICATION, payload: 2 });
                } else if (docType === "insurance") {
                    dispatch({ type: UPDATE_INSURANCE, payload: response });
                }
                else if (docType === "medical_record") {
                    dispatch({ type: UPLOAD_RECORD, payload: response });
                }
            }
        }
    } catch (err) {
        dispatch({
            type: SET_ERR,
            payload: {
                title: "Error",
                message: "Sorry, something went wrong. Please contact our support team for further help!",
                type: "danger",
            },
        });
    }
};

export const clearError = () => (dispatch) => dispatch({ type: SET_ERR, payload: null });

export const removeImage = (image, docType, appointmentId, idBack, idFront) => async (dispatch) => {
    // PASS IN BOOKING ID IN CASE OF TELEHEALTH REDIRECTION TO DELETE

    // SPECIFIC USER'S DOCUMENT
    try {
        dispatch({ type: "SET_LOADING", payload: true });

        const response = await deleteDocument(image["id"], appointmentId);
        if (docType === "idFront") {
            dispatch({ type: UPDATE_ID_FRONT, payload: undefined });
            if (!idBack) {
                dispatch({ type: UPDATE_HAS_VERIFICATION, payload: 0 });
            }
        } else if (docType === "idBack") {
            dispatch({ type: UPDATE_ID_BACK, payload: undefined });
            if (!idFront) {
                dispatch({ type: UPDATE_HAS_VERIFICATION, payload: 0 });
            }
        } else if (docType === "passport") {
            dispatch({ type: UPDATE_PASSPORT, payload: undefined });
            dispatch({ type: UPDATE_HAS_VERIFICATION, payload: 0 });
        } else if (docType === "insurance") {
            dispatch({ type: UPDATE_INSURANCE, payload: undefined });
            // scope.idBack = response.data;
        } else if(docType === "record"){
            dispatch({type:REMOVE_RECORD, payload: image})
        }
        dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
        dispatch({
            type: SET_ERR,
            payload: {
                title: "Error",
                message: "Sorry, something went wrong. Please contact our support team for further help!",
                type: "danger",
            },
        });
    }
};

export const switchToPassport = (idFront, idBack) => async (dispatch) => {
    if (idFront) {
        await dispatch(removeImage(idFront, "idFront", null, idBack, idFront));
    }
    if (idBack) {
        await dispatch(removeImage(idBack, "idBack", null, idBack, idFront));
    }
    dispatch({ type: UPDATE_HAS_VERIFICATION, payload: 0 });
};

export const switchToID = (passport) => async (dispatch) => {
    if (passport) {
        await dispatch(removeImage(passport, "passport"));
    }
};

export const uploadInsuranceApi = (event, appointmentId) => async (dispatch) => {
    try {
        if (event.target.files && event.target.files[0]) {
            // PASS IN BOOKING ID INCASE OF TELEHEALTH REDIRECTION
            // TO UPLOAD DOCUMENT TO SPECIFIC USER
            const response = await uploadDocument({
                document: event.target.files[0],
                document_type: "insurance",
                hash: appointmentId ? appointmentId : undefined,
            });

            if (response) {
                dispatch({ type: UPDATE_INSURANCE, payload: response });
            }
        }
    } catch (err) {
        dispatch({
            type: SET_ERR,
            payload: {
                title: "Error",
                message: "Sorry, something went wrong. Please contact our support team for further help!",
                type: "danger",
            },
        });
    }
};

export const uploadRecord = (event) => async (dispatch) => {
    try {
        if (event.target.files && event.target.files[0]) {
            // PASS IN BOOKING ID INCASE OF TELEHEALTH REDIRECTION
            // TO UPLOAD DOCUMENT TO SPECIFIC USER
            const response = await uploadDocument({
                document: event.target.files[0],
                document_type: "medical_record",
            });

            if (response) {
                dispatch({ type: UPLOAD_RECORD, payload: response });
            }
        }
    } catch (err) {
        dispatch({
            type: SET_ERR,
            payload: {
                title: "Error",
                message: "Sorry, something went wrong. Please contact our support team for further help!",
                type: "danger",
            },
        });
    }
};

