import { SET_INIT_DATA, SET_INT_ERR } from "../types/init";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  initData: null,
  error: null,
};

const InitialData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT_DATA:
      return { ...state, error: null, initData: payload };
    case SET_INT_ERR:
      return { ...state, error: payload, initData: null };
    case HYDRATE:
      return { ...payload.InitialData, ...state };
    default:
      return state;
  }
};

export default InitialData;
