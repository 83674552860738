import dayjs from "dayjs";
import { Day, Hour } from "./models";

// Get four or five upcoming days
export const getDays = (start = dayjs()) => {
  const offsetLimit = window.innerWidth <= 728 ? 4 : 5;
  let rangeArray = [];
  let tempDate = start.clone();
  for (let index = 0; index < offsetLimit; index++) {
    rangeArray.push(tempDate);
    tempDate = tempDate.clone().add(1, "days");
  }
  return rangeArray;
};

// format days based on Day model class
export const formatedDays = (timings, start) => {
  let data = getDays(start);
  const dates = Object.keys(timings);
  return data.map((item) => {
    const raw = item.format("YYYY-MM-DD");
    const date = new Day(raw, dates.includes(raw));
    if (date.available && timings[raw].length) {
      date.setHours(timings[raw]);
    } else {
      date.available = false;
    }

    return date;
  });
};

// format hours based on Hour model class
export const parseDateTimes = (day) => {
  let array = day.hours.slice();
  const halfway = Math.ceil(array.length / 2);
  let firstHalf = array.slice(0, halfway);
  let secondHalf = array.slice(halfway, array.length);

  const defaultHours1Arr = firstHalf.map((item) => {
    return new Hour(`${item.slice(0, 5)} ${item.slice(5)}`, true);
  });
  const defaultHours2Arr = secondHalf.map((item) => {
    return new Hour(`${item.slice(0, 5)} ${item.slice(5)}`, true);
  });
  return {
    defaultHours1Arr,
    defaultHours2Arr,
  };
};
