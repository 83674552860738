import { GET_REVIEW_DATA } from "../actions/submitReviewData";
import { HYDRATE } from "next-redux-wrapper";

const initialState = null;

const reviewSubmit = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REVIEW_DATA:
      return { ...payload };
    case HYDRATE:
      return { ...payload.reviewSubmit };
    default:
      return state;
  }
};

export default reviewSubmit;
