import { isAfricanCounty } from "@/utils/router";
import dayjs from "dayjs";
import getConfig from "next/config";
import { useEffect, useState } from "react";
import { t } from "../intl/translation";
// TODO: this is a redirect implemented with the CSR in mind, and does not function even in that scenario.
// Should be removed from home page as alternative for SSR was implemented in doctors profile (where redirects are handled).
// redirects cannot be handled in the homepage due to the way nextjs handles routes.
export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);

  console.log("url, name, regex, results", url, name, regex, results);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const replaceAll = (str, find, replace) => {
  if (!str) {
    return "";
  }
  return str.replace(new RegExp(find, "g"), replace);
};

export const isMobile = () => {
  if (typeof window != "undefined") {
    return window?.outerWidth <= 600 || false;
  }

  return false;
};

// Hook to determine if we're on mobile or desktop also to handle rezising of window on desktop
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Set window width/height to state
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const isImage = (file) => {
  if (file.extension === ".jpg" || file.extension === ".jpeg" || file.extension === ".png") {
    return true;
  }
  return false;
};
export const handleExpiredOn = (expiry_date, t) => {
  const remainingDays = Math.floor((new Date(expiry_date) - new Date()) / (1000 * 3600 * 24));
  if (remainingDays > 3) {
    let expiry = expiry_date.split(" ");
    return `${t("Expires ")} ${expiry[1]} ${expiry[0]}, ${expiry[2] - 2000}`;
  } else if (remainingDays <= 3 && remainingDays > 1) {
    return t(`Expires in ${remainingDays} days`);
  } else if (remainingDays == 1) {
    return t("Expires in 24 hours");
  } else if (remainingDays == 0) {
    return t("Last Day");
  } else if (remainingDays < 0) {
    return t("Expired");
  }
};

export const handelPrice = (price) => {
  if (!isNaN(price)) {
    return Math.trunc(price);
  } else {
    return price.split(".")[0];
  }
};

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const copyToClipBoard = (link, document) => {
  const el = document.createElement("textarea");
  el.value = link;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  // let textarea;
  // let result;

  // try {
  //   textarea = document.createElement('textarea');
  //   textarea.setAttribute('readonly', true);
  //   textarea.setAttribute('contenteditable', true);
  //   textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
  //   textarea.value = link;

  //   document.body.appendChild(textarea);

  //   textarea.focus();
  //   textarea.select();

  //   const range = document.createRange();
  //   range.selectNodeContents(textarea);

  //   const sel = window.getSelection();
  //   sel.removeAllRanges();
  //   sel.addRange(range);

  //   textarea.setSelectionRange(0, textarea.value.length);
  //   result = document.execCommand('copy');
  // } catch (err) {
  //   console.error(err);
  //   result = null;
  // } finally {
  //   document.body.removeChild(textarea);
  // }

  // // manual copy fallback using prompt
  // if (!result) {
  //   const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  //   const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
  //   result = prompt(`Press ${copyHotkey}`, link); // eslint-disable-line no-alert
  //   if (!result) {
  //     return false;
  //   }
  // }
};

export const getFriendlyDate = (dateString) => {
  return dayjs(dateString).format("dddd, MMM DD");
};

export const getTestNumbers = () => {
  return ["+91912345678011", "+91912345678012", "+91912345678013", "+91912345678014", "+91912345678015"];
};

const paymentErrorMessagesList = {
  balance_insufficient: t(
    "Your card does not have sufficient balance. Please use a card with an adequate fund to complete the transaction."
  ),
  bank_account_declined: t(
    "This bank account cannot be used. This error occurs if the account is not supported or is not yet verified. Please try with a different bank account."
  ),
  card_decline_rate_limit_exceeded: t(
    "Looks like this card has been declined too many times. Please try again in 24 hours. If the issue does not resolve, make sure that your card is supported and the information entered is correct."
  ),
  card_declined: t("Your card has been declined."),
  country_unsupported: t("Our services are not available in the country this card was issued."),
  expired_card: t("This card has expired."),
  incorrect_cvc: t("Please check the card's security code."),
  incorrect_number: t("The card number is invalid. Please re-enter the card."),
  postal_code_invalid: t("Incorrect postal code."),
  payment_intent_authentication_failure: t("We are unable to authenticate your payment method."),
  setup_intent_authentication_failure: t("We are unable to authenticate your payment method."),
  incomplete_zip: t("Your ZIP code is incomplete."),
};
export const getPaymentErrorFromMessage = (code, defaultMessage) => {
  const errorMessage = paymentErrorMessagesList[code];
  return errorMessage ? errorMessage : defaultMessage;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length;
};

export const getCountryFromRequestUrl = (reqURl) => {
  let tempCountry = null;
  let countries = getConfig().publicRuntimeConfig.countries;

  if (reqURl) {
    countries.forEach((country) => {
      if (reqURl.includes(`/${country}/`) || reqURl === `/${country}`) {
        tempCountry = country;
      }
    });
  }

  return tempCountry;
};

export const arabicToEnglish = (string) => {
  const arabicNumerals = "٠١٢٣٤٥٦٧٨٩";
  return string.replace(/[٠-٩]/g, (d) => arabicNumerals.indexOf(d));
};
export const customizePaymentsMessageForAfrica = (country_code) => {
  return isAfricanCounty(country_code);
};

export const getPageTypeFromPath = (pathname) => {
  let result;

  switch (pathname) {
    case "/":
      result = "Home Page";
      break;
    case "/doctors/[...slug]":
    case "/doctors":
      result = "Doctor Listing";
      break;
    case "/[doctor_name]":
      result = "Doctor Profile";
      break;
    case "/clinics/[...slug]":
    case "/clinics":
      result = "Clinic Listing";
      break;
    case "/clinic/[slug]":
      result = "Clinic Profile";
      break;
    case "/insurance/[slug]":
      result = "Insurance Profile";
      break;
    case "/login":
      result = "Login";
      break;
    case "/signup":
      result = "Sign up";
      break;
    case "/otp":
      result = "OTP Page";
      break;
    case "/bookings/submit_booking/doctor/[slug]":
      result = "Booking Form";
      break;
    case "/bookings/booking-confirmation/doctor/[slug]":
      result = "Confirmation Page";
      break;
    case "/me/profile/edit":
      result = "Edit Profile Page";
      break;
    case "/me/profile/uploaded_documents":
      result = "Complete Profile Page";
      break;
    case "/me/appointments":
      result = "My Appointments Page";
      break;
    case "/discounts":
      result = "Discounts Home";
      break;
    case "/discount/[discount_slug]":
      result = "Discount Profile";
      break;
    case "/discounts/listings":
      result = "Discount Category Page";
      break;
    case "/submit_review/[slug]":
      result = "Submit Doctor Review";
      break;
    case "/payments/[slug]":
      result = "Submit Doctor Review";
      break;
    default:
      result = pathname;
      break;
  }

  return result;
};
