import { useSelector } from "react-redux";

function SiteDisclaimers({ disclaimersData, noBtnBorder }) {
    const serverData = useSelector(({ InitialDataS }) => InitialDataS);
    const disclaimers = disclaimersData ? disclaimersData : serverData?.site_disclaimers;

    return disclaimers.length
        ? disclaimers.map((disclaimer, index) => (
              <div
                  className={`notice-banner ${!noBtnBorder && 'notice-banner-bottom-border'}`}
                  key={index}
                  style={{
                      backgroundColor: disclaimer.color,
                      borderBottom: disclaimer.borderBottom,
                  }}
              >
                  <h5
                      className="notice-banner-header"
                      style={{ color: disclaimer.font }}
                      dangerouslySetInnerHTML={{ __html: disclaimer.displayed }}
                  />
                  {disclaimer.free && <div className="free-disclaimer">Free</div>}
              </div>
          ))
        : null;
}

export default SiteDisclaimers;
