import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GridTheme } from "Stylesheets/Grid";
import Theme from "Stylesheets/Theme";
import Axios from "lib/Axios";
import ReactQueryConfig from "lib/ReactQueryConfig";
import MyApp from "next/app";
import getConfig from "next/config";
import Router, { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { ToasterContainer } from "../TS_components/HomeHero/CommonToast/CommonToast.styled";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { GetInitialData, GetInitialDataServer } from "../data/actions/init";
import { SetSeoData } from "../data/actions/seo";
import { wrapper } from "../data/store";
import useTranslation from "../intl/useTranslation";
import RTLCSS_PLUGIN from "../lib/RTLCSS";
import "../styles/globals.scss";
import { InitGtm, SEO, initMixpanel } from "../utils";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// Configure sentry only for staging and production
// const stripePromise = loadStripe("pk_test_PMmJFsSqcAhqRo4mlxRqRRuW");

// const Layout = dynamic(() => import("../components/Layout"), { loading: () => <Loader /> });

const App = function ({ Component, pageProps }) {
  const [queryClient] = useState(() => new QueryClient(ReactQueryConfig));
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);
  const { initData } = useSelector(({ InitialData }) => InitialData);
  const seoData = useSelector(({ SeoData }) => SeoData);
  const router = useRouter();
  const { asPath } = useRouter();
  const { locale } = router;
  const [nextUrl, setNextUrl] = useState(asPath);
  Axios.defaults.headers.common["Accept-Language"] = locale;
  // INITIALIZE MIXPANEL TRACKING
  initMixpanel({ client_side_country, locale });
  // INITIALIZE GTM
  InitGtm(client_side_country);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetInitialData({ locale, client_side_country }));
    // ADD LISTENER FOR ROUTE CHANGE TO UPDATE SEO BY DEFAULT
    const updateSEO = (url) => {
      // UPDATE SEO ONCE PAGE'S LOADED
      // BETTER WAY WOULD BE TO AVOID TIMEOUT
      setTimeout(
        function () {
          SEO.updateOnRouteChange({ client_side_country, locale }, seoData);
        }.bind(this),
        500
      );
    };
    // BY DEFAULT CALL FIRST TIME SEO TOO
    updateSEO(asPath);
    // SET LOADING TRUE WHEN ROUTE CHANGE
    Router.events.on("routeChangeStart", (theNextUrl) => {
      setNextUrl(theNextUrl);
      setLoading(true);
    });
    Router.events.on("routeChangeComplete", (url) => {
      setLoading(false);
      updateSEO(url);
    }); // add listener
    Router.events.on("routeChangeError", () => setLoading(false));

    return () => {
      Router.events.off("routeChangeStart", () => setLoading(false));
      Router.events.off("routeChangeComplete", (url) => {
        updateSEO(url);
        setLoading(false);
      }); // remove listener
      Router.events.off("routeChangeError", () => setLoading(false));
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("currentPath")) {
      // update previous path
      sessionStorage.setItem("prevPath", sessionStorage.getItem("currentPath"));
    }
    sessionStorage.setItem("currentPath", router.asPath);
  }, [router.asPath]);

  // this a fix to resolve issue with initialization
  // until the rerenders and initialization issue is identified and resolved.
  useEffect(() => {
    // this is to prevent a loader flicker when the first initData value is null and a rerender with loader occurs
    if (initData) {
      setLoading(false);
    }
  }, [router.asPath, initData]);

  const [initReq, setInitReq] = useState(true);

  useEffect(() => {
    setInitReq(false);
  }, []);

  const isDesktop = useMediaQuery({
    minWidth: 768,
  });

  return (
    <StyleSheetManager stylisPlugins={locale === "ar" ? [RTLCSS_PLUGIN] : []}>
      <ThemeProvider theme={Theme}>
        <GridThemeProvider gridTheme={GridTheme}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <>
                {loading && initReq && (
                  <Loader
                    {...(nextUrl && nextUrl?.includes("/doctors/")
                      ? {
                          loadingText: t("Loading Doctors"),
                          id: "doctors-loader1",
                        }
                      : {})}
                  />
                )}
                {!initReq && loading ? (
                  <Loader
                    {...(nextUrl && nextUrl?.includes("/doctors/")
                      ? {
                          loadingText: t("Loading Doctors"),
                          id: "doctors-loader2",
                        }
                      : {})}
                  />
                ) : (
                  <Layout key={asPath}>
                    <ToasterContainer>
                      <Toaster
                        gutter={5}
                        toastOptions={{
                          duration: 3000,
                          position: isDesktop ? "bottom-center" : "top-center",
                        }}
                      />
                    </ToasterContainer>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </>
            </Hydrate>
          </QueryClientProvider>
        </GridThemeProvider>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

App.getInitialProps = async (appContext) => {
  let tempCountry = null;
  let countries = getConfig().publicRuntimeConfig.countries;
  const { locale } = appContext.router;

  const reqURl = appContext?.ctx?.req?.url || appContext?.ctx?.req?.headers.referer;
  if (reqURl) {
    countries.forEach((country) => {
      if (reqURl.includes(`/${country}/`) || reqURl === `/${country}`) {
        tempCountry = country;
      }
    });

    let seoUrl = appContext.ctx.req.url;

    if (locale == "ar") {
      seoUrl = "/ar" + seoUrl;
    }

    const UrlComponent = require("url");
    // GET SEO DATA ON SSR AND SAVE IN STORE
    let seoData = await SEO.getAndUpdateSEOObject(UrlComponent.parse(seoUrl).pathname, {
      client_side_country: tempCountry,
      locale,
    });
    await appContext.ctx.store.dispatch(
      SetSeoData({
        ...seoData,
        canonical: seoData.canonical || UrlComponent.parse(seoUrl).pathname,
      })
    );
  }
  if (appContext.router?.isSsr === false) {
    const appProps = await MyApp.getInitialProps(appContext);
    return { ...appProps };
  } else {
    await appContext.ctx.store.dispatch(GetInitialDataServer({ locale, client_side_country: tempCountry }));
    const appProps = await MyApp.getInitialProps(appContext);
    return { ...appProps };
  }
};

export default wrapper.withRedux(App);
