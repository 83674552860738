const GoogleAds = () => {
  return (
    <ins
      className="adsbygoogle"
      data-ad-client="ca-pub-7589165321326769"
      data-ad-slot="2837445977"
      data-ad-format="rectangle, horizontal"
      data-full-width-responsive="true"
      data-ad-region={"page-" + Math.random()}
    ></ins>
  );
};

export default GoogleAds;
