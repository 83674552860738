import axios from "axios";
import { getRoute } from "../utils";

export const searchData = async (client_side_country, body) => {
    let route = getRoute(client_side_country, "/search/");
    if (route.startsWith("/")) {
        route = route.substring(1);
    }
    const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${route}`,
        body
    );
    return data;
};

export const resultHeaders = {
    specializations: "Specialities",
    treatments: "Treatments",
    clinics: "Clinics",
    doctors: "Doctors",
};

export const resultKeys = ["specializations", "treatments", "clinics", "doctors"];

export const handleData = (obj, key, locale) => {
    const getHeaderBrackets = (obj, local) => {
        return obj[`friendly_name_${local}`] ? "(" + obj[`friendly_name_${local}`] + ")" : null;
    };
    const getResultSubHeader = (obj, key, local) => {
        if (key === "clinics") {
            const areaName = obj.area[`name_${local}`];
            const city = obj.area.city;

            if (city) return areaName + ", " + city[`name_${local}`];

            return areaName;
        } else if (key === "doctors") {
            const subspec = obj.sub_specialization[`name_${local}`];
            const spec = obj.specialization[`name_${local}`];

            if (subspec) {
                return spec + " | " + subspec;
            } else {
                return spec;
            }
        }
    };
    const imageURL = (slug) => `${process.env.NEXT_PUBLIC_IMAGE_BASE}profilepic/${slug}/50/50/95/`;
    const replaceAll = (str, find, replace) => {
        if (!str) {
            return "";
        }
        return str.replace(new RegExp(find, "g"), replace);
    };
    const go = () => {
        const slug = obj[`slug_${locale}`];
        if (key === "doctors") {
            return "/" + obj.slug;
        } else if (key === "clinics") {
            return `/clinic/${obj[`slug_en`]}/`;
        } else if (key === "treatments" || "specializations" || "subspecializations") {
            const newSlug = replaceAll(slug, "-", "+");
            return `/doctors/${newSlug}/`;
        }
    };

    return {
        mainHeader: resultHeaders[key],
        header: obj[`name_${locale}`],
        betweenBrackets: getHeaderBrackets(obj, locale),
        subHeader: getResultSubHeader(obj, key, locale),
        imageUrl: imageURL(obj.slug),
        go: go(),
    };
};
