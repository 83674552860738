import { GET_DOCTOR_REVIEW } from "../actions/review";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const doctorReviews = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_DOCTOR_REVIEW:
            return { ...state,  [payload.doctorName]: payload};
        case HYDRATE:
            return { ...payload.doctorReviews };
        default:
            return state;
    }
};

export default doctorReviews;
