import dayjs from "dayjs";
import { API, localize } from "../../utils";
export const GET_USER_PRESCRIPTION = "GET_USER_PRESCRIPTION";
export const GET_BOOKING_PRESCRIPTION = "GET_BOOKING_PRESCRIPTION";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export const getUserPrescriptions = (locale) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING", payload: true });
    const data = await API.getRequest(true, localize(`profile/user-prescriptions/`, undefined, locale));
    if (data) {
      dispatch({ type: "SET_LOADING", payload: false });

      const result = data.reduce((r, obj) => {
        const FORMAT = "MMMM DD YYYY, ddd hh:mmA";
        let dateObj = dayjs(obj.date, FORMAT);
        let monthyear = `${dateObj.format("MMM")}  ${dateObj.format("YYYY")}`;
        obj.date = dateObj.format("DD MMM, hh:mmA");
        if (!r[monthyear]) r[monthyear] = { monthyear, dateObj, prescriptions: [obj] };
        else r[monthyear].prescriptions.push(obj);
        return r;
      }, {});

      const monthsData = Object.keys(result)
        .map((key) => {
          return result[key];
        })
        .sort((a, b) => b.dateObj - a.dateObj);

      dispatch({ type: GET_USER_PRESCRIPTION, payload: monthsData });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getBookingPrescription = (bookingId, locale) => async (dispatch) => {
  try {
    dispatch({ type: "SET_LOADING", payload: true });
    const data = await API.getRequest(true, localize(`booking_prescriptions/${bookingId}/`, undefined, locale));
    if (data) {
      dispatch({ type: "SET_LOADING", payload: false });

      dispatch({ type: GET_BOOKING_PRESCRIPTION, payload: data });
    }
  } catch (err) {
    console.log(err);
  }
};
