import React from 'react';

function Loader() {
    return (
        <div className="loader-background">
            <div className="loader-spinner"/>
        </div>
    )
}
export default Loader;
