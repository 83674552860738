import { GET_PAYMENTS_DATA } from "../actions/payments";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const payments = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PAYMENTS_DATA:
      return { ...state, ...payload };
    case HYDRATE:
      return { ...payload.payments, ...state };

    default:
      return state;
  }
};

export default payments;
