export const ar = {
  "Concerned about Coronavirus (COVID-19)? Get your symptoms checked here!":
    "قلق بشأن الإصابة بفيروس كورونا (كوفيد-١٩)؟ افحص أعراضك هنا!",
  "Get your symptoms checked for COVID-19 and request a free phone consultation!":
    "افحص أعراضك لكوفيد-١٩ واطلب تلقي استشارة هاتفية مجانية!",
  "Thank you for using HeliumDoc. Please expect a call back from one of our doctors.":
    "شكرا لاستخدامك هيليوم دوك. يرجى توقع مكالمة هاتفية من أحدى أطبائنا.",
  "*This service is available daily only from 10 AM - 1 PM except Fridays.":
    "*هذه الخدمة متاحة يومياً من الساعة ١٠ صباحاً حتى ١ بعد الظهر باستثناء يوم الجمعة.",
  "Calling the COVID-19 Hotline...": "جاري الاتصال بالخط الساخن لكوفيد-١٩…",
  "Request Sent!": "تم ارسال الطلب!",
  "Featured Doctors": "أطباء مميزون",
  "Popular Clinics": "أبرز العيادات",
  Featured: "مميز",
  "If you didn't get a pop up asking you to call": "إذا لم يظهر لك إشعار تلقائياً للإتصال بالرقم",
  "click here to manually call": "اضغظ هنا للإتصال على الرقم",
  "A user with that username already exists.": "هناك حساب آخر مسجل بنفس البريد الالكتروني",
  "The two password fields didn't match.": "كلمات المرور غير متطابقة",
  "Password confirmation": "تأكيد كلمة المرور",
  "New password confirmation": "تأكيد كلمة المرور الجديدة",
  "<strong>Success!</strong> Thank you for contacting us. We will be in touch shortly":
    "<strong>تم تقديم الطلب بنجاح!</strong> سنعاود الإتصال بك قريباً.",
  "Do you want to get popular among your patients?": "هل تريد أن تكون محبوباً من بين مرضاك؟",
  "Do you want your patients to find your clinic without getting lost?": "هل تود أن يعثر مرضاك على عيادتك دون التوهان؟",
  "Do you want 24/7 service for your patients?": "هل تريد أن يعثر",
  "Product Tour": "مميزات المنتج",
  "Sounds interesting?": "هل أثرنا إهتمامك؟",
  "Let's talk more": "لنتحدث أكثر",
  "Product Features": "مميزات المنتج",
  "Get Discovered": "إشتهر عبر موقعنا",
  "Let your patients find you through our website": "دع مرضاك يجدونك على الموقع",
  "24/7 Booking request": "حجوزات و مواعيد على مدار الساعة",
  "Patients can request booking within few clicks even if your phones are busy or it's midnight and your clinic is closed.":
    "بإمكان المرضى طلب المواعيد عبر الموقع، من دون الحاجة للإتصال بالعيادة.",
  "Patient Reviews": "تعليقات الزوار",
  "Build your online presence and reputation by having your patients share their experience on your profile with everyone to see.":
    "تساعد تقيمات المرضى في تعزيز سمعتك بين الناس، و تساعد المرضى الجدد في زيارتك أيضاً.",
  "Clinic Highlights": "مميزات العيادة",
  "Let your patients know the benefits of your clinic and why they should choose your service.":
    "دع الزوار يتعرفون على أفضل مميزات العيادة، و جميع الخدمات التي توفرها.",
  "Custom Profile": "صفحة خاصة بك",
  "Showcase Your Personality": "أظهر شخصيتك",
  "Let your patients know you beyond your profession by sharing your philosophy towards medicine and treatment.":
    "إسمح لمرضاك بالتعرف على تفكيرك من خلال فلسفتك تجاه الأدوية والعلاجات.",
  Location: "المنطقة",
  "Help your patients find your location effortlessly without getting lost.":
    "اسمح لمرضاك للوصول بسهولة الى العيادة عن طريق عرض خارطة العيادة على صفحتك الخاصة.",
  "Clinic Hours": "ساعات العمل",
  "Make it easier for your patients to know at what hours you will be available.": "دع الزوار يعلمون أوقات عملك",
  "Professional Photos": "صور إحترافية",
  "Have photos of you and your clinic for patients to feel comfortable going to you.":
    "ضع صوراً للعيادة و صور أنجح العلاجات التي قدمتها لتجذب الزوار الجدد.",
  "Strategic Partners": "الشركاء الإستراتيجيين",
  "Do you want to get": "هل تريد أن تكون",
  "popular among": "محبوباً من بين",
  "your patients?": "مرضاك؟",
  "Do you want your patients": "هل تريد أن يعثر",
  "to find your clinic without": "مرضاك على عيادتك",
  "getting lost?": "بدون التوهان؟",
  "Do you want 24/7 service": "هل تريد خدمة",
  "for your patients?": "متواصلة لمرضاك؟",
  "Attract New Patients": "إجذب إنتباه المرضى",
  "Get reviews & referrals from happy patients to build your practice":
    "إحصل على إحالات و تقيمات من مرضى سعيدين لتحسن ممارستك الطبية",
  "Enhance Patient Experience": "حسّن تجربة المرضى",
  "Allow patients to send a booking request anytime, even when your clinic is closed.":
    "إسمح للمرضى بإرسال طلب حجز في أي وقت، حتى إن كانت العيادة مغلقة",
  "Strengthen Your Reputation": "عزز سمعتك",
  "Let your patients know about all your awesome educational credentials": "إسمح للمرضى بمعرفة مؤهلاتك العلمية الرائعة",
  "Why should I be on HeliumDoc?": "لماذا يجب أن أسجل في هيليوم دوك؟",
  "Patients can request booking within few clicks even if your phones are busy or it's midnight and your clinic is closed":
    "بإمكان المرضى طلب المواعيد عبر الموقع، من دون الحاجة للإتصال بالعيادة.",
  "Personal Search Listing": "إدرج اسمك على الموقع",
  "Patients can request booking within few clicks. Even if your phones are busy or your clinic is closed, patients will always be able to request an appointment.":
    "بإمكان المرضى طلب المواعيد عبر الموقع، من دون الحاجة للإتصال بالعيادة.",
  "Personalized Profile": "صفحة مخصصة لك",
  "Your profile will be customized with your credentials and specialties to let your patients know more about you!":
    "سنقوم بتخصيص صفحتك بمعلومات عن دراستك و تخصصك الطبي ليتمكن المرضى من معرفتك أكثر!",
  "As Seen On": "كما ظهرنا على",
  "Interested? We are here to help you!": "هل تود الإشتراك؟ دع معلوماتك هنا و سنتصل بك لنقابلك!",
  "Your Name": "الإسم",
  "Clinic or Hospital Name": "اسم العيادة أو المستشفى",
  "Email (Optional)": "البريد الإلكتروني (إختياري)",
  "Contact Number": "رقم التواصل",
  "Call Me Back": "إتصلوا بي",
  'Thank you for booking an appointment through <span class=\\"in-text-logo\\">HeliumDoc</span>':
    'شكراً لإستخدامكم <span class=\\"in-text-logo\\">هيليوم دوك</span> لحجز مواعيدكم',
  "You can expect a call shortly from": "يمكنك أن تتوقع مكالمة من",
  "to confirm your appointment within 24 hours.": "لتأكيد موعدك خلال ٢٤ ساعة",
  Confirmed: "",
  "No Answer": "",
  "Other Reason": "",
  "Visit the doctor at their clinic": "قم بزيارة الطبيب في عيادته.",
  "Visit the doctor via video consultation": "قم بزيارة الطبيب عبر استشارة الفيديو.",
  Navigate: "الخارطة",
  "View on Google Maps": "عرض على خريطة Google",
  Filters: "التصفيات",
  Speciality: "التخصص",
  Clinic: "عيادة",
  "Country of Education": "بلد التعليم",
  Area: "المنطقة",
  Gender: "الجنس",
  "All Doctors In Qatar": "جميع الأطباء في قطر",
  "show all": "عرض الكل",
  Nationality: "",
  "No Doctors Found": "لم يتم العثور على الأطباء",
  "We can't find a doctor matching your preferences": "لم نجد طبيباً حسب اختياراتك",
  "Do you know a doctor?": "هل تعرف طبيباً؟",
  "Let us know!": "تواصل معنا!",
  "We are working our best on adding all the good doctors in Qatar": "نحن نعمل لإضافة جميع الأطباء في قطر",
  "Online Booking": "الحجز عبر الإنترنت",
  Prev: "السابق",
  Next: "التالي",
  Home: "الرئيسية",
  "Free Consultation": "إستشارة مجانية",
  "Phone Appointment": "تقبل المواعيد المسبقة عبر الهاتف",
  Walkin: "لا حاجة لحجز موعد",
  RECOMMENDATIONS: "",
  "Open in Google Maps": "عرض على خريطة Google",
  Profile: "صفحة خاصة بك",
  Photos: "",
  "Emergency Info": "معلومات الطوارئ",
  "For emergency appointments on the same day send SMS to":
    "للمواعيد الطارئة في نفس اليوم بإمكانك إرسال رسالة نصية قصيرة إلى د. نزار خرما مباشرة على هاتفه النقال.",
  "directly on his mobile": "",
  "Call Emergency Number": "",
  "Professional Statement": "التعريف الشخصي",
  Qualifications: "",
  "Dental Schools": "كلية الأسنان",
  "Medical Schools": "كلية الطب",
  Diplomas: "الشهادة",
  "Board Certifications": "",
  "Country of Education & Practice": "بلد التعليم",
  "Share your experience:": "شاركنا بتجربتك:",
  "Be the first to review:": "كن أول من يعلق:",
  "How was your experience going to": "كيف كانت تجربتك بالذهاب لـ",
  "?": "؟",
  "to someone else?": "لشخص آخر؟",
  Yes: "نعم",
  No: "لا",
  "Would you recommend": "هل تنصح",
  Submit: "نشر",
  "Submit Review": "انشر تجربتك",
  "Already Reviewed!": "لقد نشرت تعليقاً من قبل!",
  "Write a Review": "نشر تعليق",
  "Posted on": "نشرت على",
  "Call Clinic Number": "عيادة",
  "Office Hours": "ساعات العمل",
  Phone: "رقم الهاتف",
  "Appointment Request": "طلب موعد",
  "Your Email": "البريد الإلكتروني",
  "Your Contact Number": "رقم التواصل",
  "Submit Booking": "تأكيد الموعد",
  "Insurance Providers with Direct Billing": "شركات التأمين",
  Close: "اغلاق النافذة",
  "<strong>Success!</strong> Thank you for requesting an appointment with Dr.": "نجاح! شكراً لطلب موعد مع د.",
  "on HeliumDoc. The clinic should give you a call shortly to confirm your request":
    "على هيليوم دوك. سوف يتم الإتصال بك لتأكيد الموعد.",
  recommendations: "توصيات",
  "For emergency appointments on the same day send sms to Dr. Nizar Kharma directly on his mobile":
    "للمواعيد الطارئة في نفس اليوم بإمكانك إرسال رسالة نصية قصيرة إلى د. نزار خرما مباشرة على هاتفه النقال.",
  "License Number": "رقم الرخصة الطبية",
  "Dental School": "كلية الأسنان",
  "Medical School": "كلية الطب",
  "Board Certification": "",
  Affiliations: "",
  "Blog Links": "",
  Other: "آخر",
  Contact: "تواصل معنا",
  "Search By": "البحث بـ:",
  "Doctor Name": "الاسم",
  "Clinic Name": "عيادة",
  "OB/GYN": "طبيب النساء والولادة",
  GPs: "طبيب عام",
  "Al Waab": "الوعب",
  "Al Hilal": "الهلال",
  "Al Duhail": "دحيل",
  "Abu Hamour": "ابو هامور",
  "Al Muntazah": "المنتزة",
  "Join HeliumDoc for Free!": "سجل في هيليوم دوك مجاناً",
  "Advertise your Clinic": "الإعلانات",
  "Made with": "صنع بـ",
  "in Qatar": "في قطر",
  "in UAE": "في الإمارات",
  "Find best doctors in Qatar based on patient reviews and credentials. Browse doctor profiles with their background information, get reviews from other patients going to that doctor and get clinic locations. Make an informed health decision based on knowledge, not chance.":
    "اعثر على أفضل الأطباء في قطر حسب تقيمات المرضى و معلوماتهم. اقرأ عن تخصصاتهم، و اقراً تقيم الأطباء لمساعدتك في العثور على أفضل الأطباء.",
  Bookings: "الحجز عبر الإنترنت",
  Dashboard: "",
  "Log Out": "تسجيل خروج",
  "Sign Up": "إشترك",
  Login: "سجل دخول",
  "Join Now": "إنضم الآن",
  "Get all information about a doctor instantly without calling the clinic":
    "أحصل على جميع المعلومات التي تحتاجها عن الطبيب من دون الإتصال",
  "Insurance/Pricing": "التأمين/الأسعار",
  in: "في",
  for: "لأجل",
  " Eye Doctors ": "أطباء العيون",
  "Your review is saved, and you will be able to post it when you create an account or login.":
    "لقد حفظنا تعليقك، و يمكنك نشره بعد تسجيل دخولك للموقع.",
  "Login with Facebook": "التسجيل عبر الفيسبوك",
  "Don't worry, we NEVER post without your permission. We hate that, too!":
    "لا تقلق، لن نقوم بإضافة أي شيء في صفحة الفيسبوك الخاصة بك. نحن نكره هذا أيضاً!",
  OR: "أو",
  "Your username and password don't match our records. Please try again.":
    "البريد الإلكتروني أو كلمة المرور لا يتطابق مع سجلاتنا. الرجاء المحاولة مرة أخرى.",
  "Don't have an account?": "ليس لديك حساب؟",
  "Sign up now": "الإشتراك",
  "Clinic Photos": "ساعات العمل",
  "Your username, in case you've forgotten:": "حسابك القديم، هو:",
  "The %(site_name)s team": "",
  "Send SMS Messages": "",
  "^": "^",
  "Info:": "إنتبه:",
  "The first 5 doctors are randomly sorted.": ".تم فرز أول ٥ أطباء بشكل عشوائي",
  Country: "الدولة",
  "I'm paying for myself": "سأتحمل التكاليف بنفسي",
  "Patient Name": "إسم المريض",
  "Enter your Full Name": "أدخل إسمك بالكامل",
  Mobile: "الهاتف النقال",
  "Enter your Mobile Number": "رقم الهاتف النقال",
  "Enter your mobile number": "رقم الهاتف النقال",
  "No more reviews": "لا مزيد من التعليقات",
  "Show more": "عرض المزيد",
  "Show less": "عرض أقل",
  Satisfied: "ممتازة",
  Neutral: "عادية",
  Unsatisfied: "سيئة",
  "No reviews yet. Be the first the first to leave a Review!": "لا توجد تعليقات حتى الآن. كن أول من يترك مراجعة!",
  "How was your experience?": "كيف كانت تجربتك مع الطبيب؟",
  "You need to choose one of the following.": "يجب عليك ان تختار إحداً من التالي.",
  "How was your experience with": "كيف كانت تجربتك مع",
  "Share your Experience": "شاركنا بتجربتك",
  "Thank you for sharing a review for": "شكرا لمشاركتك تجربتك مع",
  "You have already submitted a review for": "لقد قمت بمشاركة تجربتك من قبل مع",
  "No Matching Results": "لم يتم العثور على شيء",
  Back: "الى الخلف",
  "Back to My Appointments": "العودة إلى مواعيدي",
  "Clear Filters": "امسح الكل",
  "See Doctors": "إبحث أطباء",

  "See Offers": "إبحث عروض",
  Subspeciality: "تخصص فرعي",
  "Please fill in the form to confirm your appointment:": "يرجى تعبئة البيانات أدناه، و سنتصل بك لتأكيد الموعد:",
  "You need to log in before you can submit your review": "يجب عليك الإشتراك معنا قبل أن نعرض تعليقك",
  "Nat./Country": "بلد التعليم",
  "Show Nearby Doctors": "أظهر أقرب الأطباء",
  "Getting Location": "نعثر على موقعك",
  "Sending your Request": "جاري إرسال طلبك",
  "Choose a Clinic": "اختار عيادة",
  "This doctor belongs to multiple clinics, please choose a clinic:":
    "هذا الطبيب ينتمي لعدة عيادات، يرجى اختيار عيادة:",
  "Please choose a Clinic.": "الرجاء اختار عيادة.",
  "Choose Offers": "إختر عروض",
  "manager replied": "اجاب",
  Newest: "الأحدث",
  Oldest: "الأقدم",
  "Publish Review": "أنشر تقييمك",
  "Sort by:": "رتب حسب:",
  Sponsored: "اعلان",
  "Languages:": "لغات:",
  "Please enter a Valid Phone Number.": "يرجى إدخال رقم هاتف صحيح.",
  "Disclaimer: Prices/Offers are subject to change without prior notice. Terms and conditions apply.":
    "تنويه: قد تتغير الأسعار والعروض بدون إشعار مسبق. تطبق الشروط والإحكام.",
  "Select your Insurance": "اختر التأمين الخاص بك ",
  "Select Reason to Visit (Optional)": "اختر سبب الزيارة (اختياري)",
  "Something Went Wrong": "هناك خطأ ما",
  "Sorry, something went wrong in the backend. Please contact our support team for further help!":
    "عذرًا ، حدث عطل ما. يرجى الاتصال بفريق الدعم لمزيد من المساعدة!",
  "Please allow browser to access location": "	يرجى السماح للمتصفح للوصول إلى الموقع",
  "Sorry!": "عذرًا!",
  "You have to select a doctor before doing that": "يجب اختيار طبيب قبل القيام بذلك",
  "Success!": "تم بنجاح!",
  "Your information has been saved successfully.": "تم حفظ معلوماتك بنجاح.",
  "Cancelled appointment successfully": "تم إلغاء الموعد بنجاح",
  "Updated Appointment successfully": "تم تحديث الموعد بنجاح",
  "Cannot select a month in the future": "لا يمكن تحديد شهر في المستقبل",
  "Please choose the end date after start date": "يرجى اختيار تاريخ الانتهاء بعد تاريخ البدء",
  "Notice!": "تنويه!",
  "Error Occurred": "حدث خطأ",
  "Patient created!": "ضبط المريض",
  "Patient Details edited!": "	تم تعديل تفاصيل المريض!",
  "Reply Submitted!": "تم ارسال الرد!",
  "Reply to review submitted successfully!": "الرد على التعليق تم بنجاح!",
  "An error occurred, please contact the HeliumDoc team for assistance":
    "حدث خطأ ، يرجى الاتصال بفريق هيليوم دوك للحصول على المساعدة",
  "We have received your information and will contact you soon.": "لقد تلقينا معلوماتك وسنتصل بك قريبًا.",
  "An error occurred in submitting your information.": "حدث خطأ في إرسال معلوماتك.",
  "Please try again later.": "الرجاء معاودة المحاولة في وقت لاحق.",
  Warning: "تحذير",
  "Please enter your name": "من فضلك أدخل إسمك",
  "Please enter your clinic name": "يرجى إدخال اسم عيادتك",
  "Please enter your email": "يرجى إدخال بريدك الإلكتروني",
  "Please enter your phone number in a valid format": "يرجى إدخال رقم هاتفك بهيئة صحيحة",
  "Please Allow Browser to Find Your Location": "الرجاء السماح للمتصفح إيجاد موقعك",
  "Querying Server Failed!": "فشل الاستعلام عن الخادم!",
  "Please contact the HeliumDoc team for help!": "يرجى التواصل مع فريق هيليوم دوك للحصول على المساعدة!",
  "Review Submitted!": "تم إرسال التعليق!",
  "Thank you for submitting a review!": "شكرا لك على كتابة التعليق!",
  "You already reviewed this doctor": "لقد قمت بتقييم هذا الطبيب من قبل",
  "You already reviewed this doctor, thank you for your review!":
    "لقد قمت بتقييم هذا الطبيب من قبل، شكرا لك على تعليقك!",
  "Failure!": "فشل!",
  "Your review could not be submitted, please contact": "لم يتم ارسال تعليقك ، يرجى الاتصال",
  "the HeliumDoc team for further assistance!": "فريق هيليوم دوك للحصول على المساعدة!",
  "Your Review has been Saved!": "تم حفظ تعليقك!",
  "Your review has been saved, but you need to log in before you can submit it":
    "	تم حفظ تعليقك ، لكنك تحتاج إلى تسجيل الدخول قبل أن تتمكن من إرساله",
  "I'll pay myself": "سأدفع بنفسي",
  "Please select an Insurance Provider.": "يرجى اختيار مزود التأمين",
  "Please select your Insurance Plan.": "يرجى اختيار التأمين",
  "Please select a clinic": "يرجى اختيار العيادة",
  "Please select Yes or No.": "يرجى اختيار نعم أو لا.",
  "How are you going to pay": "طريقة الدفع",
  "Do you have insurance?": "هل لديك تأمين؟",
  "This doctor and clinic does not accept ": "هذا الطبيب والعيادة لا يقبلان ",
  ". Would you still like to book with him/her?": ". هل لازلت ترغب بحجز موعد معه/معها؟",
  "No. Find me a doctor who accepts my insurance.": "لا. عرض الأطباء الذين يقبلون التأمين.",
  "Other...": "أخرى",
  "General Consultation": "استشارة عامة",
  "Change Country": "تغيير البلدان",
  Dubai: "دبي",
  Bahrain: "البحرين",
  "Are You a Doctor?": "هل أنت طبيب؟",
  "Follow us": "تابعنا",
  Menu: "القائمة",
  "No Offers Found": "لم يتم العثور على عروض",
  "Expires on:": "ينتهي في",
  "Expires ": "ينتهي ",
  "Search for Doctors, Specialities, Clinics...": "إبحث عن أطباء، تخصصات، عيادات...",
  Logout: "الخروج",
  "Enter your details": "ادخل التفاصيل الخاصة بك",
  "Are you booking for yourself?": "هل تحجز موعد لنفسك؟",
  "Complete your details": "أكمل بياناتك",
  "Select a Reason a visit (Optional)": "قم باختيار سبب الزيارة (اختياري)",
  "Choose a time for your booking": "قم باختيار وقت الموعد",
  "Please select a time for your booking.": "يرجى اختيار وقت الموعد",
  Continue: "استمر",
  Emirates: "الإمارات",
  Nigeria: "نيجيريا",
  "Step 1": "الخطوة ١",
  "Step 2": "الخطوة ٢",
  "Choose City": "اختر مدينة",
  "Choose Area": "اختر منطقة",
  "All Areas": "جميع المناطق",
  "All Cities": "جميع المدن",
  "Choose Plan": "اختر الخطة",
  "View Profile": "عرض الطبيب",
  "View Clinic": "عرض العيادة",
  "Select a View": "اختر القائمة",
  Clinics: "عيادات",
  "No Available timings for this day": "لا يوجد مواعيد لهذا اليوم",
  Cancel: "إلغاء",
  "Sorry we could not find any results": "عذرًا ، لم نتمكن من العثور على أي نتائج!",
  "No Results Found": "لا توجد نتائج",
  "Sub Specializations": "التخصصات الفرعية",
  Available: "متاح",
  "All Specializations": "كل التخصصات",
  "All Specialities": "كل التخصصات",
  "Next Available": "أقرب موعد",
  "Doctor is available": "الطبيب متاح",
  "Special Offers": "عروض خاصة",
  "Average Ratings": "معدل التقيمات",
  "New Patient. This is the first time I go to": "مريض جديد. هذه زيارتي الأولى إلى",
  "Multiple patient records were found for the phone number": "تم العثور على عدة سجلات مرضى لرقم الهاتف",
  at: "في",
  "Recommended reviews": "التعليقات الموصى بها",
  "Please Confirm Patient": "يرجى تأكيد المريض",
  "Please select a patient.": "يرجى الاختيار.",
  "If you require any assistance, please call": "إذا كنت بحاجة إلي أي مساعدة، يرجى الاتصال على",
  "Please select the correct one:": "يرجى اختيار السجل الصحيح:",
  "First-come, first-served. Submit a booking to get the doctor's schedule and clinic location.":
    "الأولوية للحاضرين أولاً. قم بتأكيد الحجز للحصول على جدول أوقات الطبيب وموقع العيادة.",
  "Acceptance of Terms": "قبول الشروط",
  "Remember Me": "تذكرني",
  "Enter Email": "أدخل البريد الإلكتروني",
  "Enter Password": "أدخل كلمة المرور",
  "Log in": "سجل دخول",
  "Full Name": "الإسم الكامل",
  Name: "الإسم",
  Email: "البريد الإلكتروني",
  "Email address": "البريد الإلكتروني",
  Password: "كلمة المرور",
  "Re-enter Password": "أعد ادخال كلمة المرور",
  "Enter the same password as above, for verification.": "أدخل نفس كلمة المرور مرة أخرى، للتحقق",
  "Create your account": "أنشئ حسابك",
  "Old password": "كلمة المرور السابقة",
  "New password": "كلمة المرور الجديدة",
  "New password (again)": "كلمة المرور الجديدة (مرة أخرى)",
  "Enter email": "أدخل البريد الإلكتروني",
  "Recover Password": "إعادة تعيين كلمة المرور",
  "Enter new password": "أدخل كلمة المرور",
  "Enter new password (again)": "أدخل كلمة المرور",
  "Welcome to HeliumDoc": "مرحبا بك في هيليوم دوك",
  "Book Appointments with the Best Doctors": "احجز موعدك مع أفضل الأطباء في",
  Specialization: "التخصص",
  City: "المدينة",
  Find: "إبحث عن",
  "Find Discounts": "إبحث عن العروض",
  Insurance: "التأمين",
  Best: "أفضل",
  "Best doctor for": "أفضل طبيب لأجل",
  "Best Clinics": "أفضل عيادات",
  "24 hours": "٢٤ ساعة",
  Midnight: "منتصف الليل",
  ",": "،",
  now: "الآن",
  today: "اليوم",
  tomorrow: "غداً",
  on: "في تاريخ",
  Sunday: "الأحد",
  Monday: "الإثنين",
  Tuesday: "الثلاثاء",
  Wednesday: "الأربعاء",
  Thursday: "الخميس",
  Friday: "الجمعة",
  Saturday: "السبت",
  Free: "مجانية",
  Network: "شبكة التأمين",
  "General Practitioners (GP)": "أطباء عامين",
  "general+practitioner": "طبيب+عام",
  "Internal Medicine": "أطباء الأمراض باطنية",
  "internal+medicine": "أمراض+باطنية",
  Pediatricians: "طبيب أطفال",
  pediatrician: "طبيب+أطفال",
  "Obstetricians & Gynecologists (OB/GYN)": "طبيب أمراض النساء والولادة",
  "obstetrician+and+gynecologist": "نساء+وولادة",
  Dentists: "طبيب أسنان",
  dentist: "طبيب+أسنان",
  Orthopedists: "أخصائي تقويم العظام",
  orthopedist: "طبيب+عظام",
  Urologists: "أطباء مسالك بولية",
  urologist: "مسالك+بولية",
  "Eye Doctors": "أطباء العيون",
  ophthalmologist: "طبيب+عيون",
  "Ear, Nose & Throat (ENT)": "أطباء أنف وأذن وحنجرة",
  "e+n+t": "أنف+وأذن+وحنجرة",
  Endocrinologist: "أطباء الغدد",
  endocrinologist: "طبيب+غدد",
  Dermatologists: "أطباء الامراض الجلدية",
  dermatologist: "طبيب+جلدية",
  "Plastic Surgeons": "جراحي التجميل",
  "plastic+surgeon": "جراحة+تجميلية",
  Psychiatrists: "أطباء نفسين",
  psychiatrist: "طبيب+نفسي",
  OBGYN: "طبيب أمراض النساء والولادة",
  Arguments: "العلاجات",
  English: "الإنجليزية",
  Arabic: "العربية",
  Qatar: "قطر",
  qatar: "قطر",
  UAE: "الإمارات",
  "Saudi Arabia": "المملكة العربية السعودية",
  Treatment: "العلاج",
  "Procedures & Treatments": "الإجراءات والعلاجات",
  Telemedicine: "زيارات عن بعد",
  "Sub Specialization": "التخصص الفرعي",
  "City/Area": "المدينة/المنطقة",
  "Nationality/Country of Education": "بلد التعليم",
  Language: "لغة",
  male: "ذكر",
  female: "أنثى",
  Male: "ذكر",
  Female: "أنثى",
  "Dr.": "د.",
  "Prof. Dr.": "أ. د.",
  "Mr.": "السيد",
  "Mrs.": "السيدة",
  "Ms.": "الآنسة",
  Specialist: "أخصائي",
  Consultant: "استشاري",
  "Senior Consultant": "استشاري اول",
  Counselor: "مستشار",
  "Associate Specialist": "أخصائي مساعد",
  "Today at": "اليوم",
  "Tomorrow at": "غداً",
  Consultation: "الإستشارة",
  "Free consultation": "استشارة مجانية",
  "Best Doctors": "أفضل الأطباء",
  Default: "أساسي",
  "Most Recommended": "الأكثر توصية",
  "Most Popular": "الأكثر شعبية",
  "Closest Doctors": "أقرب الأطباء",
  "Thank you for using HeliumDoc. \\n Here's the location for {}: {}\\n":
    "شكراً لإستخدامك هيليوم دوك. \\n هذا موقع {}: {}\\n",
  "Would you like to know the costs of consultation/treatment for Dr. {}?":
    "هل تود معرفة تكاليف الاستشارة والعلاج عند د. {}؟",
  "You're receiving this email because you requested a password reset for your  user account at %(site_name)s.":
    "لقد وصلك هذا البريد لأنك طلبت إعادة تعيين كلمة المرور لحسابك في هيليوم دوك HeliumDoc.",
  "Please go to the following page and choose a new password:":
    "إذا كنت تريد إعادة تعيين كلمة المرور، قم بزيارة هذا الرابط:",
  "Your login email, in case you've forgotten, is same this email address:": "حسابك القديم، هو:",
  "Thanks for using our site!": "شكراً لزيارتك لموقعنا!",
  "The HeliumDoc team": "فريق هيليوم دوك",
  "Password reset on %(site_name)s": "كلمة المرور لإعادة تعيين البريد الإلكتروني أرسلة",
  "Welcome Back!": "مرحباً بك مجدداً",
  "Not a HeliumDoc user yet?": "لم تشترك بعد؟",
  "Create your account.": "أنشئ حسابك",
  "Forgot Password?": "هل نسيت كلمة المرور؟",
  "Continue with Google": "التسجيل عبر Google",
  "Continue with Facebook": "التسجيل عبر Facebook",
  or: "أو",
  "Password Change": "تغير كلمة المرور",
  "Reset link sent": "أرسلنا الرابط لإعادة تعيين كلمة السر",
  "Please check your mail. You would have received a password reset mail from us.":
    "تم إرسال بريد الكتروني يحتوي على تفاصيل تغير كلمة المرور.",
  "Password recovery": "استعادة كلمة المرور",
  "Remember your password?": "تذكرت كلمة المرور؟",
  "Back to Login": "سجل الدخول",
  "Already a User?": "هل أنت مستخدم؟",
  'By clicking "Create your account", you are agreeing to the HeliumDoc': 'بالضغط على "أنشئ حسابك"، فإنك توافق على',
  "Terms of Service": "الشروط والأحكام",
  and: "و",
  "Privacy Policy": "سياسة الخصوصية",
  Action: "موقعك",
  HeliumDoc: "هيليوم دوك",
  Required: "مطلوب",
  "You have to select a speciality before searching": "يجب اختيار تخصص قبل البحث",
  "Select Speciality": "إختر تخصص الطبيب",
  "Area (Optional)": "المنطقة (إختياري)",
  "Select Area": "إختر المنطقة",
  Search: "إبحث",
  "Popular Specialities": "التخصصات الشائعة",
  "Obstetricians & Gynecologists": "طبيب أمراض النساء والولادة",
  "General Doctors": "طبيب عام",
  "Skin Doctors": "طبيب الأمراض الجلدية",
  "Stay Healthy With Us": "مقالات صحية",
  "Read the latest healthcare articles and doctor opinions on our brand new blog.":
    "تعرف على أحدث الأخبار في المجال الصحي وأقرأ في مدوناتنا",
  "Explore more about various treatments, health concerns, and home remedies from the best doctors!":
    "عن آراء الأطباء  المختصين عن تخصصاتهم وعلاجاتهم ومواضيع في الصحة العامة.",
  "Our blog": "من مدونتنا",
  "Read More": "إقرأ المزيد...",
  "Choose speciality, insurance, area": "اختر التخصص، التأمين، المنطقة",
  Featured: "مميز",
  "Book Now": "إحجز موعد",
  "Book appointment with": "إحجز موعد مع",
  Call: "إتصل",
  "to book an appointment": "لحجز موعد",
  "Popular Treatments": "العلاجات الشائعة",
  "Back to Search": "الرجوع إلى البحث",
  "Clinic Price List": "قائمة اسعار العيادة",
  Address: "عنوان",
  "Working Times": "أوقات العمل",
  "Open now": "مفتوح الآن",
  "Closed now": "مغلق الآن",
  Closed: "مغلق",
  "About the Clinic": "عن العيادة",
  Services: "خدمات",
  "Languages Spoken at": "اللغات التي يتم التحدث بها في",
  Doctors: "أطباء",
  Equipment: "معدات",
  "View More": "أظهر المزيد",
  Insurances: "تأمين",
  "Medical Equipment": "المعدات الطبية",
  "Years of Experience": "سنوات الخبرة",
  Details: "التفاصيل",
  Reviews: "تعليقات المرضى",
  Specialities: "التخصصات",
  "Speciality is required": "التخصص مطلوب",
  "Languages Spoken": "لغة التحدث",
  Treatments: "علاجات",
  Credentials: "أوراق الإعتماد",
  Education: "التعليم",
  "Dental Qualifications": "المؤهلات الطبية",
  "Medical Qualifications": "المؤهلات الطبية",
  Diploma: "الدبلوم",
  Residency: "الإقامة",
  Fellowship: "الإلتحاقات",
  Board: "مجلس",
  Affiliation: "الانتماء",
  "Read More Reviews": "إقرأ المزيد...",
  "Insurance providers with Direct Billing": "شركات التأمين مع الدفع المباشر",
  "Similar Doctors": "أطباء مماثلين",
  "Call Now": "إتصل الآن",
  reviews: "تعليقات المرضى",
  "No reviews yet": "لا توجد تعليقات حتى الآن",
  HeliumDoc: "هيليوم دوك",
  "2020 HeliumDoc Inc.": "جميع الحقوق محفوظة ٢٠٢٠ هيليوم دوك",
  Areas: "المناطق",
  "About us": "إقرأ عنا",
  Blog: "المدونة",
  "Terms of Use": "الشروط والأحكام",
  "Search doctors, procedures, clinics": "إبحث عن أطباء، تخصصات، عيادات...",
  "General Care": "عناية عامة",
  "Specialized Care": "الرعاية المتخصصة",
  "Other Care": "الرعاية الأخرى",
  "Are you a Doctor?": "هل أنت طبيب؟",
  "Internal Dashboard": "لوحة التحكم (هيليوم دوك)",
  "Clinic Dashboard": "لوحة العيادة",
  "Send SMS": "أرسل رسالة نصية قصيرة",
  "Bookings Report": "تقرير الحجز",
  "Bookings Management": "إدارة الحجوزات",
  "Reviews Management": "إدارة التقييمات",
  "Practice Management": "إدارة المواعيد",
  "Invoice Management": "إدارة الفواتير",
  "Sign up": "إشترك",
  "Do you need any help? Message us": "هل تحتاج للمساعدة؟ تواصل معنا",
  "Thank you for using HeliumDoc": "شكرا لك على استخدام هيليوم دوك",
  "Thank you for booking an appointment through HeliumDoc": "شكراً لإستخدامكم هيليوم دوك لحجز مواعيدكم",
  "only accepts Walk-ins. You can visit the clinic at these times:":
    "يستقبل زيارات بدون مواعيد. يمكنك زيارة العيادة في هذة الأوقات:",
  "Please visit": "يرجى زيارة صفحة",
  "page for more information about the location of the clinic.": "لمزيد من المعلومات حول موقع العيادة",
  "Your appointment has been confirmed for": "تم تاكيد حجز موعدك في التاريخ المحدد أدناه",
  with: "مع",
  "to confirm the date and time of your appointment with": "لتأكيد وقت ويوم موعدك مع",
  "Back to Doctor Listings": "العودة الى قائمة الأطباء",
  "To help the clinic provide the best care, please complete your additional info":
    "لمساعدة العيادة على تقديم أفضل رعاية، يرجى إكمال معلوماتك",
  "Please choose a gender.": "الرجاء إختيار الجنس.",
  "Enter your Email": "أدخل البريد الإلكتروني",
  Reason: "السبب",
  "Select a Reason": "اختر سبب الزيارة",
  Send: "أرسل",
  "Refer friends & family and get free vouchers!": "احصل على قسيمة شراء مجانية عند مشاركتك مع الأصدقاء والعائلة!",
  "For every 2 people who make a booking through the link get a <strong>QR 100  voucher</strong> for Carrefour or Sephora or Talabat.":
    "شارك الرابط مع ٢ من الأصدقاء أو العائلة لحجز موعد من خلال هيليوم دوك واحصل على  <strong>قسيمة شراء مجانية بقيمة ١٠٠ ريال قطري</strong> مقدمة من كارفور ،  سيفورا أو طلبات",
  "Share with:": "شارك:",
  "I just booked a doctor on HeliumDoc, check them out you can find and book with  the best doctors in Qatar.":
    "لقد حجزت موعد مع طبيب من خلال هيليوم دوك ، تصفح القائمة واحجز موعدك مع افضل  الأطباء في قطر",
  "Copy and share the link below:": "إنسخ وشارك الرابط التالي:",
  "We will contact you for your reward when your link has been used!":
    "سنقوم بالتواصل معك لأجل جائزتك في حال استخدام الرابط!",
  "Sharing Successful!": "تمت المشاركة بنجاح!",
  "Thank you for sharing,": "شكرا لك على المشاركة ،",
  "Doctor is currently on leave until": "الطبيب حالياً في إجازة حتى",
  "Doctor is currently on leave until further notice": "الطبيب حالياً في إجازة حتى إشعار آخر",
  "You are booking an appointment with": "انت تحجز موعد مع",
  "Search Specialities": "ابحث على التخصص",
  "Download our App": "حمل التطبيق",
  "Follow Us": "تابعنا على",
  "Find the Best Doctors in": "اعثر على أفضل الأطباء في",
  "Find a doctor instantly": "ابحث عن طبيب على الفور",
  "Filter quickly by insurance, specialty, and location.": "ابحث عن طبيب من خلال التخصص ، التأمين ، وموقع العيادة.",
  "Make informed decisions": "اقرأ تقييمات الأطباء",
  "Research doctors with patient reviews.": "تصفح آراء وتقيمات المرضى عن الأطباء.",
  "Book Online": "احجز عبر الانترنت",
  "Instantly book online and receive text reminders.": "احجر موعدك عبر الانترنت واحصل رسائل تذكير بالموعد.",
  "Book an appointment": "احجز موعد",
  "404 - Page not Found | HeliumDoc": "٤٠٤ - الصفحة غير موجودة",
  "404. Page Not Found": "٤٠٤ - الصفحة غير موجودة",
  "We couldn't find the page that you're looking for!": "الصفحة التي طلبتها ليست موجودة على الموقع.",
  "Back to Home": "الرجوع إلى الرئسية",
  "500. Server Error": "٥٠٠ - خلل في الطلب",
  "Sorry, some error seems to have occurred. We will be back soon!":
    "عذراُ، حدث خطأ في تحميل الصفحة، يرجى المحاولة لاحقاً.",
  "Select City": "إختر المدينة",
  Cities: "المدن",
  "Search Areas": "إبحث على المنطقة",
  "Search Cities": "إبحث عن المدينة",
  "Please Select": "الرجاء الإختيار",
  close: "اغلق",
  "Clear All": "امسح الكل",
  Clear: "امسح",
  "Nothing found here!": "لا يوجد شيء هنا!",
  "View more": "عرض المزيد",
  "View less": "عرض أقل",
  applied: "استعمل",
  "Specialities:": "التخصصات:",
  "Area:": "المنطقة:",
  "Area of": "منطقة",
  "We can't find an offer matching your preferences": "لم نجد على عرض حسب اختياراتك",
  "These doctors have your discount!": "العرض متاح لدى الأطباء التاليين",
  "Choose one to continue": "اختر طبيب للمتابعة",
  "Sort By": "ترتيب حسب:",
  selected: "محدد",
  "Sort By:": "ترتيب حسب:",
  Popular: "الأكثر شعبية",
  "Call to Book an Appointment": "اتصل لحجز موعد",
  "Branch:": "الفرع:",
  "Branches of": "فروع",
  "Working Hours at": "ساعات عمل",
  "Ramadan Working Hours at": "ساعات عمل في رمضان في",
  "This clinic does not accept any insurance with Direct Billing": "هذه العيادة لا تقبل أي تأمين مع الدفع المباشر",
  "This doctor does not accept any insurance with Direct Billing": "هذا الطبيب لا يقبل أي تأمين مع الدفع المباشر",
  "Booking Details": "تفاصيل الحجز",
  "View Prices": "عرض الأسعار",
  Discounts: "الخصومات",
  "Clinic Location": "موقع العيادة",
  "Share this doctor with a friend": "شارك هذه الصفحة مع صديق",
  Prices: "الأسعار",
  "Call Support": "اتصل للمساعدة",
  "Call Clinic": "اتصل بالعيادة",
  "For admin Eyes only :)": "عشانك ياسارة حنط من العمارة",
  "Location of": "موقع",
  Careers: "وظائف",
  "Contact Us": "اتصل بنا",
  "For clinics": "للعيادات",
  Widgets: "تطبيقات مصغرة",
  "Need help finding a doctor?": "هل تحتاج مساعدة في العثور على طبيب؟",
  "Call now and our team will help you find the best doctor for your case!":
    "اتصل الآن وسوف نساعدك في العثور على أفضل طبيب لحالتك!",
  "No, I can find one myself": "كلا، يمكنني العثور عليه بنفسي",
  "Patients can request booking within few clicks even if your phones are busy  or it's midnight and your clinic is closed.":
    "بإمكان المرضى طلب المواعيد عبر الموقع، من دون الحاجة للإتصال بالعيادة.",
  "Build your online presence and reputation by having your patients share  their experience on your profile with everyone to see.":
    "تساعد تقيمات المرضى في تعزيز سمعتك بين الناس، و تساعد المرضى الجدد في زيارتك  أيضاً.",
  "Let your patients know the benefits of your clinic and why they should  choose your service.":
    "دع الزوار يتعرفون على أفضل مميزات العيادة، و جميع الخدمات التي توفرها.",
  "Let your patients know you beyond your profession by sharing your philosophy  towards medicine and treatment.":
    "إسمح لمرضاك بالتعرف على تفكيرك من خلال فلسفتك تجاه الأدوية والعلاجات.",
  "Have photos of you and your clinic for patients to feel comfortable going to  you.":
    "ضع صوراً للعيادة و صور أنجح العلاجات التي قدمتها لتجذب الزوار الجدد.",
  "Allow patients to send a booking request anytime, even when your clinic is  closed.":
    "إسمح للمرضى بإرسال طلب حجز في أي وقت، حتى إن كانت العيادة مغلقة",
  "Why should I be on HeliumDoc?": "لماذا يجب أن أسجل في هيليوم دوك؟",
  "Patients can request booking within few clicks even if your phones are busy  or it's midnight and your clinic is closed":
    "بإمكان المرضى طلب المواعيد عبر الموقع، من دون الحاجة للإتصال بالعيادة.",
  "Patients can request booking within few clicks. Even if your phones are busy  or your clinic is closed, patients will always be able to request an  appointment.":
    "بإمكان المرضى طلب المواعيد عبر الموقع، من دون الحاجة للإتصال بالعيادة.",
  "Your profile will be customized with your credentials and specialties to let  your patients know more about you!":
    "سنقوم بتخصيص صفحتك بمعلومات عن دراستك و تخصصك الطبي ليتمكن المرضى من معرفتك  أكثر!",
  "Updated on July 11, 2017": "تم التحديث في 11 يوليو 2017",
  Preface: "مقدمة",
  "Selected:": "اختيارك:",
  "My Appointments": "مواعيدي",
  Upcoming: "قادمة",
  Completed: "مكتملة",
  Cancelled: "ملغاة",
  "Please specify other reason": "يرجى تحديد سبب آخر",
  "Why book through HeliumDoc?": "لماذا عليك أن تحجز عبر هيليوم دوك؟",
  "Instant Appointments": "التأكيد الفوري للموعد",
  "Free Cancellation": "إلغاء مجاني",
  "Exciting Offers": "عروض خاصة",
  "Get all information about a doctor instantly without calling the clinic.":
    "احصل على كل المعلومات حول الطبيب فورًا بدون الاتصال بالعيادة!",
  "Get exciting discounts and offers with doctors when booking with HeliumDoc!":
    "احصل على خصومات وعروض مختلفة مع الأطباء عند الحجز عبر هيليوم دوك!",
  "We get it - sometime things come up and you have to decline an appointment, you can re-book anytime the same appointment from your profile.":
    "نحن نتفهم الأمر - تطرأ بعض الأمور في بعض الأحيان التي تستدعي منك أن تلغي الموعد، لا تقلق! يمكنك في أي وقت إعادة حجز نفس الموعد من حسابك الشخصي.",
  "Reschedule Appointment": "تغيير الموعد",
  "Cancel Appointment": "إلغاء الموعد",
  "Cancel Appointment?": "إلغاء الموعد؟",
  "Pay for Consultation": "دفع رسم الاستشارة",
  "Join Call": "انضم الى المكالمة",
  "You don’t have any upcoming appointments at the moment.": "ليس لديك أي مواعيد قادمة في الوقت الحالي",
  "You don't have any completed appointments at the moment.": "ليس لديك أي مواعيد مكتملة في الوقت الحالي",
  "You don't have any cancelled appointments at the moment.": "ليس لديك أي مواعيد ملغاة في الوقت الحالي",
  "After cancelling any appointment, you will see it here.": "بعد الغاء أي موعد، سيمكنك رؤيته هنا.",
  "After scheduling any appointment, you will see it here.": "بعد حجز أي موعد، سيمكنك رؤيته هنا.",
  "After completing any appointment, you will see it here.": "بعد الانتهاء من أي موعد، سيمكنك رؤيته هنا",
  "Start booking!": "ابدأ الحجز!",
  "Book again": "إحجز مجدداً",
  "Available slots for": "مواعيد متاحة ل",
  "Choose a new Date & Time": "اختر تاريخًا ووقتًا جديدين للموعد",
  "Provide Patient Details": "قدم تفاصيل المريض",
  "Details will be sent to the clinic you are booking": "سيتم إرسال التفاصيل التالية إلى العيادة التي تقوم بالحجز فيها",
  "Previous Date & Time": "التاريخ والوقت السابقين",
  "Reason for cancellation:": "سبب الإلغاء:",
  "Work Related Reasons": "أسباب متعلقة بالعمل",
  "Personal Emergency": "طارئ شخصي",
  "Lack of Transportation": "عدم توفر وسيلة مواصلات",
  "Don't feel sick": "لا تشعر أنك مريض",
  "Not in pain anymore": "لا تشعر بأي ألم حالياً",
  "Type your reason here": "اكتب سبب الإلغاء هنا",
  "Please choose a reason for cancellation.": "يرجى اختيار سبب الإلغاء.",
  "Yes, Cancel": "تأكيد الإلغاء",
  "Don't Cancel": "تراجع",
  "Select Date": "حدد التاريخ",
  "Select Time": "حدد الوقت",
  "This doctor is not available for this date.": "هذا الطبيب غير متاح في هذا التاريخ.",
  "Please make sure you choose a date and selected a time for this appointment.":
    "يرجى التأكد من اختيار تاريخ وتحديد وقت لهذا الموعد.",
  "Appointment Cancelled": "تم إلغاء الموعد",
  "Below appointment has been cancelled.": "تم إلغاء الموعد أدناه.",
  "Get directions": "احصل على الاتجاهات",
  "Appointment Rescheduled": "تم تغيير الموعد",
  "Thank you for booking an appointment through HeliumDoc, your appointment has been rescheduled.":
    "شكرا لحجز موعد عبر هيليوم دوك، تم تغيير موعدك.",
  "Clinic Name & Location": "اسم العيادة وموقعها",
  "Patient Booking Details": "تفاصيل حجز المريض",
  "Mobile Number": "رقم الهاتف النقال",
  "With Email": "عبر البريد الالكتروني",
  "With Phone": "عبر الهاتف",
  "You can view and manage all your appointments here.": "يمكنك عرض وتعديل تفاصيل كل مواعيدك هنا.",
  "You have to sign in to be able to view your appointments.": "يجب عليك تسجيل الدخول لتتمكن من عرض مواعيدك.",
  "To help the clinic provide the best care, please complete your additional info.":
    "لمساعدة العيادة على تقديم أفضل رعاية، يرجى إكمال معلوماتك الإضافية.",
  "Thank you for booking an appointment through HeliumDoc, Your appointment has been rescheduled.":
    "لمساعدة العيادة على تقديم أفضل رعاية، يرجى إكمال معلوماتك الإضافية",
  "Complete Profile": "أكمل تفاصيل حسابك الشخصي",
  "Upload Again": "ارفع مرة أخرى",
  "The other side": "الوجه الآخر",
  "We require copy of your ID or Passport to continue": "نطلب نسخة من هويتك أو جواز سفرك للمتابعة",
  "Back & Front Required": "الوجه والظهر مطلوبان",
  "Upload ID": "رفع بطاقة الهوية",
  "Upload Passport": "رفع جواز السفر",
  "Upload the Other Side": "رفع الوجه الآخر",
  "Next Step": "الخطوة التالية",
  "Upload your insurance or skip this step if you have no insurance":
    "قم برفع صورة للتأمين الخاص بك أو تخطي هذه الخطوة إذا لم يكن لديك تأمين",
  "No Insurance?": "لا يوجد تأمين؟",
  Skip: "تخطي",
  Finish: "إنهاء",
  "Upload ID or Passport": "رفع بطاقة الهوية أو جواز السفر",
  "Upload Insurance": "رفع صورة التأمين",
  "Video Consultation": "استشارة عبر الفيديو",
  "insurance available": "التأمين متاح",
  "Visit the doctor via video consultation.": "قم بزيارة الطبيب عبر استشارة الفيديو.",
  "Starting at": "يبدأ من",
  "Clinic Visit": "زيارة العيادة",
  "Video Visit": "زيارة الفيديو",
  "Home Visit": "زيارة منزلية",
  "Audio Visit": "زيارة صوتية",
  "Visit the doctor at their clinic.": "قم بزيارة الطبيب في عيادته.",
  "Choose Consultation Type": "اختر نوع الاستشارة",
  "You are booking a clinic visit with": "أنت تقوم بحجز زيارة عيادة مع",
  "You are booking a video visit with": "أنت تقوم بحجز زيارة فيديو مع",
  "You are booking a home visit with": "أنت تقوم بحجز زيارة منزلية مع",
  "You are booking a audio visit with": "أنت تقوم بحجز زيارة صوتية مع",
  "Payment Failed": "فشلت عملية الدفع",
  "Please retry or email": "يرجى إعادة المحاولة أو إرسال رسالة إلى",
  "If the appointment cannot be confirmed for any reason, you will get a": "إذا لم يتم تأكيد الموعد لأي سبب، سوف تسترد",
  "100% refund": "100٪ من المبلغ",
  "Your credit card will not be charged yet.": "لن يتم تحصيل أي مبالغ من بطاقتك الائتمانية حتى الآن.",
  "will call you shortly after submitting your payment information to confirm the date and time of your appointment.":
    "ستتصل بك بعد فترة وجيزة من ارسال معلومات الدفع لتأكيد تاريخ ووقت موعدك.",
  "Enter your Credit Card Details": "ادخل تفاصيل بطاقة الائتمان",
  "Payment Details": "تفاصيل الدفع",
  "Your transactions are 100% secure": "معاملاتك آمنة 100٪",
  Pay: "دفع",
  "Submit Payment Information": "أرسل معلومات الدفع",
  "Payment Successful": "تم الدفع بنجاح",
  "An SMS will be sent to you with the details of the appointment.":
    "سيتم إرسال رسالة نصية قصيرة تحتوي على تفاصيل الموعد.",
  "Booking Completed Successfully": "اكتمل الحجز بنجاح",
  "Payment Expired": "انتهت صلاحية الدفع",
  "Payment links expire": "تنتهي صلاحية روابط الدفع بعد",
  "minutes after the appointment is created.": "دقيقة من إنشاء الموعد.",
  "Appointment Details": "تفاصيل الموعد",
  "Redirecting you to Complete Profile": "تفاصيل الموعد",
  "Upload Front Side": "رفع الوجه الأمامي",
  "Passport Image": "صورة جواز السفر",
  "No ID?": "لا يوجد بطاقة هوية؟",
  "No Passport?": "لا يوجد جواز سفر؟",
  "Visit the doctor in the audio consultation.": "استشارة مجانية",
  "Have a Promocode?": "هل لديك كود ترويجي؟",
  "You have to sign in before you can pay.": "يجب عليك تسجيل الدخول قبل أن تتمكن من الدفع.",
  "You have to sign in before you can complete your profile.":
    "يجب عليك تسجيل الدخول قبل أن تتمكن من إكمال ملفك الشخصي.",
  "Completing your profile is": "إكمال ملفك الشخصي",
  mandatory: "إلزامي",
  "before an appointment.": "قبل الموعد.",
  "Complete My Profile": "أكمل ملفي الشخصي",
  "Please enter your booking details below.": "يرجى إدخال تفاصيل الحجز أدناه.",
  "Complete your profile": "أكمل ملفك الشخصي",
  "to join the call": "للانضمام إلى المكالمة",
  "Verify your Number": "تحقق من رقمك",
  "Sorry, something went wrong. Please contact our support team for further help!":
    "عذرا، هناك خطأ ما. يرجى الاتصال بفريق الدعم لمزيد من المساعدة!",
  "You already have a booking with this doctor on this date. Please choose another date to continue":
    "لقد سبق وحجزت موعداً مع هذا الطبيب في  هذا اليوم. يرجى تحديد تاريخ آخر للمتابعة",
  "resend pin by SMS": "إعادة الإرسال عبر رسالة نصية",
  "Resend pin by WhatsApp": "إعادة إرسال الرمز عبر واتساب",
  "whatsapp-in": "خلال",
  "Pin successfully sent by WhatsApp": "تم إرسال الرمز بنجاح عبر واتساب",
  "Pin sent by WhatsApp": "أُرسل الرمز عبر واتساب",
  "Number not found on WhatsApp": "لم يُعثر على الرقم في واتساب",
  "Use OTP instead?": "استخدم كلمة مرور لمرة واحدة بدلاً من ذلك؟",
  "You have requested the maximum number of allowed OTPs. Please try again in a few hours.":
    "لقد تجاوزت العدد المسموح طلبه لرمز التأكيد. يرجى إعادة المحاولة بعد بضعة ساعات.",
  "OTP has been sent to your number!": "تم ارسال رقم سري صالح لمرة واحدة الي هاتفك بنجاح",
  "Use password instead?": "استخدم كلمة المرور بدلاً من ذلك؟",
  "User doesn't exist!": "المستخدم غير موجود!",
  Error: "خطأ",
  "Not your number? Click here choose another.": "ليس رقمك؟ اضغط هنا لاختيار رقم آخر.",
  "All your documents were uploaded successfully.": "تم رفع جميع المستندات الخاصة بك بنجاح.",
  "Audio Call Consultation Price": "سعر الاستشارة عبر مكالمة صوتية",
  "Clinic Visit Consultation Price": "سعر الاستشارة عبر زيارة العيادة",
  "Home Visit Consultation Price": "سعر الاستشارة عبر الزيارة المنزلية",
  "Video Call Consultation Price": "سعر الاستشارة عبر مكالمة فيديو",
  "Your credit card will be temporarily charged for a hold amount of":
    "سيتم تحصيل رسوم من بطاقتك الائتمانية مؤقتاً وذلك عبر مبلغ معلق قدره",
  "If any copayment will be subject to your insurance coverage. The final amount will be charged only after the clinic calls to inform you about the fee.":
    "إذا كان هناك أي عملية دفع مشتركة ستخضع لتغطية التأمين الخاصة بك. فسيتم تحصيل المبلغ النهائي فقط بعد أن تتحدث اليك العيادة هاتفياً لإبلاغك بالرسوم.",
  "Show next availability": "إظهار المواعيد المتاحة التالية",
  "Send PIN": "إرسال رقم التعريف الشخصي",
  "Enter your PIN": "يرجى إدخال الرمز",
  "Add your number": "أضف رقمك",
  Pin: "رمز التأكيد",
  "The doctor will visit you at your home.": "سيقوم الطبيب بزيارتك في منزلك.",
  "I agree to the": "أنا أوافق على",
  terms: "الشروط",
  "&": "و",
  "privacy agreements": "اتفاقيات الخصوصية",
  "Please agree to the terms & privacy agreements": "أوافق على الشروط واتفاقيات الخصوصية",
  "Consultation price": "سعر الاستشارة",
  "Starting Price": "السعر المبدئي",
  "Please expect a call back from one of our doctors after Eid Al-Fitr holidays.":
    "يرجى توقع مكالمة هاتفية من أحدى أطبائنا بعد عطلة عيد الفطر.",
  "*Due to Eid Al-Fitr holidays, this service will resume on May 31.":
    "* نظراً لعطلة عيد الفطر ، سيتم اعادة هذه الخدمة في ٣١ مايو.",
  "Consult with doctors online from the comfort of your home.": "استشر أطباء عبر الانترنت وانت مستريح في منزلك.",
  "Video Visits": "زيارات عبر الفيديو",
  NEW: "جديد",
  "Search Doctors": "بحث عن الأطباء",
  "Doctors now offer video visit through HeliumDoc. Look for the video badge below.":
    "الآن، يوفر الأطباء زيارة عبر الفيديو من خلال هيليوم دوك. ابحث عن شارة الفيديو أدناه.",
  "Search Video Visits": "ابحث عن زيارات عبر الفيديو",
  "Offers Video Visits": "زيارة عبر الفيديو متاحة",
  "Offers video visits only": "يقدم زيارات الفيديو فقط",
  "Price Summary": "ملخص السعر",
  Apply: "تطبيق",
  "Search Country": "البحث عن البلد",
  "Country not found": "لم يتم العثور على البلد",
  Applied: "تم تطبيقها",
  "Original Price": "السعر الأصلي",
  Total: "الإجمالي",
  "Promo Code": "كود الخصم",
  "up to": "يصل الى",
  Discount: "تخفيض",
  "Not offered by doctor": "غير متاح لدى هذا الطبيب",
  Okay: "موافق",
  "Please call": "يرجى الاتصال بـ",
  "to reschedule your appointment.": "لإعادة جدولة موعدك.",
  "We want to hear from you!": "نريد أن نسمع منك!",
  "Write your review here": "اكتب رأيك هنا",
  5: "5",
  "Please rate your experience": "يرجى تقييم تجربتك",
  "How was the video quality?": "كيف كانت جودة الفيديو؟",
  "How was the audio quality?": "كيف كانت جودة الصوت؟",
  "How was the overall experience?": "كيف كانت التجربة ككل؟",
  Rate: "قيّم",
  "Rating submitted successfully!": "تم إرسال التقييم بنجاح!",
  "Back to HeliumDoc": "الرجوع الى هيليوم دوك",
  "Thank you for your feedback": "شكرا لك على رأيك",
  "Your review has been submitted successfully": "تم تقديم تعليقك بنجاح",
  "No amount will be charged until the clinic calls you to confirm your appointment.":
    "لن يتم تحصيل أي مبلغ الا بعد أن تتصل بك العيادة لتأكيد موعدك.",
  "The consultation fee is an indicative rate added by the clinic. Charges are subject to your insurance coverage if applicable.":
    "رسوم الاستشارة هي سعر إرشادي تم إضافته من خلال العيادة. تخضع الرسوم لتغطية التأمين الخاصة بك إن وجدت.",
  remove: "حذف",
  "MAIN INFO": "المعلومات الأساسية",
  "EXTRA INFO": "معلومات اضافية",
  "Save Changes": "حفظ التعديلات",
  "Date of Birth": "تاريخ الميلاد",
  "Uploaded Documents": "رفع الملفات",
  "ID/Passport": "بطاقة الهوية/جواز السفر",
  INSURANCE: "التأمين",
  "No ID uploaded yet": "لم يتم تحميل أي بطاقة هوية حتى الآن",
  "No insurance uploaded yet": "لم يتم تحميل أي تأمين حتى الآن",
  "No record uploaded yet": "لم يتم تحميل أي سجل حتى الآن",
  "Upload Another": "رفع ملف آخر",
  "Stay Safe!": "ابق آمنًا!",
  Prescriptions: "الوصفات الطبية",
  prescriptions: "الوصفات الطبية",
  "Uploaded by doctors after appointments": "تم تحميلها من خلال الأطباء بعد المواعيد",
  "Insurance, ID/Passport, Records": "التأمين ، الهوية / جواز السفر ، السجلات",
  "Profile Edited Successfully": "تم تعديل الحساب الشخصي بنجاح",
  Edit: "تعديل",
  "Name is required": "الاسم مطلوب",
  "Mobile number successfully changed": "تم تغيير رقم الهاتف المحمول بنجاح",
  "Skip for now": "تخطي الآن",
  "Old Password": "كلمة السر القديمة",
  "New Password": "كلمة السر الجديدة",
  "Confirm New Password": "تأكيد كلمة السر الجديدة",
  "Confirm new password is required": "تأكيد كلمة السر الجديدة مطلوب",
  "Old Password is required": "كلمة السر القديمة مطلوبة",
  "Enter your Old Password": "ادخل كلمة السر القديمة",
  "Enter your New Password": "ادخل كلمة السر الجديدة",
  "Change Password": "تغيير كلمة السر",
  "Date of birth is required": "تاريخ الميلاد مطلوب",
  "View Prescriptions": "عرض الوصفات الطبية",
  "Download All": "تحميل الكل",
  Change: "تغيير",
  "Manage your appointments": "إدارة مواعيدك",
  "No Prescriptions Yet": "لا وصفات طبية حتى الآن",
  "You will find doctor prescriptions here after your appointments": "ستجد وصفات الطبيب هنا بعد الانتهاء من مواعيدك",
  "Edit Profile": "تعديل الحساب الشخصي",
  "Change Mobile Number": "تعديل رقم الهاتف المحمول",
  Sex: "الجنس",
  "New password is required": "كلمة السر الجديدة مطلوبة",
  "View or edit your information": "عرض أو تعديل معلوماتك",
  "Current and new mobile number are same!": "رقم الهاتف المحمول الحالي والجديد متطابق!",
  "Gender is required": "تحديد الجنس مطلوب",
  RECORDS: "السجلات",
  Download: "تحميل",
  "Email address is not valid": "البريد الإلكتروني غير صالح",
  "Add Email": "إضافة بريد الكتروني",
  "Email Added Successfully": "تمت إضافة البريد الإلكتروني بنجاح",
  "Email Already Added": "تمت إضافة البريد الإلكتروني بالفعل",
  "Add Password": "إضافة كلمة مرور",
  "No email added yet": "لم تتم إضافة بريد إلكتروني حتى الآن",
  "Password Added Successfully": "تمت إضافة كلمة المرور بنجاح",
  "You have to sign in to be able to view your profile.": "يجب عليك تسجيل الدخول لتتمكن من عرض حسابك الشخصي.",
  "Enter Patient Full Name": "أدخل اسم المريض بالكامل",
  "Dashboard for HeliumDoc Staff": "لوحة التحكم لطاقم هيليوم دوك",
  "Reports & Analytics on your doctors": "التقارير و التحليلات لاطباءك",
  "Send SMS to patients for reviews": "ارسل رسالة نصية قصيرة للمرضى للمراجعات",
  "List of patient bookings details": "قائمة  تفاصيل حجوزات المرضى",
  "Confirm patient booking requests": "تأكيد حجوزات المرضى",
  "List of patient reviews on your doctors": "قائمة المراجعات لاطباءك",
  "Appointments, Calendar, Patient List": "المواعيد, التقويم, قائمة المرضى",
  "Patient Bills": "فواتير المرضى",
  "Medical Report": "تقرير طبي",
  "No Medical Reports uploaded yet. Store all your medical reports here.":
    "لم يتم تحميل أي تقارير طبية حتى الآن. قم بتحميل جميع تقاريرك الطبية هنا.",
  "Medical Reports": "تقارير طبية",
  "Upload Medical Reports": "رفع تقارير طبية",
  "Medical Jobs in UAE": "وظائف طبية في الإمارات",
  "Medical Jobs in Qatar": "وظائف طبية في قطر",
  "Add your Mobile Number": "أضف رقم هاتفك المحمول",
  "Find Clinics by": "البحث عن العيادات حسب",
  "Find Doctors by": "البحث عن الأطباء حسب",
  Doha: "الدوحة",
  "How can I find the best": "كيف يمكنني العثور على أفضل",
  "You can filter your specialists based on location, treatment, language, insurance, popularity and gender. This helps you find the doctor best suited to your needs in":
    "يمكنك تصفية الأطباء بناءاً على الموقع والعلاج المتاح واللغة والتأمين والشهرة والجنس. يساعدك هذا في العثور على الطبيب الأنسب لاحتياجاتك في",
  "You can also read the ratings and reviews against each doctor.":
    "يمكنك أيضًا قراءة التقييمات والتعليقات لكل طبيب منهم.",
  "How can I find a doctor who speaks my language?": "كيف يمكنني العثور على طبيب يتحدث لغتي؟",
  "When you search for": "عندما تبحث عن",
  ", you will be able to see Filters on the right. The last filter is Language. You can choose the language of your liking and the doctors that speak it will be displayed.":
    "، ستتمكن من رؤية عوامل التصفية على اليسار. التصفية الأخيرة هي اللغة. يمكنك اختيار اللغة التي ترغب بها وسيتم عرض الأطباء الذين يتحدثون بها.",
  "How can I make an appointment with instant confirmation?": "كيف يمكنني تحديد موعد مع التأكيد الفوري؟",
  "On some": "في بعض بطاقات",
  "cards, you will find a blue label “Available at a certain time”. Doctors with these buttons are available for instant confirmation and require no confirmation call.":
    "، ستجد علامة زرقاء مكتوب عليه ”متاح في وقت معين“. الأطباء الذين لديهم هذه الأزرار متاحون للتأكيد الفوري ولا يحتاجون إلى مكالمة تأكيد.",
  "How can I book an appointment online with a": "كيف يمكنني حجز موعد على الانترنت مع",
  "To book an appointment with HeliumDoc follow these steps:": "لحجز موعد مع هيليوم دوك اتبع الخطوات التالية:",
  "1. On the HeliumDoc homepage search for the treatment or doctor by typing in the search bar. You can also search by choosing a speciality and location In":
    "1. في صفحة هيليوم دوك الرئيسية، ابحث عن العلاج أو الطبيب عن طريق الكتابة في شريط البحث. يمكنك أيضًا البحث عن طريق اختيار التخصص والمنطقة في",
  "2. After filtering to find the doctor best suited to your needs, you can click the ‘Book Now’ or ‘Available at a certain time’ button on the doctor card.":
    "2. بعد التصفية للعثور على الطبيب الأنسب لاحتياجاتك، يمكنك الضغط فوق الزر ”احجز الآن“ أو ”متاح في وقت معين“ على بطاقة الطبيب.",
  "3. If that doctor is offering a video visit, you will be able to choose between ‘Video visit’ and ‘Clinic visit.’":
    "3. إذا كان هذا الطبيب يوفر زيارة عبر الفيديو، فستتمكن من الاختيار بين ”زيارة الفيديو“ و ”زيارة العيادة“.",
  "How can I find a doctor who takes my insurance?": "كيف يمكنني العثور على طبيب يقبل تأميني؟",
  "HeliumDoc lets you search specifically for": "يتيح لك هيليوم دوك البحث تحديدًا عن",
  "who accept your insurance in": "الذين يقبلون تأمينك في",
  "After searching for a speciality or doctor, just choose your insurance from the Insurance dropdown at the top of the page.":
    "بعد البحث عن تخصص أو طبيب، ما عليك سوى اختيار التأمين الخاص بك من قائمة التأمين المنسدلة في أعلى الصفحة.",
  "How can I book a video visit with a": "كيف يمكنني حجز زيارة عبر الفيديو مع",
  "Select the Video Visits option on the right side of the page. After choosing the doctor of your choice, click on Book Now and when asked the consultation type, choose video visit. After filling the form, make the online payment.":
    "حدد خيار زيارات عبر الفيديو على الجانب الأيسر من الصفحة. بعد اختيار الطبيب، إضغط على إحجز الآن وعند سؤالك عن نوع الاستشارة، اختر زيارة الفيديو. بعد ملء استمارة الحجز، قم بالدفع عبر الإنترنت.",
  "How can I find a top-rated": "كيف يمكنني الاعتماد على تقييم مرضى آخرين للعثور على أفضل",
  "You can go to the doctor’s profile to view the ratings and reviews for that doctor. These have been left by patients who have consulted the specialist. You can also filter the doctors by choosing ‘Most Recommended’ or ‘Most Popular’ to see the top doctors in":
    "يمكنك الذهاب إلى الملف الشخصي للطبيب لعرض التقييمات والتعليقات لهذا الطبيب. هذه التقييمات والتعليقات تم نشرها عن طريق مرضى آخرون قاموا بزيارة هذا الطبيب. يمكنك أيضًا تصفية الأطباء عن طريق اختيار ”الأكثر توصية“ أو ”لأكثر شهرة“ لرؤية أفضل الأطباء في",
  "Are video visits with": "هل زيارات الفيديو مع",
  "covered by my insurance?": "مشمولة بالتأمين الخاص بي؟",
  "Most insurers in": "توفر معظم شركات التأمين في",
  "provide coverage for video visits at the same cost as clinic visits. You can search on HeliumDoc specifically for":
    "تغطية لزيارات عبر الفيديو بنفس تكلفة زيارات العيادة. يمكنك البحث على هيليوم دوك تحديدًا عن",
  "who accept your insurance for video visits by selecting your insurance company from the drop-down menu at the top of the page.":
    "يقبلون تأمينك لزيارات الفيديو عن طريق اختيار شركة التأمين الخاصة بك من القائمة المنسدلة في أعلى الصفحة.",
  "Can I cancel an appointment?": "هل يمكنني إلغاء موعد؟",
  "For appointments paid for online, you can cancel or reschedule up to two hours before the scheduled time of the appointment. An appointment cannot be rescheduled or cancelled after the given time frame. After cancelling the appointment, you can schedule another appointment or ask for a refund. The refund is subject to a 5% payment gateway fee. If for some reason you do not attend the appointment, the appointment will be marked as a no-show and you will not be entitled to a refund.":
    "بالنسبة إلى المواعيد المدفوعة عبر الإنترنت، يمكنك إلغاء أو إعادة جدولة ما يصل إلى ساعتين قبل الوقت المحدد للموعد. لا يمكن إعادة جدولة موعد أو إلغاؤه بعد الإطار الزمني المحدد. بعد إلغاء الموعد، يمكنك تحديد موعد آخر أو طلب استرداد. يخضع رد الأموال لرسوم بوابة الدفع بنسبة 5٪. إذا لم تحضر الموعد لسبب ما، فسيتم تحديد الموعد على أنه عدم حضور ولن يحق لك استرداد المبلغ.",
  "How can I make an appointment with": "كيف يمكنني تحديد موعد مع",
  "HeliumDoc allows you to book appointments online without the hassle of calling the clinic.":
    "يسمح لك هيليوم دوك بحجز المواعيد عبر الإنترنت دون عناء الاتصال بالعيادة.",
  "Click here": "اضغط هنا ",
  "to book an appointment online with": "لحجز موعد عبر الإنترنت مع",
  "You will get a call back from the clinic to confirm your appointment.": "ستصلك مكالمة من العيادة لتأكيد موعدك.",
  Does: "هل يقبل",
  "accept my insurance?": "التأمين الخاص بي؟",
  "to see a list of insurance plans he accepts.": "للاطلاع على قائمة خطط التأمين التي يقبلها.",
  "How much does": "كم يتقاضى",
  "charge & how do I pay?": "وكيف لي أن أدفع؟",
  "Where is": "أين تقع عيادة",
  "clinic located in": "في",
  "works at:": "يعمل هذا الطبيب في",
  "for the google map location.": "للحصول على رابط للموقع عبر خرائط جوجل.",
  "What is the education and qualifications of": "ما هو تعليم ومؤهلات",
  "has the following degrees and diplomas:": "حاصل على الدرجات والدبلومات العلمية التالية:",
  "Are there any additional charges to book an appointment with a": "هل هناك أي رسوم إضافية لحجز موعد مع",
  "through HeliumDoc?": "من خلال هيليوم دوك؟",
  "HeliumDoc bridges the gap between the patient and the doctor. There are no additional charges for booking through HeliumDoc.":
    "يعمل هيليوم دوك على إيصال المريض بالطبيب بطريقة فعالة وسهلة. لا توجد أي رسوم إضافية للحجز من خلال هيليوم دوك.",
  charges: "يتقاضى رسوم قدرها",
  "for consultation for cash patients. You will pay at the reception when you visit the doctor. There are no additional charges when you book through HeliumDoc.":
    "للاستشارة. يمكنك الدفع في مكتب الاستقبال عند زيارة الطبيب. لا توجد رسوم إضافية عند الحجز من خلال هيليوم دوك.",
  "What timings for": "ما هي مواعيد",
  "are available?": "المتاحة؟",
  "to request an appointment with the doctor and the clinic will call to share the doctor’s schedule.":
    "لطلب موعد مع الطبيب وستتصل بك العيادة للاتفاق على موعد يناسبك.",
  "What languages does": "ما هي اللغات التي يتحدث",
  "speak?": "بها؟",
  He: "هو",
  She: "هي",
  "What has the experience of patients been with": "ما هي تجربة المرضى السابقين مع",
  "speaks the following": "يتحدث اللغات التالية ",
  "What are common reasons for patients to see": "ما الأسباب الشائعة التي تدفع المرضى إلى زيارة",
  "frequently sees patients for": "كثيرًا ما يستقبل المرضى لإجراء",
  "reviews have been added against": "تعليق تمت اضافتهم من خلال مرضى",
  "profile and his average rating is": "ومتوسط ​​تقييمه هو",
  "How do I know what are the available specialties at": "كيف لي أن أتعرف على التخصصات المتوفرة في",
  "When you search for a clinic, the specialities available are displayed under the Doctor tab.":
    "عند البحث عن عيادة، يتم عرض التخصصات المتاحة تحت علامة تبويب أطباء.",
  "Does the": "هل تقبل",
  FAQs: "الأسئلة الشائعة",
  "to see a list of insurance plans supported at": "لمشاهدة قائمة بخطط التأمين المدعومة في",
  "Are there any additional charges to book an appointment with a clinic through HeliumDoc?":
    "هل توجد رسوم إضافية لحجز موعد مع عيادة من خلال هيليوم دوك؟",
  "There are no additional charges when you book through HeliumDoc.":
    "لا توجد رسوم إضافية عند الحجز من خلال HeliumDoc.",
  "How do I know what are the available treatments at": "كيف لي معرفة ما هي العلاجات المتاحة في",
  "When you search for a clinic, go to the Treatments tab to list the treatments being offered at that clinic.":
    "عندما تبحث عن عيادة، انتقل إلى علامة التبويب ”العلاجات“ لسرد العلاجات التي يتم تقديمها في تلك العيادة.",
  "located in": "في منطقة",
  "What are the consultation charges of doctors in": "ما هي رسوم استشارة الأطباء في",
  "The consultation for doctors in": "استشارة الأطباء في",
  doctors: "الأطباء",
  DOCTORS: "الأطباء",
  "ranges from": "تتراوح من",
  "for cash patients. You will pay at the reception when you visit the doctor.":
    "يمكنك الدفع في مكتب الاستقبال عند زيارة الطبيب.",
  is: "من",
  "How can I find the best clinic in": "كيف يمكنني العثور على أفضل عيادة في",
  "Popular clinics in": "العيادات المشهورة في",
  "have a rating against their names. These ratings are a compilation of patient reviews for the doctors who serve in that specific clinic.":
    "لديها تقييم مرتبط بأسمائها. هذه التقييمات عبارة عن تجميع لتعليقات وتقييمات المرضى للأطباء الذين يخدمون في تلك العيادة المحددة.",
  "How can I book an appointment?": "كيف يمكنني حجز موعد؟",
  "You can search for the clinic in the search bar and book with the desired doctor. You can also call us at":
    "يمكنك البحث عن العيادة في شريط البحث والحجز مع الطبيب المطلوب. يمكنك أيضًا الاتصال بنا على",
  "from 9AM to 5PM to book your appointment.": "من 9 صباحًا إلى 5 مساءً لحجز موعدك.",
  "How can I find a clinic nearest to me?": "كيف لي أن أجد أقرب عيادة لي؟",
  "Click on the “Filters” option under the Clinics tab. Then click on City and select your desired area within the chosen city. You will be redirected to a list of clinics located nearest to you.":
    "اذهب الى أعلى الصفحة بعد الوصول للتخصص الذي تريده. ثم انقر فوق المدينة وحدد المنطقة التي تريدها داخل المدينة المختارة. ستتم إعادة توجيهك إلى قائمة العيادات الأقرب إليك.",
  "How can I find a clinic who accepts my insurance?": "كيف لي أن أجد عيادة تقبل التأمين الخاص بي؟",
  "Click on the “Filters” option under the Clinics tab. Then click on Insurance and select your desired insurance. You will be redirected to a list of clinics that accept the chosen insurance.":
    "اذهب الى أعلى الصفحة بعد الوصول للتخصص الذي تريده. ثم اضغط فوق التأمين وحدد التأمين الذي تريده. ستتم إعادة توجيهك إلى قائمة العيادات التي تقبل التأمين المختار.",
  "Please specify reason": "يرجى تحديد السبب",
  "Edit Review": "تعديل التعليق",
  "Delete Review": "حذف التعليق",
  Delete: "حذف",
  "Delete Review?": "حذف التعليق؟",
  "Are you sure you want to delete this review?": "هل أنت متأكد انك تريد حذف هذا التعليق؟",
  "Contact Numbers": "ارقام التواصل",
  "Doctor Works at Multiple Clinics": "يعمل الطبيب في عيادات متعددة",
  "Insurance Providers": "شركات تأمين",
  "Filter companies or plans": "تصفية الشركات أو الخطط",
  "Show Less": "عرض أقل",
  "Show More": "عرض أكثر",
  "No plans to show": "لا يوجد خطط لعرضها",
  "Your search term matches with no company or plan": "لا يتطابق بحثك مع أي شركة او خطة",
  "Have you visited this clinic before?": "هل زرت هذه العيادة من قبل؟",
  "What's Next": "ماذا بعد؟",
  "You have submitted your booking": "لقد قمت بإتمام الحجز الخاص بك",
  Booking: "الحجز",
  Confirmation: "تأكيد",
  "Your booking is confirmed by": "تم تأكيد موعدك من قبل",
  "will contact you shortly to confirm your booking": "ستتصل بك قريبًا لتأكيد حجزك",
  Visit: "قم بزيارة",
  See: "بإمكانك زيارة",
  "after appointment confirmation.": "بعد تأكيد الموعد.",
  "Your response has been saved successfully": "لقد تم حفظ ردك بنجاح",
  "Pay at Clinic": "ادفع في العيادة",
  "Pay Online Now": "ادفع عبر الإنترنت الآن",
  "Cannot pay online just yet": "لا يمكن الدفع عبر الإنترنت بعد",
  "We are working hard to give you the option to pay online.": "نحن نعمل بجد لنمنحك خيار الدفع عبر الإنترنت.",
  "As of now you are kindly requested to pay at the clinic.": "اعتباراً من الآن يُطلب منك التفضل بالدفع في العيادة.",
  Ok: "حسنا",
  "Loading Doctors": "جاري تحميل الأطباء...",
  "Feedback Submitted": "تم إرسال التعليق",
  "Your feedback has already been submitted!": "تم إرسال تعليقك بالفعل!",
  "Feedback has been successfully submitted!": "تم إرسال التعليق بالفعل!",
  plans: "خطط",
  "Insurance Plans": "خطط التأمين",
  "Other Insurance Companies": "خطط التأمين",
  "No insurance companies to show": "لا توجد شركات تأمين لعرضها",
  clinics: "عيادات",
  CLINICS: "العيادات",
  "We are working hard to give you the option to pay online in installments.":
    "نحن نعمل بجد لتوفير خيار الدفع بالتقسيط عبر الانترنت قريباً.",
  "Pay Online in Installments": "الدفع بالتقسيط عبر الانترنت",
  "Cannot Pay in Installments Just Yet": "لا يمكن الدفع على أقساط بعد",
  "Preferred Booking Time": "وقت الحجز المفضل",
  "Choose when you would like to see your doctor and we'll do our best to make it happen":
    "اختر الوقت الذي ترغب في رؤية طبيبك فيه وسنبذل قصارى جهدنا لتحقيق ذلك",
  Day: "اليوم",
  "Within 24 Hours": "خلال 24 ساعة",
  "Within 48 Hours": "خلال 48 ساعة",
  Later: "لاحقاً",
  Time: "الوقت",
  Morning: "الصباح",
  Afternoon: "بعد الظهيرة",
  Evening: "المساء",
  "Appointment Cancelled": "تم إلغاء الموعد",
  "Your appointment has been cancelled. Thank you for your feedback!": "تم إلغاء موعدك. شكرا لك على تعليقك!",
  "Cannot Cancel": "لا يمكن الإلغاء",
  "You can no longer cancel this appointment because its time has already passed":
    "لم يعد بإمكانك إلغاء هذا الموعد لأن وقته قد مضى بالفعل",
  "Help us serve you better": "ساعدنا في خدمتك بشكل أفضل",
  "Please let us know why you’re cancelling so we can server you better next time":
    "يرجى إخبارنا بسبب قيامك بالإلغاء حتى نتمكن من خدمتك بشكل أفضل في المرة القادمة",
  "* Please choose a reason for cancellation.": "* الرجاء اختيار سبب الإلغاء.",
  "Cancel Appointment": "إلغاء الموعد",
  "Are you sure you want to cancel your appointment?": "هل أنت متأكد أنك تريد إلغاء موعدك؟",
  "You will not get a refund.": "لن تتمكن من استرداد أموالك.",
  "Your free discount will no longer be valid.": "خصمك المجاني لن يصبح ساريًا.",
  "A refund will not be made if you have already paid.": "إذا كنت قد دفعت بالفعل فلن يتم رد المبلغ.",
  "Clinics List": "قائمة العيادات",
  "Doctors List": "قائمة الأطباء",
  "covered by": "مغطى بواسطة",
  Insurance: "للتأمين",
  "Book Appointment with": "حجز موعد",
  speaking: "يتحدث",
  "prices in": "اسعار في",
  "Please fill out complete card details.": "يرجى ملء تفاصيل البطاقة بالكامل.",
  "Your card number is incomplete.": "رقم بطاقتك غير مكتمل.",
  "Your card number is invalid.": "رقم بطاقتك غير صالح.",
  "Your card's expiration date is incomplete.": "تاريخ انتهاء صلاحية بطاقتك غير مكتمل.",
  "Your card's expiration year is in the past.": "سنة انتهاء صلاحية بطاقتك في الماضي.",
  "Your card's security code is incomplete.": "رمز أمان بطاقتك غير مكتمل.",
  "No-Show Fee": "رسوم عدم الحضور",
  "Booking with HeliumDoc is": "الحجز مع هيليوم دوك",
  Secure: "آمن",
  "Free.": "مجاني.",
  "If you miss your appointment, a no-show fee of AED 50 will be deducted.":
    "إذا فاتكم موعدكم ، فسيتم خصم رسوم عدم الحضور بقيمة ٥٠ درهم.",
  "If you choose to": "إذا اخترتم",
  cancel: "إلغاء",
  reschedule: "إعادة تحديد",
  "your appointment 12 hours prior to the booking time, you": "موعدكم قبل ١٢ ساعة من وقت الحجز ،",
  "will not be charged.": "will not be charged.",
  "فلن يتم تحصيل الرسوم": "فلن يتم تحصيل الرسوم",
  منكم: ".منكم.",
  "We understand that emergencies happen.": "نحن نتفهم أن حالات الطوارئ تحدث.",
  "Best Discounts in": "أفضل الخصومات في",
  "Best Savers": "أفضل الخصومات",
  "See all": "عرض الجميع",
  "Get Discount": "احصل على الخصم",
  save: "وفر",
  "Expiring Soon": "خصومات تنتهي قريباً",
  "Discount Categories": "أقسام الخصومات",
  "Discount Category": "فئة الخصم",
  "All Categories": "جميع الفئات",
  "Search Discount Categories": "إبحث فى أقسام الخصومات",
  Recommended: "الأكثر توصية",
  "Recommended Discounts": "الخصومات الأكثر توصية",
  Discount: "خصم",
  // "Discounts": "خصومات",
  "Discounts By Area": "خصومات حسب المنطقة",
  "Expires in 3 days": "ينتهي خلال 3 أيام",
  "Expires in 2 days": "ينتهي خلال يومين",
  "Last Day": "اليوم الآخير",
  "Expires on": "ينتهي في",
  "Expires in 24 hours": "ينتهي خلال 24 ساعة",
  "Best Savers in": "أفضل الخصومات في",
  "Choose Area": "اختر منطقة",
  "No Discounts Now": "لا يوجد خصومات الآن",
  "We are working hard to bring you new discounts. Please check later.":
    "نحن نعمل بأقصى جهدنا لنقدم لك خصومات جديدة. يرجى التحقق لاحقًا.",
  "Go Back": "العودة للخلف",
  "Expiring Soon Discounts": "خصومات تنتهي قريباً",
  "Discount offered by": "الخصم مقدم من",
  "Choose a Doctor to Continue": "اختر طبيب للمتابعة",
  "Read Reviews": "قراءة التعليقات",
  "you save": "وفرت",
  "Discount Details": "تفاصيل الخصم",
  TERMS: "الشروط",
  DESCRIPTION: "الوصف",
  "Necessary precautions and safety measures must be strictly adhered to safeguard public health. Cosmetic procedures should be sought for therapeutic or reconstructive reasons only.":
    "يجب التقيد الصارم بالاحتياطات وتدابير السلامة اللازمة لحماية الصحة العامة. كما يجب البحث عن إجراءات التجميل لأسباب علاجية أو ترميمية فقط.",
  "This advertisement permission was granted by MOH based on the professional /activity licenses for both advertising facility and its healthcare providers who should deliver the service / products in accordance with the regulations and the standards set by the licensing authority. MOH is ABSOLUTELY NOT part of this deal or any of its terms.":
    "تم منح الإذن بنشر هذا الإعلان من قبل وزارة الصحة بناءاً على التراخيص المهنية/المتعلقة بالنشاط لكل من المنشأة الإعلانية ومقدمي الرعاية الصحية الذين يجب عليهم تقديم الخدمة/المنتجات وفقًا للوائح والمعايير التي تحددها سلطة الترخيص. إن وزارة الصحة ليست بشكل مطلق جزءًا من هذا العرض أو أياً من شروطه.",
  "Buying the deal is exclusively the responsibility of the consumer. This ad is approved by":
    "شراء العرض هو مسؤولية المستهلك بشكل حصري. تمت الموافقة على هذا الإعلان من قبل وزارة الصحة. رقم الترخيص",
  Reviews: "تعليقات المرضى",
  "How it Works": "كيف يعمل؟",
  "Select a doctor": "اختر طبيباً",
  "Book the discount and pay online": "احجز الخصم وادفع عبر اللإنترنت",
  "The clinic will confirm your appointment": "ستؤكد العيادة موعدك",
  "Visit the clinic": "قم بزيارة العيادة",
  Next: "التالي",
  "Choose Doctor": "اختر طبيباً",
  "View on Google Maps": "اعرض على خرائط جوجل",
  Reviews: "تعليقات المرضى",
  "Best Saver Discounts": "أفضل الخصومات",
  "Or search other areas": "أو ابحث في مناطق أخرى",
  "Link copied. Now you can share it with a friend!": "تم نسخ الرابط. الآن يمكنك مشاركتها مع صديق!",
  "To avail this offer is solely the responsibility of the client and this advertisement has been approved by":
    "الاستفادة من هذا العرض هي مسؤولية العميل وحده وقد تمت الموافقة على هذا الإعلان من قبل",
  "The permit to advertise has been given by the": "تم منح تصريح الإعلان من قبل",
  "considering the advertisement and the healthcare facility should deliver whilst abiding by the rules set by the authorities. It should be noted that":
    "ويجب على منشأة الرعاية الصحية الالتزام بالقواعد التي وضعتها السلطات. وتجدر الإشارة إلى أن",
  "is not involved in the campaign.": "ليست جزءاً من هذه الحملة.",
  "All preventive measures must be taken to protect public health. Individuals should undergo cosmetic treatments for the purpose of therapy, to boost self-confidence or for reconstructive reasons as advised by professionals.":
    "يجب اتخاذ جميع التدابير الوقائية لحماية الصحة العامة. حيث يجب أن يخضع الأفراد للعلاجات التجميلية إما لغرض العلاج ، أو لتعزيز الثقة بالنفس أو لأسباب ترميمية كما ينصح به المتخصصون.",
  "No reviews yet.": "لا توجد تعليقات حتى الآن.",
  "Show Less Categories": "عرض أقساماً اقل",
  "Show More Categories": "عرض المزيد من الأقسام",
  "Expiring Soon Discounts": "خصومات تنتهي قريباً",
  "License No.": " رقم الترخيص",
  "Doctors now offer video visit through HeliumDoc. Look for the video badge below":
    "الآن، يوفر الأطباء زيارة عبر الفيديو من خلال هيليوم دوك. ابحث عن شارة الفيديو أدناه.",
  License: "رخصة",
  "Password Changed Successfully": "تم تغيير كلمة السر بنجاح",
  "New password does not match!": "كلمة السر غير متطابقة",
  "Price starting from": "السعر يبدأ من",
  "No password added yet": "لم تتم إضافة كلمة مرور حتى الآن",
  "Discounts in": "الخصومات في",
  // "Best": "افضل",
  // "Discounts": "خصومات",
  in: "في",
  "What medical insurance plans are available in": "ما هي خطط التأمين الطبي المتوفرة في",
  "The medical insurance plans available in": "خطط التأمين الطبي المتوفرة في",
  are: "هي",
  "What are the medical specialties and the list of doctors covered by":
    "ما هي التخصصات الطبية وقائمة الأطباء التي يشملها تأمين",
  "To know the medical specialties and the list of doctors covered by":
    "لمعرفة التخصصات الطبية وقائمة الأطباء التي يشملها تأمين",
  "go to the top of the page and browse the list of doctors covered by insurance":
    "توجه الى أعلى الصفحة وتصفح القائمة الأطباء التى يغطيها تأمين",
  "What hospitals are covered by": "ما هي المستشفيات التي يشملها تأمين",
  "You can go to the top of the page and you will find a list of hospitals covered by":
    "توجه الى أعلى الصفحة وستجد قائمة المستشفيات التي يشملها تأمين",
  "below the list of doctors covered by": "أسفل قائمة الأطباء التى يغطيها تأمين",
  Does: "هل تأمين",
  "insurance cover orthodontics": "يشمل تقويم الأسنان",
  "Orthodontic coverage in": "تغطية تقويم الأسنان في تأمين",
  "depends on your insurance plan with the insurance company, because insurance coverage varies according to the medical insurance plans of":
    "يعتمد على خطة التأمين الخاصة بك لدى شركة التأمين، حيث تختلف تغطية التأمين حسب خطط التأمين الطبي لشركة",
  "Is pregnancy covered by": "يشمل الحمل والولادة",
  insurance: "هل تأمين",
  "Pregnancy coverage in": "تغطية الحمل والولادة في تأمين",
  "Is laser eye surgery covered by": "يشمل جراحة النظر بالليزر",
  "laser eye surgery coverage in": "تغطية علاج النظر بالليزر في تأمين",
  "Is breast cancer treatment covered by": "يشمل علاج سرطان الثدي",
  "Breast cancer treatment coverage in": "تغطية علاج سرطان الثدي في تأمين",
  "Load More Clinics": "تحميل المزيد",
  "Load More Doctors": "تحميل المزيد",
  "Visit Type": "نوع الزيارة",
  "Video Only": "فيديو فقط",
  "Clinic Only": "عيادة فقط",
  "Clinic and Video": "عيادة وفيديو",
  "More Filters": "المزيد من التصفيات",
  "Apply Filters": "تطبيق التصفيات",
  "Offers clinic & video visits": "يقدم زيارات العيادة والفيديو",
  "Any Plan": "أي خطة",
  Buy1Get1Free: "اشتر 1 واحصل على 1 مجانا",
  "Buy 1 Get 1 Free": "اشترِ 1 واحصل على 1 مجانًا",
  "Buy 1 Get 1 Free!": "اشترِ 1 واحصل على 1 مجانًا!",
  "buy 1 get 1 for free!": "اشترِ 1 واحصل على 1 مجانًا!",
  "buy 1 and get 1 for free!": "اشترِ 1 واحصل على 1 مجانًا!",
  "you get 2 for the price of 1!": "احصل على ٢ بسعر ١!",
  "you get 2 for the price of 1": "احصل على ٢ بسعر ١",
  "2 for the price of 1": "٢ بسعر ١",
  "Book the Buy 1 Get 1 Free discount and pay online": "احجز خصم اشترِ 1 واحصل على 1 مجانًا وادفع عبر الإنترنت",
  "You get a link to share with someone to redeem the same discount for free":
    "ستحصل على رابط لمشاركته مع شخص آخر لاسترداد نفس الخصم مجانًا",
  "Visit the clinic and get your discount": "قم بزيارة العيادة واحصل على خصمك",
  "pending confirmation": "في انتظار التأكيد",
  "Copy Coupon Link": "انسخ رابط القسيمة",
  "you get this discount for FREE!": "أنت تحصل على هذا الخصم مجانا!",
  "you get it for FREE": "أنت تحصل عليه مجانًا",
  "The clinic will confirm your side of the appointment": "ستؤكد العيادة موعدك",
  "Link has been copied!": "تم نسخ الرابط!",
  "See Clinics": "تصفح العيادات",
  "Cancel My Appointment": "الغ موعدي",
  "Book the free discount": "احجز الخصم المجاني",
  "For Doctors": "إضافة طبيب",
  "Your card does not have sufficient balance. Please use a card with an adequate fund to complete the transaction.":
    "Your card does not have sufficient balance. Please use a card with an adequate fund to complete the transaction.",
  "This bank account cannot be used. This error occurs if the account is not supported or is not yet verified. Please try with a different bank account.":
    "This bank account cannot be used. This error occurs if the account is not supported or is not yet verified. Please try with a different bank account.",
  "Looks like this card has been declined too many times. Please try again in 24 hours. If the issue does not resolve, make sure that your card is supported and the information entered is correct.":
    "Looks like this card has been declined too many times. Please try again in 24 hours. If the issue does not resolve, make sure that your card is supported and the information entered is correct.",
  "Your card has been declined.": "Your card has been declined.",
  "Our services are not available in the country this card was issued.":
    "Our services are not available in the country this card was issued.",
  "This card has expired.": "This card has expired.",
  "Please check the card's security code.": "",
  "The card number is invalid. Please re-enter the card.": "The card number is invalid. Please re-enter the card.",
  "Incorrect postal code.": "Incorrect postal code.",
  "We are unable to authenticate your payment method.": "We are unable to authenticate your payment method.",
  "Your ZIP code is incomplete.": "Your ZIP code is incomplete.",

  "Clinic Confirmed Your Appointment": "أكدت العيادة موعدك",
  "Clinic Confirms Your Appointment": "ستؤكد العيادة موعدك",
  "will contact you shortly to confirm your walk-in appointment": "سوف تتصل بك قريباً لتأكيد موعدك",
  "I Want to Get the Discount": "أريد الحصول على الخصم",
  "Link has been copied!": "تم نسخ الرابط!",
  "buy 1 get 1 for free!": "اشترِ 1 واحصل على 1 مجانًا!",
  "buy 1 and get 1 for free!": "اشترِ 1 واحصل على 1 مجانًا!",
  "Redeem Discount for free": "احصل على الخصم مجانًا",
  "Visit the Clinic": "قم بزيارة العيادة",
  "only accepts walk-ins. After clinic confirms, you can visit the clinic at these times:":
    "لا يقبل إلا الزيارات بأسبقية الحضور. وبعد تأكيد العيادة، يمكنك زيارتها في هذه الأوقات:",
  "Thank you for booking through HeliumDoc": "شكراً لحجزك من خلال هيليوم دوك",
  "Thank you for booking an appointment through HeliumDoc": "شكراً لإستخدامكم هيليوم دوك لحجز مواعيدكم",
  "will contact you shortly to confirm the date and time of your appointment":
    "سوف تتصل بك قريبًا لتأكيد تاريخ ووقت موعدك",
  "You will get a discount coupon link to share with a friend or family member to redeem the free discount.":
    "ستحصل على رابط قسيمة الخصم لمشاركته مع صديقك أو أحد أفراد عائلتك ليتمكن بدوره من الحصول على الخصم المجاني.",
  "See how it works": "اعرف كيف يعمل",
  "Share the discount coupon link with a friend or family member to redeem the free discount.":
    "شارك رابط قسيمة الخصم مع صديق أو أحد أفراد عائلتك ليحصل على الخصم المجاني.",
  "Redeem your discount with": "احصل على خصمك مع",
  "You have booked and paid the discounted price": "تم الحجز و الدفع و تفعيل الخصم",
  "Book the Discount and Pay Online": "احجز الخصم وادفع عبر اللإنترنت",
  "Get Discount Coupon Link": "احصل على رابط قسيمة الخصم",
  "How Discount Coupons Work": "كيفية عمل قسائم الخصم",
  "Enter your payment details to buy the": "أدخل تفاصيل الدفع الخاصة بك لشراء",
  "Book and Redeem the Free Discount": "احجز واحصل على الخصم المجاني",
  "You have booked and redeemed your discount": "لقد حجزت وحصلت على خصمك",
  "Discount Coupon Link": "رابط قسيمة الخصم",
  "after appointment confirmation.": "بعد تأكيد الموعد.",
  "copy link": "نسخ الرابط",
  "Please select a clinic": "يرجى اختيار العيادة",
  "Select Your Clinic": "اختر العيادة",
  "This discount is not supported for this doctor": "هذا الخصم غير مدعوم لهذا الطبيب",
  Expired: "منتهية الصلاحية",
  "Your appointment has been rescheduled to": "تمت إعادة ترتيب موعدك إلى",
  "Discounted Price": "سعر مخفض",
  "Known For": "أبرز الإنجازات",
  "Start by entering your mobile number": "ابدأ بإدخال رقم الهاتف الخاص بك",
  "A pin code was sent to your mobile number": "تم إرسال رمز التأكيد إلى رقم الهاتف الخاص بك",
  "Resend pin": "إعادة إرسال رمز التأكيد",
  "Verify My Mobile Number": "التأكد من رقم الهاتف الخاص بي",
  "Change number": "تغيير رقم الهاتف",
  "Read our blog": "اقرأ مدونتنا",
  "Read the latest healthcare articles and doctor opinions": "اطلع على أحدث المقالات عن الرعاية الصحية وآراء الأطباء",
  "Our Blog": "من مدونتنا",
  "Save Big With Discounts": "عروض وخصومات",
  Expired: "منتهية الصلاحية",
  "This is a Buy 1 Get 1 Free Discount. After you buy the discount, you get a link to share with someone to redeem the discount for free":
    "هذا خصم اشترِ 1 واحصل على 1 مجانًا. بعد شرائك الخصم، ستحصل على رابط لمشاركته مع شخص آخر، والذي سيحصل على الخصم مجانًا",
  "You are booking the": "أنت تحجز",
  discount: "تخفيض",
  "will call you shortly after paying to confirm the date and time of your appointment.":
    "سوف تتصل بك قريباً بعد الدفع لتأكيد تاريخ ووقت موعدك.",
  "All bookings and payments made are non-refundable. The amount is only refunded if ":
    "جميع الحجوزات والمدفوعات غير قابلة للاسترداد. يتم رد المبلغ فقط إذا ألغت",
  "cancels your booking.": "حجزك",
  "Credit Card Details": "تفاصيل بطاقة الائتمان",

  "Clinic Confirmed Your Appointment": "أكدت العيادة موعدك",
  "Clinic Confirms Your Appointment": "ستؤكد العيادة موعدك",
  "will contact you shortly to confirm your walk-in appointment": "سوف تتصل بك قريباً لتأكيد موعدك",
  "I Want to Get the Discount": "أريد الحصول على الخصم",
  "Link has been copied!": "تم نسخ الرابط!",
  "buy 1 get 1 for free!": "اشترِ 1 واحصل على 1 مجانًا!",
  "buy 1 and get 1 for free!": "اشترِ 1 واحصل على 1 مجانًا!",
  "Redeem Discount for free": "احصل على الخصم مجانًا",
  "Visit the Clinic": "قم بزيارة العيادة",
  "only accepts walk-ins. After clinic confirms, you can visit the clinic at these times:":
    "لا يقبل إلا الزيارات بأسبقية الحضور. وبعد تأكيد العيادة، يمكنك زيارتها في هذه الأوقات:",
  "Thank you for booking through HeliumDoc": "شكراً لحجزك من خلال هيليوم دوك",
  "Thank you for booking an appointment through HeliumDoc": "شكراً لإستخدامكم هيليوم دوك لحجز مواعيدكم",
  "will contact you shortly to confirm the date and time of your appointment":
    "سوف تتصل بك قريبًا لتأكيد تاريخ ووقت موعدك",
  "You will get a discount coupon link to share with a friend or family member to redeem the free discount.":
    "ستحصل على رابط قسيمة الخصم لمشاركته مع صديقك أو أحد أفراد عائلتك ليتمكن بدوره من الحصول على الخصم المجاني.",
  "See how it works": "اعرف كيف يعمل",
  "Share the discount coupon link with a friend or family member to redeem the free discount.":
    "شارك رابط قسيمة الخصم مع صديق أو أحد أفراد عائلتك ليحصل على الخصم المجاني.",
  "Redeem your discount with": "احصل على خصمك مع",
  "You have booked and paid the discounted price": "تم الحجز و الدفع و تفعيل الخصم",
  "Book the Discount and Pay Online": "احجز الخصم وادفع عبر اللإنترنت",
  "Get Discount Coupon Link": "احصل على رابط قسيمة الخصم",
  "How Discount Coupons Work": "كيفية عمل قسائم الخصم",
  "Enter your payment details to buy the": "أدخل تفاصيل الدفع الخاصة بك لشراء",
  "Book and Redeem the Free Discount": "احجز واحصل على الخصم المجاني",
  "You have booked and redeemed your discount": "لقد حجزت وحصلت على خصمك",
  "Discount Coupon Link": "رابط قسيمة الخصم",
  "after appointment confirmation.": "بعد تأكيد الموعد.",
  "copy link": "نسخ الرابط",
  "Please select a clinic": "يرجى اختيار العيادة",
  "Select Your Clinic": "اختر العيادة",
  "This discount is not supported for this doctor": "هذا الخصم غير مدعوم لهذا الطبيب",
  Expired: "منتهية الصلاحية",
  "Your appointment has been rescheduled to": "تمت إعادة ترتيب موعدك إلى",
  "Discounted Price": "سعر مخفض",
  "Known For": "أبرز الإنجازات",
  "Start by entering your mobile number": "ابدأ بإدخال رقم الهاتف الخاص بك",
  "A pin code was sent to your mobile number": "تم إرسال رمز التأكيد إلى رقم الهاتف الخاص بك",
  "Resend pin": "إعادة إرسال رمز التأكيد",
  "Verify My Mobile Number": "التأكد من رقم الهاتف الخاص بي",
  "Change number": "تغيير رقم الهاتف",
  "Download Our App": "حمّل تطبيق هيليوم دوك",
  "Download our app today and get the best of HeliumDoc. Your wellness is just a tap away!":
    "حمّل تطبيق هيليوم دوك اليوم كي تستفيد من خدماتنا على أفضل وجه!",
  "Find Doctors by Insurance": "ابحث عن أطباء وفقاً لخطة تأمينك",
  "View All": "عرض جميع",
  "View All Doctors": "عرض جميع الأطباء",
  "View All Clinics": "عرض جميع العيادات",
  insurance_doctors_1: "قائمة الأطباء في",
  insurance_doctors_2: "تابعة لتأمين",
  insurance_clinics_1: "قائمة العيادات في",
  "No slots available on this day. Try other days": "لا توجد مواعيد متاحة في هذا اليوم. يرجى اختيار يوم آخر",
  "Choose your booking time": "يرجى اختيار موعد الحجز",
  "slots available": "موعد متاح",
  "slot available": "موعد متاح",
  "Not available": "غير متاح",
  "This doctor is currently unavailable": "هذا الطبيب غير متوفر حالياً",
  "Search other": "البحث عن أطباء آخرين في",
  "Searching for next availability": "جاري البحث عن أقرب موعد متوفر",
  "Almost there": "على وشك الإتمام",
  "A few seconds left": "بقيت بضعة ثوان",
};
