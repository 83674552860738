export class Hour {

    /**
     * @param {string} hour 
     * @param {boolean} available 
     */
    constructor(hour, available = false) {
        this.raw = hour;
        this.available = available;
        this.active = false
    }

    get trimed () {
        return this.raw.split(' ').join('')
    }
}
