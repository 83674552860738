export const GridTheme = {
  gridColumns: 12,
  breakpoints: {
    xl: 1440,
    lg: 1200,
    md: 992,
    sm: 768,
    xs: 576,
  },
  row: {
    padding: 15,
  },
  col: {
    padding: 15,
  },
  container: {
    padding: 15,
    maxWidth: {
      xl: 1195,
      lg: 1195,
      md: 970,
      sm: 750,
      xs: 560,
    },
  },
};
