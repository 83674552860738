export const getRoute = (country, path) => {
  if (country && country !== "qatar") {
    return `/${country}${path}`;
  }
  return path;
};

export const getCompleteUrl = function (url) {
  if (url[0] === "/") {
    url = url.substr(1, url.length);
  }
  return `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${url}`;
};

export const replaceDashesWithPluses = (value) => {
  return value.replace(/-/gi, "+");
};

const localizeValue = (url, country, locale) => {
  if (url[0] === "/") {
    url = url.substr(1, url.length);
  }

  let val;
  if (country == "uae") {
    val = `${locale && locale !== "en" ? `${locale}/` : ""}${`${country}/`}${url}`;
    if (url[0] !== "u" && val[4] == "u" && url != "user-booking-info/") {
      val = val.split("/");
      let splicedArray = [...val];
      splicedArray.splice(1, 1);
      val = splicedArray.join("/");
    }
  } else {
    val = `${locale && locale !== "en" ? `${locale}/` : ""}${
      country && country !== "qatar" ? `${country}/` : ""
    }${url}`;
  }
  return val;
};

export const localize = (url, country, locale) => {
  return localizeValue(url, country, locale);
};

export const localizeUrl = (url, country, locale) => {
  url = localizeValue(url, country, locale);
  return `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${url}`;
};

export const DEFAULT_LOCALE = "qatar";
export const COOKIE_LOCALE = "user_locale_2__session";

let baseURL = "";
let backendBaseUrl = "";

switch (process.env.NODE_ENV) {
  case "production":
    backendBaseUrl = "http://bandar.stagefelbayt.com/";
    baseURL = "https://www.stagefelbayt.com/";
    break;
  case "staging":
    backendBaseUrl = "http://bandar.stagefelbayt.com/";
    baseURL = "https://www.stagefelbayt.com/";
    break;
  case "development":
    backendBaseUrl = "http://bandar.stagefelbayt.com/";
    baseURL = "https://www.stagefelbayt.com/";
    break;
  default:
    backendBaseUrl = "http://bandar.stagefelbayt.com/";
    baseURL = "https://www.stagefelbayt.com/";
}

export { backendBaseUrl, baseURL };
