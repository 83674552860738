import { SSR, localize } from "../../utils";

export const GET_BLOG_POSTS_DATA = "GET_BLOG_POSTS_DATA";

export const getBlogPostsData = (query) => async (dispatch) => {
    let params = {
        specialization: query?.specialization
    }
    try {
        const data = await SSR.getRequest("blog_posts/", params);
        dispatch({ type: GET_BLOG_POSTS_DATA, payload: data.data });
    } catch (e) {
        console.log('error', e)
    }

};
