import {
    GET_DISCOUNT_DATA,
    GET_DISCOUNTS_MAIN_PAGE_DATA,
    GET_DISCOUNTS_AREA,
    GET_DISCOUNTS_LISTING,
} from "../actions/discounts";
import { HYDRATE } from "next-redux-wrapper";

const initialState = { discount: null, discounts: null, discountAreas: null, listing: null };

const discountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_DISCOUNT_DATA:

            return { ...state, discount: { ...payload } };
            case GET_DISCOUNTS_MAIN_PAGE_DATA:
            return { ...state, discounts: { ...payload } };
        case GET_DISCOUNTS_AREA:
            return { ...state, discountAreas: { ...payload } };
        // case GET_DISCOUNTS_LISTING:
        //     console.log(state, 'state listing')

        //     return { ...state, listing: { ...payload } };
        case HYDRATE:
            return { ...payload.discountData };
        default:
            return state;
    }
};

export default discountReducer;
