import { API, localize } from "../../utils";
import { SET_INIT_DATA, SET_INIT_DATA_SERVER, SET_INT_ERR } from "../types/init";

export const GetInitialData = (params) => async (dispatch) => {
  dispatch({ type: "SET_LOADING", payload: true });

  try {
    const data = await API.getRequest(true, localize("init/", params.client_side_country, params.locale));
    // user data
    const userData = {
      isAuthenticated: data.user_is_authenticated,
      isAnonymous: data.user_is_anonymous,
      isSuper: data.user_is_superuser,
      isVerified: data.user_is_verified,
      email: data.user_email,
      fullName: data.user_full_name,
    };

    // store user data to local storage
    localStorage.setItem("userData", JSON.stringify(userData));
    dispatch({ type: "SET_LOADING", payload: false });

    dispatch({
      type: SET_INIT_DATA,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: "SET_LOADING", payload: false });

    dispatch({
      type: SET_INT_ERR,
      payload: err,
    });
  }
};

export const GetInitialDataServer = (params) => async (dispatch) => {
  const data = await API.getRequest(false, localize("init/", params.client_side_country, params.locale));
  dispatch({
    type: SET_INIT_DATA_SERVER,
    payload: {
      ...data,
      client_side_country: params.client_side_country,
    },
  });
};
