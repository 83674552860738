import { useSelector } from 'react-redux';

export default function useTranslation() {
  const { locale, locales_list } = useSelector(({ InitialDataS }) => InitialDataS);

  function allowedLocales(localeList) {
    // Check the current locale with the allow locales list provided and
    // see if it is allowed or not
    return localeList.includes(locale);
  }

  function activeLocales() {
    // REMOVE A LOCALE FROM FILTER IF YOU WANT TO DISPLAY IT IN THE APP
    return locales_list.filter((locale) => {
      return (
        locale[0] !== 'sa' &&
        locale[0] !== 'kw' &&
        locale[0] !== 'bd' &&
        locale[0] !== 'ke' &&
        locale[0] !== 'gh' &&
        locale[0] !== 'ug' &&
        locale[0] !== 'lr' &&
        locale[0] !== 'sn'
      );
    });
  }

  return { allowedLocales, activeLocales };
}
