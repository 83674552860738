import { API, localize } from "../../utils";
import { changeLoading } from "./loading";
import { SetSeoData } from "./seo";
export const FETCH_CLINICS = "FETCH_CLINICS";
export const GET_CLINIC = "GET_CLINIC";
export const GET_CLINIC_FIND_BY = "GET_CLINIC_FIND_BY";
export const FETCH_CLINIC_DOCTORS_LISTING = "FETCH_CLINIC_DOCTORS_LISTING";
export const GET_DOCTOR_FIND_BY = "GET_DOCTOR_FIND_BY";
export const getClinics =
  ({ slug, index, client_side_country, locale }) =>
  async (dispatch) => {
    var url = "clinics/";
    if (slug) {
      url += `${slug}`;
    }

    url = `${url}${url[url.length - 1] == "/" ? "" : "/"}${index ? index + "/" : ""}`;

    if (index) {
      dispatch(changeLoading(true));
    }

    const data = await API.postRequest(false, localize(url, client_side_country, locale), {
      data: {},
    });

    if (data.seo) {
      dispatch(SetSeoData(data.seo));
    }
    dispatch(changeLoading(false));

    dispatch({
      type: FETCH_CLINICS,
      payload: { ...data, client_side_country: client_side_country },
    });
  };

export const getInsuranceClinics =
  ({ slug, index, client_side_country, locale }) =>
  async (dispatch) => {
    var url = "insurance/";
    if (slug) {
      url += `${slug}`;
    }

    url = `${url}/clinics/${index}/`;

    try {
      const data = await API.getRequest(false, localize(url, client_side_country, locale), {
        data: {},
      });
      dispatch({
        type: FETCH_CLINICS,
        payload: { ...data, client_side_country: client_side_country },
      });

      return data;
    } catch (error) {
      console.log(error);
    }
  };

export const getFindDoctorBy = (locale, language) => async (dispatch) => {
  var url = "";
  url += `clinic/find/`;
  const data = await API.postRequest(false, localize(url, locale, language));
  dispatch({
    type: GET_DOCTOR_FIND_BY,
    payload: data,
  });
};

export const getClinic =
  ({ auth, slug, client_side_country, locale }) =>
  async (dispatch) => {
    await API.getRequest(auth, localize(`clinic/${slug}`, client_side_country, locale))
      .then((data) => {
        dispatch({ type: GET_CLINIC, payload: data });
      })
      .catch((err) => {
        // dispatch({ type: GET_CLINIC, payload: null });
        return err;
      });
    //   try {
    //     const data = await API.getRequest(auth, localize(`clinic/${slug}`, client_side_country, locale));
    //     dispatch({ type: GET_CLINIC, payload: data });
    //   } catch (err) {
    //     // console.log(err,'err');
    //     // dispatch({ type: GET_CLINIC, payload: null });
    //     return err;
    //   }
  };

export const getFindClinic = (auth, slug, client_side_country, locale) => async (dispatch) => {
  try {
    const data = await API.postRequest(auth, localize(`clinic/find/`, client_side_country, locale), {
      clinic_slug: slug,
    });

    dispatch({
      type: GET_CLINIC_FIND_BY,
      payload: data,
    });
  } catch (err) {
    return err;
  }
};

export const getClinicDoctorsListing =
  ({ auth, slug, index, client_side_country, locale, specializationSlug }) =>
  async (dispatch) => {
    // dispatch({ type: "SET_LOADING", payload: true });
    let url = `${localize(`clinic/get_doctors/${slug}/?page=${index}`, client_side_country, locale)}`;

    if (specializationSlug) {
      url += `&specialization_slug=${specializationSlug}`;
    }

    const data = await API.getRequest(auth, url);
    // const { data } = await axios.get(url, { headers: { "x-requested-with": "XMLHttpRequest" } });
    dispatch({ type: FETCH_CLINIC_DOCTORS_LISTING, payload: { ...data, client_side_country: client_side_country } });
    // dispatch({ type: "SET_LOADING", payload: false });
  };
