import { isAfricanCounty } from "@/utils/router";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../data/actions/userProfile";
import { Loader } from "../Common";
import CookieNotice from "../CookieNotice";
import Footer from "./Footer";
import { Navbar } from "./Navbar";
import SiteDisclaimers from "./SiteDisclaimers";

function Layout({ children }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { locale } = router;

  const loader = useSelector(({ loader }) => loader);
  const userProfile = useSelector(({ userProfile }) => userProfile);
  const SeoData = useSelector(({ SeoData }) => SeoData);
  const { country_code } = useSelector(({ InitialDataS }) => InitialDataS);
  let isProtectedRoute = false;
  const [showCookieNotice, setShowCookieNotice] = useState(
    typeof window !== "undefined" &&
      country_code &&
      isAfricanCounty(country_code) &&
      router.route.indexOf("pregnancy-risk") == -1 &&
      !Boolean(window.localStorage.getItem("ACCEPTED_COOKIES"))
  );

  const [showFooter, setShowFooter] = useState(true);

  const meddy = locale === "en" ? "HeliumDoc" : "هيليوم دوك";

  if (router.route.indexOf("/me/") != -1) {
    isProtectedRoute = true;
  }

  useEffect(() => {
    if (isProtectedRoute && !Object.keys(userProfile).length) {
      dispatch(fetchUserData());
    }
  }, [userProfile]);

  useEffect(() => {
    const { asPath } = router;
    if (locale.indexOf(`ar`) > -1) {
      let css = document.createElement("link");
      css.setAttribute("rel", "stylesheet");
      css.setAttribute("type", "text/css");
      // css.setAttribute("href", "<%= htmlWebpackPlugin.files.css[2]%>");
      document.getElementsByTagName("head")[0].appendChild(css);

      // var tajawal = document.createElement("link");
      // tajawal.setAttribute("as", "style");
      // tajawal.setAttribute("rel", "preload");
      // tajawal.setAttribute("onload", "this.onload=null;this.rel='stylesheet'");
      // tajawal.setAttribute("href", "https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700&display=swap");
      // document.getElementsByTagName("head")[0].appendChild(tajawal);
    }

    const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
    if (
      asPath.indexOf("/login") != -1 ||
      asPath.indexOf("/signup") != -1 ||
      asPath.indexOf("booking") != -1 ||
      asPath.indexOf("profile/edit") != -1
    ) {
      if (recaptchaElems.length) {
        recaptchaElems[0].style.visibility = "visible";
      }
    } else {
      if (recaptchaElems.length) {
        recaptchaElems[0].style.visibility = "hidden";
      }
    }

    if (router.route.indexOf("pregnancy-risk-assessment") != -1 && router.route.indexOf("result") == -1) {
      setShowFooter(false);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta key="description" name="description" content={SeoData.meta_desc || ""} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        {/* DEFAULT PAGE TITLE */}
        <title>{`${SeoData.page_title} ${SeoData.page_title ? "|" : ""} ${meddy}`}</title>
        <link
          rel="canonical"
          href={`${
            process.env.NODE_ENV === "development" ? "http://localhost:3003/" : process.env.NEXT_PUBLIC_BASE_URL
          }${locale === "ar" ? "ar/" : ""}${router.asPath.replace("/", "")}`}
        />
        {/* Generic Routes that supports all countries */}
        {router.pathname === "/" ||
        ["/login", "/signup", "/join-doctors", "/privacy", "/terms"].includes(router.pathname) ? (
          [
            { lang: "en", code: "qa" },
            { lang: "ar", code: "qa" },
            { lang: "en", code: "ae" },
            { lang: "ar", code: "ae" },
            { lang: "en", code: "sa" },
            { lang: "ar", code: "sa" },
            { lang: "en", code: "ng" },
            { lang: "en", code: "ug" },
            { lang: "en", code: "lr" },
            { lang: "en", code: "sn" },
            { lang: "en", code: "ke" },
            { lang: "en", code: "gh" },
          ].map(({ lang, code }) => (
            <link
              rel="alternate"
              href={`${
                process.env.NODE_ENV === "development" ? "http://localhost:3003/" : process.env.NEXT_PUBLIC_BASE_URL
              }${lang === "ar" ? lang + "/" : ""}${
                code !== "qa" ? code.replace("ae", "uae") + "/" : ""
              }${router.pathname.replace("/", "")}${router.pathname === "/" ? "" : "/"}`}
              hreflang={`${lang}-${code}`}
            />
          ))
        ) : ["qa", "sa", "ae"].includes(country_code) &&
          ["/clinic/[slug]", "/insurance/[slug]", "/[doctor_name]"].includes(router.pathname) ? (
          <>
            {["en", "ar"].map((lang) => (
              <link
                rel="alternate"
                href={`${
                  process.env.NODE_ENV === "development" ? "http://localhost:3003/" : process.env.NEXT_PUBLIC_BASE_URL
                }${lang === "ar" ? "ar/" : ""}${router.asPath.replace("/", "")}`}
                hreflang={`${lang}-${country_code}`}
              />
            ))}
          </>
        ) : router.pathname === "/cookie" ? (
          [
            { lang: "en", code: "ng" },
            { lang: "en", code: "ug" },
            { lang: "en", code: "lr" },
            { lang: "en", code: "sn" },
            { lang: "en", code: "ke" },
            { lang: "en", code: "gh" },
          ].map(({ lang, code }) => (
            <link
              rel="alternate"
              href={`${
                process.env.NODE_ENV === "development" ? "http://localhost:3003/" : process.env.NEXT_PUBLIC_BASE_URL
              }${code}/cookie`}
              hreflang={`${lang}-${code}`}
            />
          ))
        ) : (
          <link
            rel="alternate"
            href={`${
              process.env.NODE_ENV === "development" ? "http://localhost:3003/" : process.env.NEXT_PUBLIC_BASE_URL
            }${locale === "ar" ? "ar/" : ""}${router.asPath.replace("/", "")}`}
            hreflang={`${locale}-${country_code}`}
          />
        )}

        <script
          async
          defer
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
        ></script>
      </Head>
      {/* IMPORT GLOBAL STYLES */}
      {/* <style jsx='true' global>{`
                @import url("/fonts/Icons/meddy-icons.woff");
            `}</style> 
        
            <style jsx='true' global>{`
                @import url("/fonts/Icons/meddy-icons.css");
            `}</style> */}

      {/* IMPORT GLOBAL STYLES END */}

      {(isProtectedRoute && Object.keys(userProfile).length) || !isProtectedRoute ? (
        <>
          <Navbar />
          <SiteDisclaimers />
          {children}
          {showFooter && <Footer />}
          {showCookieNotice && <CookieNotice setShowCookieNotice={setShowCookieNotice} />}
          {loader && <Loader />}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default Layout;
