import { SET_SEO_DATA } from "../actions/seo";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const SeoData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEO_DATA:
      return { ...state, ...payload };
    case HYDRATE:
      return { ...state, ...payload.SeoData };
    default:
      return state;
  }
};

export default SeoData;
