import { GET_DOCTOR_PROFILE_DATA, GET_DOCTOR_REVIEWS, GET_DOCTOR_FIND_BY } from "../types/doctorProfile";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  doctorProfile: null,
  doctorReviews: null,
  doctorFindBy: null,
};

const DoctorProfileData = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DOCTOR_PROFILE_DATA:
      return { ...state, doctorProfile: { ...payload } };
    case GET_DOCTOR_REVIEWS:
      return { ...state, doctorReviews: { ...payload } };
    case GET_DOCTOR_FIND_BY:
      return { ...state, doctorFindBy: { ...payload } };
    case HYDRATE:
      return { ...payload.doctorProfileData };
    default:
      return state;
  }
};

export default DoctorProfileData;
