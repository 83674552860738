import { API, localize } from "../../utils";
import { FETCH_CLINICS } from "../actions/clinics";

export const GET_BOOKINGS_DATA = "GET_BOOKINGS_DATA";

export const getBookings =
  ({ doctor, client_side_country, language_code }) =>
  async (dispatch) => {
    var url = "/bookings/submit_booking/doctor";
    const data = await API.getRequest(false, localize(`${url}/${doctor}/`, client_side_country, language_code), {
      options: {},
    });
    dispatch({
      type: GET_BOOKINGS_DATA,
      payload: data,
    });
  };


export const getUserBookingInfo = async ({client_side_country,language_code}) => {

  try {
    return await API.getRequest(true,localize("user-booking-info/",client_side_country,language_code));
  } catch (error) {
    console.log(error)
  }
  
}

export const getAppointmentTimings = async ({client_side_country,language_code,bookingId,clinicId,startDate,endDate}) => {
  try {
    let body = {
      date_from: startDate,
      date_to: endDate,
    };
    return await API.postRequest(true,localize(`schedule/${bookingId}/${clinicId}/`,client_side_country,language_code),body) ;
  } catch (error) {
    throw error;
  }
}


export const createBooking = async (data,client_side_country,language_code) => {
  try {
    return await API.postRequest(true,localize("doctor/api/create_booking/",client_side_country,language_code),data);
  } catch (error) {
    throw error
  }
}

export const completeBooking = async ({confirmationId,client_side_country,language_code,data}) => {
  return await API.postRequest(true,localize(`bookings/booking-confirmation/doctor/${confirmationId}/`,client_side_country,language_code),data);
}