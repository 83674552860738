import React from "react";
import useTranslation from "../../intl/useTranslation";

const PaymentStatus = ({ heading, subText }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="payment-card payment-successful">
        <img className="confirmed-booking-img" src="/images/Confirmed Check.svg" alt="Confirmed Image" />
        <h2>{t(heading)} </h2>
        {subText && <p>{t(subText)}</p>}
      </div>
    </div>
  );
};

export default PaymentStatus;
