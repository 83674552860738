import { API, localize, SSR } from '../../utils';

export const FETCH_INSURANCE_DATA = 'FETCH_INSURANCE_DATA';
export const GET_INSURANCE_FIND_BY = 'GET_INSURANCE_FIND_BY';

export const getInsuranceData =
  ({ slug, client_side_country, locale }) =>
  async (dispatch) => {
    try {
      const data = await SSR.getRequest(localize(`insurance/${slug}`, client_side_country, locale));

      dispatch({ type: FETCH_INSURANCE_DATA, payload: { ...data.data, client_side_country: client_side_country } });
    } catch (err) {
      return err;
    }
  };

/**
 * get doctor find by data
 * @param {*} insuranceSlug
 * @returns
 */
export const getFindDoctor = (auth, locale, language, insuranceName) => async (dispatch) => {
  const data = await API.postRequest(auth, localize(`doctor/find/`, locale, language), {
    insurance_slug: insuranceName,
  });
  dispatch({
    type: GET_INSURANCE_FIND_BY,
    payload: data,
  });
};
