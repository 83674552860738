import { API, localize } from "../../utils";
import { changeLoading } from "./loading";
export const FETCH_DOCTORS_LISTING = "FETCH_DOCTORS_LISTING";
export const GET_DOCTOR_FIND_BY = "GET_DOCTOR_FIND_BY";

import { SetSeoData } from "./seo";

export const getDoctorListing =
  ({
    slug,
    index,
    client_side_country,
    locale,
    specializationSlug,
    video = false,
    sortBy = "default",
    clinic = false,
  }) =>
  async (dispatch) => {
    let url = "doctors/";

    if (specializationSlug) {
      url += `${specializationSlug.replace(/-/g, "+")}-`;
    }
    if (slug) {
      url += `${slug}/`;
    }
    index ? dispatch(changeLoading(true)) : null;

    if (index > 1) {
      url += `${index}/`;
    }

    const data = await API.postRequest(false, localize(url, client_side_country, locale), {
      clinic: clinic,
      video: video,
      sortBy: sortBy,
      get_filters: true,
    });

    if (data.seo) {
      dispatch(SetSeoData(data.seo));
    }
    dispatch(changeLoading(false));

    dispatch({
      type: FETCH_DOCTORS_LISTING,
      payload: { ...data, client_side_country: client_side_country },
    });
  };

export const getInsuranceDoctors =
  ({ slug, index, client_side_country, locale, specializationSlug }) =>
  async (dispatch) => {
    let url = "insurance/";
    url += `${slug}/doctors/${index}/?filter=`;
    if (specializationSlug) {
      url += `${specializationSlug.replace(/-/g, "+")}`;
    }

    const data = await API.getRequest(false, localize(url, client_side_country, locale), { video: false });
    dispatch({ type: FETCH_DOCTORS_LISTING, payload: { ...data, client_side_country: client_side_country } });
    return data;
  };

export const getFindDoctorBy = (language, locale, body) => async (dispatch) => {
  var url = "";

  url += `doctor/find/`;
  const data = await API.postRequest(false, localize(url, locale, language), body);

  dispatch({
    type: GET_DOCTOR_FIND_BY,
    payload: data,
  });
};
