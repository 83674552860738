import { useRouter } from "next/router";
import Translations from "./languages/main";

export default function useTranslation() {
    const router = useRouter();
    const { locale, locales, defaultLocale } = router;

    function t(key) {
        let tempLocale = locale;
        if (locale.indexOf("-") > -1) {
            tempLocale = tempLocale.substring(0, tempLocale.indexOf("-"));
        }
        if (!Translations[tempLocale][key]) {
            console.warn(`No string '${key}' for locale '${tempLocale}'`);
        }

        return Translations[tempLocale][key] || Translations[defaultLocale][key] || "";    
    }

    return { t, locale };
}
