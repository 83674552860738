import {
    FETCH_COMPLETE_PROFILE_DATA,
    UPDATE_ID_BACK,
    UPDATE_ID_FRONT,
    UPDATE_PASSPORT,
    UPDATE_HAS_VERIFICATION,
    UPDATE_INSURANCE,
    SET_ERR,
    UPLOAD_RECORD,
    REMOVE_RECORD,
} from "../actions/completeProfile";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const completeProfile = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_COMPLETE_PROFILE_DATA:
            return payload;
        case UPDATE_ID_FRONT:
            return {
                ...state,
                id_front: payload,
            };
        case UPDATE_ID_BACK:
            return {
                ...state,
                id_back: payload,
            };
        case UPDATE_PASSPORT:
            return {
                ...state,
                passport: payload,
            };
        case UPDATE_HAS_VERIFICATION:
            return {
                ...state,
                hasVerification: payload,
            };
        case UPDATE_INSURANCE:
            return {
                ...state,
                insurance_document: payload,
            };
        case SET_ERR:
            return {
                ...state,
                error: payload,
            };
        case UPLOAD_RECORD:
            return {
                ...state,
                medical_records: state.medical_records?.length ? [...state.medical_records, payload] : [payload],
            };
        case REMOVE_RECORD:
            return {
                ...state,
                medical_records: state.medical_records.filter(({ id }) => id !== payload.id),
            };
        case HYDRATE:
            return { ...payload.completeProfile };
        default:
            return state;
    }
};

export default completeProfile;
