import { GET_HOME_PAGE_DATA } from "../types/homePage";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};
   
const HomePageData = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_HOME_PAGE_DATA:
            return payload
        case HYDRATE:
            return {...payload.HomePageData};
        default:
            return state;
    }
};

export default HomePageData;
