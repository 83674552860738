import useTranslation from '@/intl/useTranslation';
import { useRouter } from 'next/router';
import { footerTracker } from '../../services/tracker/footer';
import { FloatingBtnWrapper } from './WhatsappFloatingBtn.styled';

type Props = { whatsappNum: number };

export default function WhatsappFloatingBtn({ whatsappNum }: Props) {
  const { t } = useTranslation();
  const { route } = useRouter();
  const isInDoctorProfile = ['/[doctor_name]'].includes(route);

  return (
    <FloatingBtnWrapper
      href={`https://wa.me/${whatsappNum}`}
      target="_blank"
      rel="noopener noreferrer"
      title={t('Do you need any help? Message us')}
      onClick={() => {
        footerTracker.trackWhatsappSupport();
      }}
      isInDoctorProfile={isInDoctorProfile}
    >
      <img src="/next-images/whatsapp-btn.svg" alt="Whatsapp Support" />
    </FloatingBtnWrapper>
  );
}
