import { InitGtm, TrackGtm } from "./gtm";
import { Mixpanel, initMixpanel } from "./mixpanel";
import { createErrorMessageSelector, createLoadingSelector } from "./selectors";
export { default as API } from "./api";
export {
  arabicToEnglish,
  copyToClipBoard,
  customizePaymentsMessageForAfrica,
  getCountryFromRequestUrl,
  getFriendlyDate,
  getPaymentErrorFromMessage,
  getTestNumbers,
  isImage,
  isInViewport,
  isMobile,
  isObjectEmpty,
  replaceAll,
} from "./miscUtils";
export { isAfricanCounty } from "./router";
export { default as SEO } from "./seo";
export { default as SSR } from "./ssrApi";
export { DEFAULT_LOCALE, getCompleteUrl, getRoute, localize, localizeUrl, replaceDashesWithPluses } from "./url";
export { InitGtm, Mixpanel, TrackGtm, createErrorMessageSelector, createLoadingSelector, initMixpanel };
