import { localize } from "../../utils";
import axios from "axios";

export const GET_DOCTOR_REVIEW = "GET_DOCTOR_REVIEW";

export const getDoctorReview = ({ doctorSlug, client_side_country, page, locale }) => async (dispatch) => {
    try {
        const data = await axios.get(
            `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${localize(
                `api/get_reviews_for_discount_profile/${doctorSlug}/`,
                client_side_country,
                locale
            )}`,
            { headers: { "x-requested-with": "XMLHttpRequest" } }
        );
        dispatch({
            type: GET_DOCTOR_REVIEW,
            payload: { ...data.data, doctorName: doctorSlug, client_side_country: client_side_country },
        });
    } catch (err) {
        return err;
    }
};
