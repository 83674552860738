import { GET_BLOG_POSTS_DATA } from "../actions/blogs";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};
   
const BlogPosts = (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_BLOG_POSTS_DATA:
            return payload
        case HYDRATE:
            return {...payload.BlogPosts};
        default:
            return state;
    }
};

export default BlogPosts;
