import { SET_LOADING } from "../actions/loading";
import { HYDRATE } from "next-redux-wrapper";

   
const Loader = (state = false, { type, payload }) => {
    switch (type) {
        case SET_LOADING:
            return payload
        case HYDRATE:
            return payload.loader;
        default:
            return state;
    }
};

export default Loader;
