import { GET_BOOKINGS_DATA } from "../actions/booking";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const bookings = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BOOKINGS_DATA:
      return { ...state, ...payload };
    case HYDRATE:
      return { ...state, ...payload.bookings };
    default:
      return state;
  }
};

export default bookings;
