import { PopupButton } from "@typeform/embed-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useClickAway } from "react-use";
import useTranslation from "../../../intl/useTranslation";
import { handleData, resultHeaders, resultKeys, searchData } from "../../../services/search";
import { Mixpanel, getRoute } from "../../../utils";

function searchbar({ type, custom_id, setMoreIndex = () => {} }) {
  const [results, setResults] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [count, setCount] = useState(-1);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [resultsArray, setResultsArray] = useState([]);

  const { t } = useTranslation();
  const router = useRouter();
  const { locale, push } = router;
  const { client_side_country, language_code } = useSelector(({ InitialDataS }) => InitialDataS);
  const resultContainer = useRef();

  const search = async (term) => {
    try {
      if (term && term.length >= 3) {
        const data = await searchData(client_side_country, { search_term: term });
        setResults(data);
        let countElements = 0;
        let arrResults = [];
        resultKeys.map((key) => {
          countElements += data[key].length;
          arrResults = [...arrResults, ...data[key]];
        });
        setCount(countElements);
        setResultsArray(arrResults);
        setShowResult(true);
      } else {
        setResults({});
        setCount(-1);
      }
    } catch (err) {
      alert("Search query failed! Please contact HeliumDoc for assistance!");
    }
  };
  useEffect(() => {
    search(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (showResult) {
      search(searchInput);
    }
  }, [showResult]);

  const leave = () => {
    setMobileSearch(false);
    setMoreIndex(false);
    setShowResult(false);
    setCursor(0);
  };

  const handleCancel = () => {
    leave();
    setSearchInput("");
  };

  const handleKey = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      // handle keyup
      setCursor((prevState) => prevState - 1);
      resultContainer.current.scrollTop = resultContainer.current.scrollTop - 30;
    } else if (e.keyCode === 40 && cursor < count) {
      // handle keydown
      resultContainer.current.scrollTop = resultContainer.current.scrollTop + 30;
      setCursor((prevState) => prevState + 1);
    } else if (e.key === "Enter") {
      // handle enter
      const eleHref = document.getElementsByClassName("focused")[0];
      push(`${eleHref.dataset.href}`);
      handleCancel();
      Mixpanel.track("Global Search | Result Chosen | Action", {
        "Current URL": window.location.href,
        "Clicked URL": eleHref.href,
        "Search Category": eleHref.dataset.category,
      });
    }
  };

  const findLength = (name) => {
    return resultsArray.findIndex((ele) => ele[[`name_${locale}`]] === name);
  };

  const searchContainerWrapper = useRef(null);
  useClickAway(searchContainerWrapper, () => {
    if (document) {
      document.body.style.overflow = "";
    }
    leave();
  });

  return (
    <>
      <div className={mobileSearch ? "search-container full" : "search-container"} ref={searchContainerWrapper}>
        {type === "bar" || mobileSearch ? (
          <div className="search-wrapper">
            <i className="meddy-search desk-only"></i>
            <input
              type="text"
              name="search"
              className="search-input"
              value={searchInput}
              autoFocus={mobileSearch}
              onFocus={() => {
                setShowResult(true);
                Mixpanel.track("Global Search | Search Started | Action", {
                  "Current URL": window.location.href,
                });
                if (window?.hj) {
                  window.hj("event", "click-search-input");
                }
              }}
              placeholder={t("Search doctors, procedures, clinics")}
              id={custom_id}
              onKeyDown={handleKey}
              autoComplete="off"
              onChange={(e) => setSearchInput(e.target.value)}
              data-hj-allow
            />
            <span className="close-search-cta mobile-only" onClick={handleCancel}>
              {t("Cancel")}
            </span>
          </div>
        ) : (
          <div
            className="mobile-search-triggerer"
            onClick={() => {
              setMoreIndex(true);
              setMobileSearch(true);
            }}
          >
            <i className="meddy-search"></i>
            <span>{t("Search doctors, procedures, clinics")}</span>
          </div>
        )}
        {Object.keys(results).length && showResult ? (
          <div
            className="search-result-container"
            // onBlur={leave}
            ref={resultContainer}
            id={custom_id + "-container"}
          >
            {resultKeys.map((key) => (
              <div key={key}>
                {results[key]?.length > 0 && (
                  <>
                    <div className="search-result-section-header">{t(resultHeaders[key])}</div>
                    {results[key].map((result, index) => (
                      <Link
                        href={getRoute(client_side_country, handleData(result, key, locale).go)}
                        key={`${index}_${key}`}
                      >
                        <a
                          className={
                            findLength(result[`name_${locale}`]) === cursor - 1
                              ? "search-result focused"
                              : "search-result"
                          }
                          data-href={getRoute(client_side_country, handleData(result, key, locale).go)}
                          data-category={result.model}
                          onClick={(e) => {
                            handleCancel();
                            Mixpanel.track("Global Search | Result Chosen | Action", {
                              "Current URL": window.location.href,
                              "Clicked URL": e.currentTarget.href,
                              "Search Category": result.model,
                            });
                          }}
                        >
                          {result.slug && (
                            <div className="clip-img">
                              <img
                                src={handleData(result, key, locale).imageUrl}
                                alt={`${handleData(result, key, locale).header} Profile Picture`}
                              />
                            </div>
                          )}
                          <div className="result-info">
                            <span className="result-text result-header-wrapper">
                              <span className="result-header">{handleData(result, key, locale).header}</span>
                              <span className="result-header-brackets">
                                {handleData(result, key, locale).betweenBrackets}
                              </span>
                            </span>
                            <span className="result-text result-subheader">
                              {handleData(result, key, locale).subHeader}
                            </span>
                            {result?.clinics && (
                              <span className="result-text result-special">{result.clinics[0][`name_${locale}`]}</span>
                            )}
                          </div>
                        </a>
                      </Link>
                    ))}
                  </>
                )}
              </div>
            ))}
            {count === 0 && (
              <div className="no-results">
                <h5 className="no-results-header">{t("No Results Found")}</h5>
                <p className="no-results-body">{t("Sorry we could not find any results")}</p>
                {["ng"].includes(client_side_country) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PopupButton
                      id="JnpWYOaO"
                      style={{
                        all: "unset",
                        fontFamily: "Helvetica,Arial,sans-serif",
                        display: "inline-block",
                        maxWidth: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: "#2A3390",
                        color: "#FFFFFF",
                        fontSize: "16px",
                        borderRadius: "5px",
                        padding: "0 26px",
                        fontWeight: "500",
                        height: "40px",
                        cursor: "pointer",
                        lineHeight: "40px",
                        textAlign: "center",
                        marginTop: 20,
                        textDecoration: "none",
                      }}
                    >
                      Let us help!
                    </PopupButton>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default searchbar;
