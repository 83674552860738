import { GET_USER_PRESCRIPTION, GET_BOOKING_PRESCRIPTION } from "../actions/prescription";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    prescriptions: null,
    bookingPrescription: null,
};

const userPrescription = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_USER_PRESCRIPTION:
            return { ...state, prescriptions: payload };
        case GET_BOOKING_PRESCRIPTION:
            return { ...state, bookingPrescription: payload };
        case HYDRATE:
            return { ...payload.userPrescription };
        default:
            return state;
    }
};

export default userPrescription;
