import { FETCH_USER_PROFILE_FULFILLED } from "../actions/userProfile";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const UserProfileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_USER_PROFILE_FULFILLED:
            return { ...state, ...payload };
        case HYDRATE:
            return { ...payload.UserProfileReducer };
        default:
            return state;
    }
};

export default UserProfileReducer;
