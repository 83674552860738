import { useEffect, useRef } from "react";
import Loader from "../../components/Loader";

function MeddyModal({ children, confirmationText, confirmAction, customClassName, close, loading , secondaryText,secondaryAction , hideConfirmationText, meddyModalActive}) {
    const node = useRef();
    
    const isMobile = window?.outerWidth <= 600;

    const handleClick = (e) => {
        if (!node.current) {
            return;
        }
        if (node.current.contains(e.target)) {
            return;
        }
        close();
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <>
            <div className="meddy-trans-layer animated fadeIn"></div>
            <div
                ref={node}
                className={`meddy-modal-container animated fadeIn ${
                    customClassName?.length ? [...customClassName].join(" ") : ""
                } ${isMobile ? "fadeInUp" : ""} ${meddyModalActive ? "meddymodalactive" : ""}`}
            >
                <div>{children}</div>
                {confirmationText?.length ? (
                    <div className="meddy-modal-footer">
                        <div className="text-center">
                            {!hideConfirmationText ? <button className="button-default-dark" type="submit" onClick={confirmAction} name="button" disabled={loading}>
                                {confirmationText}
                            </button> : ""}
                            { secondaryText ? (
                                <div className={"secondary-action"} onClick={() => {secondaryAction()}}>
                                    <p className="secondary-action-text">{secondaryText}</p>
                                </div> 
                            ) : null
                            }
                            {loading && <Loader />}
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
}

export default MeddyModal;
