import styled from 'styled-components';

export const FloatingBtnWrapper = styled.a<{
  readonly isInDoctorProfile?: boolean;
}>`
  display: block;
  position: fixed;
  z-index: 10;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 767.98px) {
    right: 15px;
    bottom: ${({ isInDoctorProfile }) => (isInDoctorProfile ? '90px' : '30px')};
  }
`;
