import Translations from "./languages/main";
// USE THE USETRANSLATION HOOK FOR TRANSLATIONS. THIS FUNCTION NOT RECOMMENDED
export const t =  (key) => {
        if(typeof(window) != "undefined"){
            let splittedPathname = window?.location?.pathname.split("/")
            let tempLocale = "en";
            if(splittedPathname && splittedPathname[1].indexOf("ar") != -1){
                tempLocale = "ar"
            }
            
            if (tempLocale.indexOf("-") > -1) {
                tempLocale = tempLocale.substring(0, tempLocale.indexOf("-"));
            }
            if (!Translations[tempLocale][key]) {
                console.warn(`No string '${key}' for locale '${tempLocale}'`);
            }
    
            return Translations[tempLocale][key] || Translations["en"][key] || "";
        }
       
}
