import { SET_INIT_DATA_SERVER } from "../types/init";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {};

const InitialDataS = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_INIT_DATA_SERVER:
            return { ...state, ...payload}
        
        case HYDRATE:
            return {  ...payload.InitialDataS, ...state,};

    default:
      return state;
  }
};

export default InitialDataS;
