import { combineReducers } from "redux";
import loading from "./loading";
import error from "./error";
import UserProfileReducer from "./userProfile";
import InitialData from "./initialData";
import InitialDataS from "./initServer";
import insurance from "./insurance";
import DoctorsListing from "./doctorsListing";
import clinics from "./clinics";
import HomePageData from "./homeData";
import BlogPosts from "./blogs" 
import SeoData from "./seo";
import userAppointments from "./userAppointments";
import doctorProfileData from "./doctorProfile";
import discountData from "./discounts";
import doctorReviews from "./review";
import discountListing from "./listing";
import completeProfile from "./completeProfile";
import loader from "./loader";
import bookings from "./bookings";
import userPrescription from "./prescription";
import errors from "./errors";
import auth from "./auth";
import FindDoctorBy from "./findDoctor";
import DoctorReview from "./doctorsReviews";
import reviewSubmit from "./submitReviewData";
import coupon from "./coupons"
import payments from "./payments";
import confirmation from "./cofirmationBoookings";

const reducers = {
  loading,
  bookings,
  error,
  loader,
  userProfile: UserProfileReducer,
  InitialData,
  InitialDataS,
  insurance,
  DoctorsListing,
  clinics,
  HomePageData,
  BlogPosts,
  SeoData,
  userAppointments,
  doctorProfileData,
  discountData,
  doctorReviews,
  payments,
  confirmation,
  discountListing,
  reviewSubmit,
  completeProfile,
  FindDoctorBy,
  DoctorReview,
  userPrescription,
  coupon,
  auth,
  errors,
  FindDoctorBy,
};

export default combineReducers(reducers);
