import { API, localize } from "../../utils";
export const GET_REVIEW_DATA = "GET_REVIEW_DATA";

export const getReviewData = (client_side_country, locale, slug,auth= false, callback) => async (dispatch) => {
  let theUrl = decodeURI(slug);
  if (theUrl.startsWith("/")) {
    theUrl = theUrl.substring(1);
  }
  const data = await API.getRequest(
    auth,
    localize(theUrl,null, locale) //DONT LOCALIZE THIS URL AS IT IS USING SLUG FROM ROUTER.ASPATH
  );
  if (auth && callback) {
    callback(true);
  }
  dispatch({ type: GET_REVIEW_DATA, payload: { ...data, client_side_country: client_side_country } });
  return data;
};
