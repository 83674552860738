export const createErrorMessageSelector = (actions) => (state) => {
    const errors = {};
    actions.forEach((action) => {
        errors[action] = state[action];
    });
    if (Object.keys(errors).length) {
        return errors;
    }
    return "";
};

export const createLoadingSelector = (actions) => (state) => {
    return actions.some((action) => state[action]);
};
