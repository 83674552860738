import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../utils/index";
import { getPageTypeFromPath } from "../../utils/miscUtils";
import { localize } from "../../utils/url";

export default function CookieNotice({ setShowCookieNotice }) {
  const { client_side_country, language_code, page_title } = useSelector(({ InitialDataS }) => InitialDataS);
  const { pathname } = useRouter();

  const handleAcceptCookies = (e) => {
    e.preventDefault();
    Mixpanel.track("Cookies Consent | I Consent Clicked | Button", {
      "Page Type": getPageTypeFromPath(pathname),
    });
    window.localStorage.setItem("ACCEPTED_COOKIES", true);
    setShowCookieNotice(false);
  };

  return (
    <div className="cookie-notice">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="cookie-notice_inner">
              <div className="cookie-notice_icon">
                <Image src="/next-images/cookie-illustration.svg" alt="Cookie Icon" width={45} height={45} />
              </div>
              <div className="cookie-notice_info">
                <p className="cookie-notice_text">
                  We use cookies to provide you with a great experience. By using the website, you agree to our use of{" "}
                  <Link href={`/${localize(`cookie`, client_side_country, language_code)}`}>cookies</Link>.
                </p>
                <a href="#" className="cookie-notice_btn" onClick={handleAcceptCookies}>
                  I consent to your cookies
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
