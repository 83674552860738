import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const ToasterContainer = styled.div`
  & > div {
    ${media.max.sm`
      inset: 0 !important;
    `}
  }
  .error-toast {
    background-color: ${({ theme }) => theme.colors.system.red};
    color: ${({ theme }) => theme.colors.white};
    width: 420px;
    max-width: 90%;
    border-radius: 5px;
    padding: 10px 15px;
    & > div {
      margin: 0;
      flex: initial;
      width: 100%;
    }
    ${media.max.sm`
      max-width: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
  }
`;

export const ToastBody = styled.div`
  display: flex;
  text-align: left;
  width: 100%;
`;
export const ToastContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
  line-height: 1.5;
`;
export const ToastTitle = styled.div`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
`;

export const ToastDesc = styled.div`
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font300};
`;
export const CloseToastBtn = styled.button`
  appearance: none !important;
  border: none !important;
  outline: none !important;
  background: transparent !important;
`;
