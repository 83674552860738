import { HYDRATE } from "next-redux-wrapper";
import { GET_DOCTOR_FIND_BY } from "../actions/doctorsListing";

const initialState = {
  doctorFindBy: null,
};

const FindDoctorByReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DOCTOR_FIND_BY:
      return {
        ...state,
        doctorFindBy: payload   
      };

    case HYDRATE:
    return {
      ...payload.FindDoctorBy
    }

    default:
      return state;
  }
};

export default FindDoctorByReducers;
