import { createStore, applyMiddleware, compose } from "redux";
// applyMiddleware, createStore, combineReducers, compose
import { createWrapper } from "next-redux-wrapper";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import RootReducer from "./reducers";

const composeEnhancers = composeWithDevTools({});

const middlewares = [promise, thunk];

const makeStore = (context) =>
  createStore(
    RootReducer,

    composeEnhancers(applyMiddleware(...middlewares))
  );

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: false });
