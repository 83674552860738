import axios from 'axios';
import { localize } from './url';

class SeoService {
  constructor() {
    this.title = '';
    this.meta = '';
    this.canonical;
    this.noindex;
  }

  getTitle() {
    return this.title;
  }

  getMeta() {
    return this.meta;
  }

  getCanonical() {
    return this.canonical;
  }

  request = async (url, params, counter) => {
    // TRY SEO REQUEST 3 TIMES IN CASE OF FAILURE
    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}${localize(
          `seo/?url=${url}`,
          params.client_side_country,
          params.locale
        )}`
      );
      return data;
    } catch (err) {
      if (counter < 3) {
        counter++;
        await this.request(url, params, counter);
      } else {
        return null;
      }
    }
  };

  async getAndUpdateSEOObject(url, params) {
    let data = await this.request(url, params, 1);
    return data;
  }

  updateOnRouteChange(params, data) {
    console.log(data, 'from seo');
    // GET DARA FROM STORE AND UPDATE SEO
    this.update(params, data.page_title, data.meta_desc, data.no_index, data.canonical);
  }

  update(params, title, meta, noindex, canonical, amp) {
    // CAN BE CALLED DIRECTLY FROM PAGES TOO FOR CUSTOM SEO SET
    const head = document.getElementsByTagName('head')[0];
    const meddy = params.locale === 'en' ? 'HeliumDoc' : 'هيليوم دوك';

    title = `${title} | ${meddy}`;
    document.title = title;

    this.title = title;
    this.meta = meta;
    this.canonical = canonical || '';

    var metaDesc = document.querySelector('meta[name=description]');
    if (metaDesc !== null) {
      metaDesc.setAttribute('content', meta);
    }

    if (noindex) {
      const metarobots = document.createElement('meta');
      metarobots.name = 'robots';
      metarobots.content = 'noindex,follow';
      head.appendChild(metarobots);
    }

    // if (canonical) {
    //   const link = document.createElement('link');
    //   link.rel = 'canonical';
    //   link.href = canonical;
    //   head.appendChild(link);
    // }
  }
}

const SEO = new SeoService();

export default SEO;
